import React, { Component, Fragment } from "react";
import axios from "axios";
import { getQueryString } from "../util/widgetUtil";

import {
  getUsersFromGroup,
  getUserDetails,
  createOktaEmailObject_Approval,
  getAppInfo,
  getCurrentUser
} from "../services/util";
import { DOMAIN_BALDWIN, DOMAIN_TFS, Context } from "../Constant";

import Header from "./Header";
import Footer from "./Footer";
import { getAPIUrl, getPath } from "../apiconfig";


class RequestAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: false,
      emailAddressValue: "",
      passwordValue: "",
      showWarning: false,
      showWarning_firstName: false,
      showWarning_lastName: false,
      userLastName: "",
      userFirstName: "",
      orgValue: "",
      isPasswordValid: false,
      isEmailValid: false,
      appId: "",
      appName: "this app",
      isButtonDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.callSmartCommAPI = this.callSmartCommAPI.bind(this);
  }

  componentDidMount() {
    this.state.appId = getQueryString("appId");
    
    getAppInfo(this.state.appId, (resp) => {
      if (resp.hasOwnProperty("errorCode")) {
        this.setState({
          formErrors: true,
          formErrorMessage: "An error occurred",
          isButtonDisabled: false
        });
      } else {
        this.setState({
          formErrors: false,
          formErrorMessage: "",
          appName: resp.label
        });
      }
    }); 
}


  handleSubmit = () => {
    const {
      emailAddressValue,
      userFirstName,
      userLastName,
      id,
    } = this.state;
    const obj = {
      emailFieldValue: emailAddressValue,
      userFirstName,
      userLastName,
      orgValue: "",
      id
    };
    this.setState({ isButtonDisabled: true }); //disable button to prevent multiple submits

    if (this.state.appId != getPath('getParkerIdAppId') && this.state.appId != getPath('getStoreAppId') && this.state.appId != getPath('getDistributorAppId'))
    {
      this.setState({
        formErrors: true,
        formErrorMessage: "An error occurred",
        isButtonDisabled: false
      });
    }
    else
    {
      getCurrentUser((resp) => {
        if (resp.hasOwnProperty("errorCode")) {
          this.setState({
            formErrors: true,
            formErrorMessage: "You must be logged into your Parker account to request access",
          });
        } else {
          this.setState({
            formErrors: false,
            formErrorMessage: "",
          });
  
          getUserDetails(resp.userId, (resp) => {
            if (resp && resp.hasOwnProperty("errorCode")) {
              this.setState({
                formErrors: true,
                formErrorMessage: "An error occurred",
                isButtonDisabled: false
              });
            }  
            else {
              obj.emailFieldValue = resp.profile.login;            
              obj.userFirstName = resp.profile.firstName;
              obj.userLastName = resp.profile.lastName;
              obj.id = resp.id;
              if(resp.profile.organization)
              {
                obj.orgValue = resp.profile.organization;
              }      
      
              //check if user is already in group
      
              //get approvers and send approval email
              try {
                let approverGroupId;
                switch (this.state.appId) {
                  case getPath('getParkerIdAppId'):
                    approverGroupId = getPath('getParkerIdApproverGroupId');
                    break;
                  case getPath('getStoreAppId'):
                    approverGroupId = getPath('getStoreApproverGroupId');
                    break;
                  case getPath('getDistributorAppId'):
                    approverGroupId = getPath('getDistributorApproverGroupId');
                }
                getUsersFromGroup(approverGroupId, (resp) => {
                  if (resp.hasOwnProperty("errorCode")) {
                    this.setState({
                      formErrors: true,
                      formErrorMessage: "An error occurred",
                      isButtonDisabled: false
                    });
                  } else {
                    this.setState({
                      formErrors: false,
                      formErrorMessage: "",
                    });

                    //send email to each approver
                    for (var i = 0; i < resp.length; i++) {
                      const oktaEmailObj = createOktaEmailObject_Approval(
                        "approve",
                        obj,
                        resp[i].profile.email,
                        resp[i].profile.firstName,
                        this.state.appId,
                        this.state.appName
                      );
                      this.callSmartCommAPI(oktaEmailObj, resp.id);
                    }

                  }
                });
              } catch (err) {
                this.setState({
                  formErrors: true,
                  formErrorMessage: "An error occurred, approval to application cannot be completed at this time",
                  isButtonDisabled: false
                });
              } 
      
            }
          });   
  
        }
      }); 
    }



  };

  callSmartCommAPI(obj, activationToken) {
    const { langId, history } = this.props.contextProps;
    const inputData = window.btoa(new XMLSerializer().serializeToString(obj));


    const postData = {
      name: "Okta_Email_" + Date.now(),
      inputData: inputData,
      project: "-1",
      //project: "157775803",
      config: "157862427",
      range: "1",
      queue: getPath("getSmartCommQueue"),
    };

    //user has be created at this point (work on custom email for approval flow ex. "Your Parker account has been create but awaiting approval for [appname]...")
    //send user confirmation email to activate account
    axios({
      method: "post",
      // url: 'https://apidev.parker.com/dev/smartcomm/api/v5/jobs',
      url: getAPIUrl("getJobsAPIUrl"),
      headers: {
        "Ocp-Apim-Subscription-Key": getPath("getJObAPIOCMSubscriptionKey"),
        //'Ocp-Apim-Subscription-Key': 'c926f915fa8f4305866ca03c51d98e6a'
      },
      data: postData,
    })
      .then((resp) => {

        if (resp && resp.status === 201) {
          const serachParams = this.props.context.history.location.search;
          history.push({
            pathname: "request-success",
            search: serachParams,
            state: {
              userDetails: "",
            },
          });
        } else {
          this.setState({
            formErrors: true,
            formErrorMessage: "Registration cannot be completed at this time",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          formErrors: true,
          formErrorMessage: "Registration cannot be completed at this time",
        });
      });
  }


  render() {
    const {
      formErrors,
      formErrorMessage,
      appName
    } = this.state;


    const { langId, domainName } = this.props.context;
    const registerButtonTfs = {
      background: "#13628D",
      color: "#fff",
      cursor: "pointer",
    };

    const registerButtonParker = {
      background: "#ffb91d",
      color: "#424242",
      cursor: "pointer",
    };

    const registerButtonBaldwin = {
      background: "#ffb91d",
      color: "#424242",
      cursor: "pointer",
    };

      const registerButtonStyle =
      domainName === DOMAIN_TFS
      ? registerButtonTfs
      : domainName === DOMAIN_BALDWIN
      ? registerButtonBaldwin
      : registerButtonParker;

    //const loginbgCls = domainName === DOMAIN_TFS ? "loginBackground_TFS" : domainName === DOMAIN_BALDWIN ? "loginBackground_baldwin" : "loginBackground";

    return (
      <Fragment>
        <div className="content-wrapper">
          <div id="okta-login-container" className={"regcontainer"}>
            <Header />
            <div id="widget-container">
              <div id="okta-sign-in" className="auth-container main-container">
                <div className="auth-content">
                  <div className="registration">
                    <div className="o-form">
                      <h2 className="okta-form-title o-form-head">Access Not Granted</h2>
                      {formErrors ? (
                        <div className="o-form-error-container o-form-has-errors">
                          <div>
                            <div
                              className="okta-form-infobox-error infobox infobox-error"
                              role="alert"
                            >
                              <div className="icon error-16"></div>
                              <p>{formErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                   
                      <div className="required-fields-label" style={{ color: '#a7a7a7', paddingBottom: '20px', fontSize: '90%'}}>
                        Sorry, you do not have access to {appName}. If you require access to {appName}, please click the button below.
                      </div>

                      <div className="o-form-button-bar">
                        <button
                          style={registerButtonStyle}
                          className="button button-primary"
                          value="Register"
                          onClick={this.handleSubmit}
                          disabled={this.state.isButtonDisabled}
                        >
                          Request Access
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <Footer langId={langId} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {(state) => <RequestAccess context={state} contextProps={props} />}
  </Context.Consumer>
);
