export const Localization = {
    'EN': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'Sign In',
        SIGN_IN_FAILED: 'Sign in failed!',
        USERNAME: 'User name',
        PASSWORD: 'Password',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'PLEASE VERIFY YOUR ACCOUNT ',
        EMAIL_VERIFICATION_MESSAGE: 'We are updating our sign in process to provide you with a more secure, single sign on experience across multiple Parker applications. After this update, you will use the email address provided below to sign in. ',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Your Email Address',
        MODAL_EMAIL_MESSAGE: 'You entered your email as #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Please confirm this email address is correct and you understand your email address will be your User ID',
        EMAIL_CONFIRM_KEY: 'Confirm',
        EMAIL_CANCEL_KEY: 'Cancel',
        SUCCESS_HEADER_1: 'THANK YOU FOR UPDATING YOUR EMAIL',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'We have sent a confirmation email to #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Please click the link in the message to',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirm your email address.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Make sure to check your junk or spam folder. The sender is ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        UPDATE_PASSWORD_HEADING: "PLEASE UPDATE YOUR PASSWORD",
        UPDATE_PASSWORD_BODY: "Thank you for verifying your email. You need to update your password to meet our new security policies.",
        NEW_PASSWORD: "New Password",
        RE_ENTER_PASSWORD: "Re-enter Password",
        PASSWORD_RULES_HEADER: "Password should include at least",
        UPDATE_PASSWORD_BUTTON: "Update Password",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 characters in length',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One lowercase',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Uppercase',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Number',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Special Character(!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'YOUR PASSWORD HAS BEEN UPDATED SUCCESSFULLY',
        LOGIN_REDIRECT: 'Click here ',
        LOGIN_REDIRECT_2: "to login",
        SUCCESS_IMPORTANT_MESSAGE: 'Important: Your Email Address will now be your User ID.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Welcome to MyParker',
            content: 'Parker’s secured  personalized portal for registered customers. MyParker is your single source for all pertinent information regarding your Parker account.<br/> <br/> Need a MyParker account? Contact your Parker Representative.'
        }
    },
    'TW': {
        ERR_GENERIC: "\u767c\u751f\u4e00\u822c\u932f\u8aa4\u3002",
        SIGN_IN_TEXT: '登入',
        SIGN_IN_FAILED: '登入失敗！',
        USERNAME: '使用者名稱',
        PASSWORD: '密碼',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: '請驗證您的帳戶',
        EMAIL_VERIFICATION_MESSAGE: '我們正在更新登入流程，以針對多個 Parker 應用程式提供更安全的單一登入體驗。 更新後，您將會使用下方提供的電子郵件地址進行登入。',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: '您的電子郵件地址',
        MODAL_EMAIL_MESSAGE: '您輸入的電子郵件地址為 #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: '請確認此電子郵件地址正確無誤，而且您瞭解您的電子郵件地址將作為您的使用者　ID',
        EMAIL_CONFIRM_KEY: '確認',
        EMAIL_CANCEL_KEY: '取消',
        SUCCESS_HEADER_1: '感謝您更新電子郵件',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '我們已將電子郵件確認信寄至 #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: '請點選訊息中的連結以',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: '確認您的電子郵件地址。',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: '請務必查看您的垃圾郵件資料夾。 寄件人為 ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        UPDATE_PASSWORD_HEADING: "請更新密碼",
        UPDATE_PASSWORD_BODY: "感謝您確認您的電子郵件。 您需要更新您的密碼，以遵循我們新的安全性原則。",
        NEW_PASSWORD: "新密碼 ",
        RE_ENTER_PASSWORD: "重新輸入密碼",
        PASSWORD_RULES_HEADER: "密碼應包含至少",
        UPDATE_PASSWORD_BUTTON: "更新密碼",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 個字元',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一個小寫字母',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一個大寫字母',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一個數字',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一個特殊字元(!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: '您的密碼已成功更新',
        LOGIN_REDIRECT: '按一下這裡',
        LOGIN_REDIRECT_2: '來登入',
        SUCCESS_IMPORTANT_MESSAGE: '重要事項：您的電子郵件地址現在將作為您的使用者　ID。',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'CN': {
        ERR_GENERIC: "\u53d1\u751f\u4e00\u822c\u9519\u8bef\u3002",
        SIGN_IN_TEXT: '注册',
        SIGN_IN_FAILED: '登录失败！',
        USERNAME: '用户名',
        PASSWORD: '密码',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: '请验证您的帐户',
        EMAIL_VERIFICATION_MESSAGE: '我们正在更新我们的登录流程，以便为您提供更安全的登录体验，让您尽享一次登录多个派克应用程序的便利。 更新后，您将使用以下电子邮件地址登录。',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: '您的电子邮件地址',
        MODAL_EMAIL_MESSAGE: '您输入的电子邮件是 #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: '请确认此电子邮件地址正确无误，并且您知晓您的电子邮件地址将是您的用户 ID',
        EMAIL_CONFIRM_KEY: '确认',
        EMAIL_CANCEL_KEY: '取消',
        SUCCESS_HEADER_1: '感谢您更新您的电子邮件地址',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '我们已发送确认电子邮件至 #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: '请点击消息中显示的链接，',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: '确认您的电子邮件地址。',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: '确保检查您的垃圾邮件文件夹。 发送人是 ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        UPDATE_PASSWORD_HEADING: "请更新您的密码",
        UPDATE_PASSWORD_BODY: "感谢您验证您的电子邮件地址。 您需要更新密码以满足我们新安全政策要求。",
        NEW_PASSWORD: "新密码",
        RE_ENTER_PASSWORD: "重新输入密码",
        PASSWORD_RULES_HEADER: "密码应至少包含",
        UPDATE_PASSWORD_BUTTON: "更新密码",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 个字符',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One lowercase',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一个大写字母 ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一个数字 ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '一个特殊字符（!@#$%^&*）',
                valid: false
            }],
        SUCCESS_HEADER_2: '您的密码已成功更新 ',
        LOGIN_REDIRECT: '点击此处 ',
        LOGIN_REDIRECT_2: '登录 ',
        SUCCESS_IMPORTANT_MESSAGE: '重要提示：您的电子邮件地址现在将作为您的用户 ID 。',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'TR': {
        ERR_GENERIC: "Soysal bir hata olu\u015ftu.",
        SIGN_IN_TEXT: 'OTURUM AÇIN',
        SIGN_IN_FAILED: 'Oturum açma işlemi başarısız!',
        USERNAME: 'Kullanıcı Adı',
        PASSWORD: 'Parola',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'LÜTFEN HESABINIZI DOGRULAYIN',
        EMAIL_VERIFICATION_MESSAGE: 'Size birden fazla Parker uygulamasinda daha güvenli ve tek bir oturum açma deneyimi saglamak için oturum açma sürecimizi güncelliyoruz. Bu güncelleme sonrasinda oturum açmak için asagida saglanan e-posta adresini kullanacaksiniz.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'E-posta Adresiniz',
        MODAL_EMAIL_MESSAGE: 'Girdiginiz e-posta adresi: #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Lütfen bu e-posta adresinin dogru oldugunu ve sizin Kullanici Kimliginiz olarak kullanilacagini anladiginizi onaylayin',
        EMAIL_CONFIRM_KEY: 'Onayla',
        EMAIL_CANCEL_KEY: 'Iptal Et',
        SUCCESS_HEADER_1: 'E-POSTA ADRESINIZI GÜNCELLEDIGINIZ IÇIN TESEKKÜR EDERIZ',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Su adrese bir onay e-postasi gönderdik: Lütfen gelen mesajdaki linke tiklayarak e-posta adresinizi onaylayin.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Şu adrese bir onay e-postası gönderdik: #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'E-posta adresinizi onaylamak için',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'lütfen mesajdaki bağlantıyı tıklayın.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Çöp kutusu veya spam klasörünü kontrol etmeyi unutmayın. ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Çöp kutusu veya spam klasörünü kontrol etmeyi unutmayin. Gönderici e-posta adresi: parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "LÜTFEN PAROLANIZI GÜNCELLEYİN",
        UPDATE_PASSWORD_BODY: "E-posta adresinizi doğruladığınız için teşekkür ederiz. Yeni güvenlik politikamıza uymak için parolanızı güncellemeniz gerekmektedir.",
        NEW_PASSWORD: "Yeni Parola ",
        RE_ENTER_PASSWORD: "Parolayı Tekrar Girin ",
        PASSWORD_RULES_HEADER: "Parolada uyulması gereken şartlar:",
        UPDATE_PASSWORD_BUTTON: "Parolayı Güncelleme",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'En az 12 karakter ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Bir küçük harf',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Bir büyük harf, ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Bir rakam,',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Bir özel karakter(!@#$%^&*) içermelidir.',
                valid: false
            }],
        SUCCESS_HEADER_2: 'PAROLANIZ BAŞARIYLA GÜNCELLENDİ',
        LOGIN_REDIRECT: 'Buraya tıklayarak ',
        LOGIN_REDIRECT_2: 'giriş yapın ',
        SUCCESS_IMPORTANT_MESSAGE: 'Önemli Hatırlatma: E-posta adresiniz artık Kullanıcı Kimliğiniz olarak kullanılacaktır.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'TH': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'ลงชื่อเข้าใช้',
        SIGN_IN_FAILED: 'การลงชื่อเข้าใช้ล้มเหลว',
        USERNAME: 'ชื่อผู้ใช้',
        PASSWORD: 'รหัสผ่าน',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'โปรดยืนยันบัญชีของคุณ',
        EMAIL_VERIFICATION_MESSAGE: 'เรากำลังอัปเดตกระบวนการลงชื่อเข้าระบบ เพื่อมอบความปลอดภัยมากขึ้น รวมถึงประสบการณ์ลงชื่อเข้าระบบครั้งเดียวสำหรับแอปพลิเคชันหลายอุปกรณ์ หลังจากกการอัปเดต คุณจะใช้อีเมลที่ระบุด้านล่างเพื่อลงชื่อเข้าระบบ',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'อีเมลของคุณ',
        MODAL_EMAIL_MESSAGE: 'คุณจะป้อนอีเมลเป็น #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'โปรดยืนยันว่าอีเมลนี้ถูกต้อง และคุณรับทราบว่าที่อยู่อีเมลของคุณจะเป็น ID ผู้ใช้ของคุณด้วย',
        EMAIL_CONFIRM_KEY: 'ยืนยัน',
        EMAIL_CANCEL_KEY: 'ยกเลิก',
        SUCCESS_HEADER_1: 'ขอบคุณที่อัปเดตอีเมล',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'เราได้ส่งอีเมลยืนยันไปที่ โปรดคลิกลิงก์ในข้อความเพื่อยืนยันที่อยู่อีเมล',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'เราได้ทำการส่งอีเมลยืนยันถึง #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'โปรดกดลิงก์ในข้อความเพื่อที่จะ',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'ยืนยันที่อยู่อีเมลของคุณ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'โปรดตรวจดูในโฟลเดอร์ขยะหรือสแปมของคุณ ผู้ส่งคือ ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'โปรดตรวจดูโฟลเดอร์ขยะหรือสแปม ผู้ส่งคือ parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "โปรดอัปเดตรหัสผ่าน",
        UPDATE_PASSWORD_BODY: "ขอบคุณที่ยืนยันอีเมล คุณจะต้องอัปเดตรหัสผ่านให้ตรงกับนโยบายการรักษาความปลอดภัยใหม่ของเรา",
        NEW_PASSWORD: "รหัสผ่านใหม่ ",
        RE_ENTER_PASSWORD: "ป้อนรหัสผ่านอีกครั้ง",
        PASSWORD_RULES_HEADER: "รหัสผ่านควรจะมีความยาวอย่างน้อย",
        UPDATE_PASSWORD_BUTTON: "อัปเดตรหัสผ่าน",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 ตัวอักษร',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'มีตัวพิมพ์เล็กอย่างน้อย 1 ตัว',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'ตัวพิมพ์ใหญ่ ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'ตัวเลข',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'อักขระพิเศษ (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'อัปเดตรหัสผ่านของคุณเรียบร้อยแล้ว ',
        LOGIN_REDIRECT: 'คลิกที่นี่',
        LOGIN_REDIRECT_2: 'เพื่อเข้าสู่ระบบ',
        SUCCESS_IMPORTANT_MESSAGE: 'ข้อสำคัญ: ตอนนี้ ที่อยู่อีเมลของคุณจะเป็น ID ผู้ใช้ของคุณด้วยเช่นกัน',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'SV': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'LOGGA IN',
        SIGN_IN_FAILED: 'Inloggningen misslyckades!',
        USERNAME: 'Användarnamn',
        PASSWORD: 'Lösenord',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VERIFIERA DITT KONTO',
        EMAIL_VERIFICATION_MESSAGE: 'Vi uppdaterar vår inloggningsprocess för att ge dig en säkrare upplevelse med enkel inloggning i flera Parker-program. Efter den här uppdateringen ska du använda e-postadressen som du anger nedan för att logga in.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Din e-postadress',
        MODAL_EMAIL_MESSAGE: 'Du angav din e-postadress som #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Bekräfta att den här e-postadressen är korrekt och att du förstår att din e-postadress kommer att bli ditt användar-ID',
        EMAIL_CONFIRM_KEY: 'Bekräfta',
        EMAIL_CANCEL_KEY: 'Avbryt',
        SUCCESS_HEADER_1: 'TACK FÖR ATT DU UPPDATERADE DIN E-POSTADRESS',
        //SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har skickat ett bekräftelsemeddelande till . Klicka på länken i meddelandet för att bekräfta din e-postadress.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har skickat ett bekräftelsemeddelande till #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Klicka på länken i meddelandet för att',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'bekräfta din e-postadress.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Kolla i skräppost- och spammapparna. Avsändaren är ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        //SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Se till att kolla i skräppost- och spammapparna. Avsändaren är parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "PLEASE UPDATE YOUR PASSWORD",
        UPDATE_PASSWORD_BODY: "Thank you for verifying your email. You need to update your password to meet our new security policies.",
        NEW_PASSWORD: "Nytt lösenord ",
        RE_ENTER_PASSWORD: "Ange lösenordet igen ",
        PASSWORD_RULES_HEADER: "Lösenordet måste bestå av minst",
        UPDATE_PASSWORD_BUTTON: "Uppdatera lösenord",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 tecken',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'En gemen',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'En versal ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'En siffra ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Ett specialtecken (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'DITT LÖSENORD HAR UPPDATERATS ',
        LOGIN_REDIRECT: 'Klicka här ',
        LOGIN_REDIRECT_2: 'för att logga in',
        SUCCESS_IMPORTANT_MESSAGE: 'Viktigt: din e-postadress är nu ditt användar-ID.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'RU': {
        ERR_GENERIC: "\u0412\u043e\u0437\u043d\u0438\u043a\u043b\u0430 \u043e\u0448\u0438\u0431\u043a\u0430 \u043e\u0431\u0449\u0435\u0433\u043e \u0445\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430.",
        SIGN_IN_TEXT: 'ВХОД В СИСТЕМУ',
        SIGN_IN_FAILED: 'Не удалось войти в систему!',
        USERNAME: 'Имя пользователя',
        PASSWORD: 'Пароль',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'ПОДТВЕРДИТЕ СВОЮ УЧЕТНУЮ ЗАПИСЬ.',
        EMAIL_VERIFICATION_MESSAGE: 'Мы обновляем процедуру входа в систему, чтобы предоставить надежный единый вход в систему для различных приложений Parker. После обновления для входа в систему нужно будет использовать адрес электронной почты, указанный ниже.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Ваш адрес электронной почты',
        MODAL_EMAIL_MESSAGE: 'Вы указали следующий адрес электронной почты #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Подтвердите, что вы указали правильный адрес электронной почты и поняли, что он будет использоваться в качестве идентификатора пользователя.',
        EMAIL_CONFIRM_KEY: 'Подтвердить',
        EMAIL_CANCEL_KEY: 'Отменить',
        SUCCESS_HEADER_1: 'СПАСИБО, ЧТО ОБНОВИЛИ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ.',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Мы отправили письмо для подтверждения на адрес . Пройдите по ссылке в сообщении, чтобы подтвердить электронный адрес.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Мы отправили письмо для подтверждения на адрес #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Перейдите по ссылке в письме, чтобы',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'подтвердить свой адрес электронной почты.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Не забудьте проверить папку спама. Адрес отправителя ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Не забудьте проверить папку спама. Адрес отправителя — parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "ОБНОВИТЕ ПАРОЛЬ.",
        UPDATE_PASSWORD_BODY: "Мы отправили письмо для подтверждения на адрес . Пройдите по ссылке в сообщении, чтобы подтвердить электронный адрес.",
        NEW_PASSWORD: "Новый пароль ",
        RE_ENTER_PASSWORD: "Повторите пароль ",
        PASSWORD_RULES_HEADER: "Пароль должен содержать не менее",
        UPDATE_PASSWORD_BUTTON: "Обновить пароль",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 символов ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'и одну строчную букву',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'одну заглавную букву; ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'одну цифру; ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'один специальный символ (!@#$%^&*).',
                valid: false
            }],
        SUCCESS_HEADER_2: 'ВЫ УСПЕШНО ОБНОВИЛИ ПАРОЛЬ.',
        LOGIN_REDIRECT: 'Нажмите сюда, ',
        LOGIN_REDIRECT_2: 'чтобы войти в систему',
        SUCCESS_IMPORTANT_MESSAGE: 'Обратите внимание: в качестве идентификатора пользователя теперь используется ваш адрес электронной почты.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'PT': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'INICIAR SESSÃO',
        SIGN_IN_FAILED: 'Falha ao iniciar sessão!',
        USERNAME: 'Nome de usuário',
        PASSWORD: 'Senha',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VERIFIQUE SUA CONTA',
        EMAIL_VERIFICATION_MESSAGE: 'Estamos atualizando nosso processo de logon para fornecer uma experiência de logon único mais segura em vários aplicativos Parker. Após esta atualização, você usará o endereço de e-mail fornecido abaixo para efetuar o logon.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Endereço de e-mail',
        MODAL_EMAIL_MESSAGE: 'Você inseriu seu e-mail como #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Confirme se este endereço de e-mail está correto porque, a partir de agora, seu endereço de e-mail será seu ID de usuário',
        EMAIL_CONFIRM_KEY: 'Confirmar',
        EMAIL_CANCEL_KEY: 'Cancelar',
        SUCCESS_HEADER_1: 'OBRIGADO POR ATUALIZAR SEU E-MAIL',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Enviamos um e-mail de confirmação para . Clique no link da mensagem para confirmar seu endereço de e-mail.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Enviamos um e-mail de confirmação para #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Clique no link na mensagem para',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirmar seu endereço de e-mail.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Verifique a sua pasta de lixo eletrônico ou de spam. O  ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Verifique a sua pasta de lixo eletrônico ou de spam. O emissor é parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "ATUALIZE SUA SENHA",
        UPDATE_PASSWORD_BODY: "Obrigado por verificar seu e-mail. Você precisa atualizar sua senha para atender às nossas novas políticas de segurança.",
        NEW_PASSWORD: "Nova senha",
        RE_ENTER_PASSWORD: "Digite novamente a senha ",
        PASSWORD_RULES_HEADER: "A senha deve incluir pelo menos",
        UPDATE_PASSWORD_BUTTON: "Atualizar senha",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 caracteres',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Um minúsculo',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Uma letra maiúscula ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Um número ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Um caractere especial (!@#%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'SUA SENHA FOI ATUALIZADA COM ÊXITO',
        LOGIN_REDIRECT: 'Clique aqui',
        LOGIN_REDIRECT_2: 'para entrar',
        SUCCESS_IMPORTANT_MESSAGE: 'Importante: a partir de agora, seu endereço de e-mail será seu ID de usuário.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'PL': {
        ERR_GENERIC: "Wyst\u0105pi\u0142 b\u0142\u0105d og\u00f3lny.",
        SIGN_IN_TEXT: 'ZALOGUJ SIĘ',
        SIGN_IN_FAILED: 'Logowanie nieudane.',
        USERNAME: 'Nazwa użytkownika',
        PASSWORD: 'Hasło',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'ZWERYFIKUJ SWOJE KONTO',
        EMAIL_VERIFICATION_MESSAGE: 'Zmieniamy proces logowania, by zapewnic Ci bezpieczniejszy sposób jednokrotnego logowania w wielu aplikacjach firmy Parker. Po tej zmianie do logowania sie bedziesz uzywac adresu e-mail podanego ponizej.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Twój adres e-mail',
        MODAL_EMAIL_MESSAGE: 'Wprowadzono adres e-mail #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Potwierdz, ze ten adres jest poprawny i ze rozumiesz, ze odtad bedzie on uzywany jako Twój identyfikator uzytkownika',
        EMAIL_CONFIRM_KEY: 'Potwierdz',
        EMAIL_CANCEL_KEY: 'Anuluj',
        SUCCESS_HEADER_1: 'DZIEKUJEMY ZA ZAKTUALIZOWANIE ADRESU E-MAIL',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Wyslalismy wiadomosc e-mail z potwierdzeniem na adres . Aby potwierdzic swój adres e-mail, kliknij lacze w wiadomosci.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Wysłaliśmy wiadomość e-mail z potwierdzeniem na adres #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Aby potwierdzić adres e-mail, kliknij link w wiadomości',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirm your email address.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Sprawdź kosz lub folder ze spamem. Nadawcą jest ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Sprawdz folder ze spamem. Wiadomosc zostala wyslana z adresu parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "ZAKTUALIZUJ HASŁO",
        UPDATE_PASSWORD_BODY: "DZIĘKUJEMY ZA ZWERYFIKOWANIE ADRESU E-MAIL. Zgodnie z nowymi zasadami bezpieczeństwa musisz zmienić hasło.",
        NEW_PASSWORD: "Nowe hasło",
        RE_ENTER_PASSWORD: "Wprowadź ponownie hasło ",
        PASSWORD_RULES_HEADER: "Hasło powinno się składać z co najmniej",
        UPDATE_PASSWORD_BUTTON: "Zmień hasło",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 znaków',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Jedna mała litera',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'jedną wielką literę,',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'jedną cyfrę,',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'i jeden znak specjalny (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'HASŁO ZOSTAŁO ZMIENIONE ',
        LOGIN_REDIRECT: 'Kliknij tutaj ',
        LOGIN_REDIRECT_2: ', aby się zalogować ',
        SUCCESS_IMPORTANT_MESSAGE: 'Ważne: Twój adres e-mail będzie odtąd Twoim identyfikatorem użytkownika.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'NO': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'LOGG PÅ',
        SIGN_IN_FAILED: 'Pålogging mislyktes!',
        USERNAME: 'Brukernavn',
        PASSWORD: 'Passord',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'BEKREFT KONTOEN DIN',
        EMAIL_VERIFICATION_MESSAGE: 'Vi oppdaterer påloggingsprosessen vår for å gi deg en sikrere og enklere pålogging til flere Parker-programmer. Etter denne oppdateringen skal du bruke e-postadressen som er oppgitt nedenfor, til å logge på.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'E-postadressen din',
        MODAL_EMAIL_MESSAGE: 'Du skrev inn e-postadressen din som #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Bekreft at denne e-postadressen er korrekt og at du forstår at e-postadressen din vil bli bruker-ID-en din',
        EMAIL_CONFIRM_KEY: 'Bekreft',
        EMAIL_CANCEL_KEY: 'Avbryt',
        SUCCESS_HEADER_1: 'TAKK FOR AT DU OPPDATERER E-POSTADRESSEN',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har sendt en bekreftelse på e-post til . Klikk på koblingen i meldingen for å bekrefte e-postadressen din.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har sendt en bekreftelse på e-post til #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Klikk på koblingen i meldingen for å',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'bekrefte e-postadressen din.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Husk å sjekke søppelpostmappen. Avsenderen er ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Husk å sjekke søppelpostmappen. Avsenderen er parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "OPPDATER PASSORDET DITT",
        UPDATE_PASSWORD_BODY: "Takk for at du bekrefter e-postadressen DIN. Du må oppdatere passordet ditt etter de nye sikkerhetspolicyene våre.",
        NEW_PASSWORD: "Nytt passord ",
        RE_ENTER_PASSWORD: "Skriv inn passordet på nytt ",
        PASSWORD_RULES_HEADER: "Passordet må inkludere minst",
        UPDATE_PASSWORD_BUTTON: "Oppdater passord",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'tolv tegn',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Én liten bokstav',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Én stor bokstav ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Ett tall ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Ett spesialtegn (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'PASSORDET ER OPPDATERT ',
        LOGIN_REDIRECT: 'Klikk her',
        LOGIN_REDIRECT: 'for å logge på',
        SUCCESS_IMPORTANT_MESSAGE: 'Viktig: E-postadressen din blir nå bruker-ID-en din.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'NL': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'AANMELDEN',
        SIGN_IN_FAILED: 'Aanmelden mislukt!',
        USERNAME: 'Gebruikersnaam',
        PASSWORD: 'Wachtwoord',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'CONTROLEER UW ACCOUNT ',
        EMAIL_VERIFICATION_MESSAGE: 'We werken ons aanmeldproces bij om u een veiligere single sign-on-ervaring te bieden voor meerdere Parker-toepassingen. Na deze update gebruikt u het hieronder opgegeven e-mailadres om aan te melden.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Uw e-mailadres',
        MODAL_EMAIL_MESSAGE: 'U hebt uw e-mailadres ingevoerd als #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Bevestig dat dit e-mailadres juist is en dat u begrijpt dat uw e-mailadres uw gebruikers-id is',
        EMAIL_CONFIRM_KEY: 'Bevestigen',
        EMAIL_CANCEL_KEY: 'Annuleren',
        SUCCESS_HEADER_1: 'HARTELIJK DANK VOOR HET BIJWERKEN VAN UW E-MAILADRES',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'We hebben een bevestigingse-mail verstuurd naar . Klik op de link in het bericht om uw e-mailadres te bevestigen.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'We hebben een bevestigingse-mail verstuurd naar. #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Klik op de link in het bericht om',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'uw e-mailadres te bevestigen.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Controleer de junk- of spammap. De afzender is ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Controleer de junk- of spammap. De verzender is parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "WERK UW WACHTWOORD BIJ",
        UPDATE_PASSWORD_BODY: "Hartelijk dank voor het verifiëren van uw e-mailadres. U moet uw wachtwoord bijwerken om aan ons nieuwe beveiligingsbeleid te voldoen.",
        NEW_PASSWORD: "Nieuw wachtwoord ",
        RE_ENTER_PASSWORD: "Voer het wachtwoord opnieuw in ",
        PASSWORD_RULES_HEADER: "Het wachtwoord moet minsten het volgende bevatten:",
        UPDATE_PASSWORD_BUTTON: "Wachtwoord bijwerken",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 tekens lang ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Één kleine lettere',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Een hoofdletter ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Een cijfer',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Een speciaal teken (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'UW WACHTWOORD IS BIJGEWERKT ',
        LOGIN_REDIRECT: 'Klik hier ',
        LOGIN_REDIRECT_2: 'Om in te loggen ',
        SUCCESS_IMPORTANT_MESSAGE: 'Belangrijk: uw e-mailadres is nu uw gebruikers-id.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'KO': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: '로그인',
        SIGN_IN_FAILED: '로그인 실패!',
        USERNAME: '사용자 이름',
        PASSWORD: '암호',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: '귀하의 계정을 확인해 주십시오.',
        EMAIL_VERIFICATION_MESSAGE: '저희는 현재 다양한 Parker 애플리케이션에 대한 더욱 안전한 싱글 사인온 경험을 제공하기 위하여 로그인 프로세스를 업데이트하고 있습니다. 이 업데이트가 마무리되고 나면, 아래에 제공되는 이메일 주소를 이용해 로그인하시게 됩니다. ',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: '귀하의 이메일 주소',
        MODAL_EMAIL_MESSAGE: '귀하가 입력한 이메일 주소 #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: '이 이메일 주소가 정확한지 확인해 주십시오. 이제부터 해당 이메일 주소가 귀하의 사용자 ID가 됨을 기억하시기 바랍니다.',
        EMAIL_CONFIRM_KEY: '확인',
        EMAIL_CANCEL_KEY: '취소',
        SUCCESS_HEADER_1: '이메일을 업데이트해 주셔서 감사합니다.',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '다음 주소로 확인 이메일을 보내드렸습니다. 메시지에 있는 링크를 클릭하여 이메일 주소를 확인해 주십시오.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '다음 주소로 확인 이메일을 보내드렸습니다. #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: '메시지에 있는 링크를 클릭하여',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: '이메일 주소를 확인해 주십시오.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: '정크 또는 스팸 폴더를 확인하십시오. 보낸 사람: ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: '정크 또는 스팸 폴더를 확인하십시오. 보낸 사람은 parkeraccount@logintest.parker.com입니다.',
        UPDATE_PASSWORD_HEADING: "암호를 업데이트해 주십시오.",
        UPDATE_PASSWORD_BODY: "이메일을 확인해 주셔서 감사합니다. 당사의 새 보안 정책에 따라 귀하의 암호를 업데이트해야 합니다.",
        NEW_PASSWORD: "새 암호 ",
        RE_ENTER_PASSWORD: "암호 재입력 ",
        PASSWORD_RULES_HEADER: "암호는 최소",
        UPDATE_PASSWORD_BUTTON: "암호 업데이트",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12자 이상이어야 합니다.',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '소문자 1개',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '대문자 1개, ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '숫자 1개 ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '숫자 문자(!@#$%^&*) 1개',
                valid: false
            }],
        SUCCESS_HEADER_2: '귀하의 암호가 성공적으로 업데이트되었습니다. ',
        LOGIN_REDIRECT: '여기를 클릭하여 ',
        LOGIN_REDIRECT_2: '로그인 ',
        SUCCESS_IMPORTANT_MESSAGE: '중요: 이제 귀하의 이메일 주소가 귀하의 사용자 ID가 됩니다.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'JA': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'サインイン',
        SIGN_IN_FAILED: 'サインインに失敗しました。',
        USERNAME: 'ユーザー名',
        PASSWORD: 'パスワード',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'アカウント情報をご確認ください',
        EMAIL_VERIFICATION_MESSAGE: 'Parkerのさまざまなアプリケーションでさらに安全なシングルサインオンを実現するため、サインインプロセスのアップデートが予定されています。 このアップデート後、ログインには以下に入力する電子メールアドレスが必要になります。',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'お客様の電子メールアドレス',
        MODAL_EMAIL_MESSAGE: 'お客様が入力した電子メールアドレス: #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: '入力した電子メールアドレスが正しいことをご確認ください。この電子メールアドレスがお客様のユーザーIDとなります。',
        EMAIL_CONFIRM_KEY: '確認',
        EMAIL_CANCEL_KEY: 'キャンセル',
        SUCCESS_HEADER_1: '電子メールの更新にご協力いただきありがとうございます',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '確認用の電子メールを次の電子メールアドレスにお送りいたしました: 電子メール内の本文にあるリンクをクリックして、電子メールアドレスをご確認ください。',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: '確認用の電子メールを次のEメールアドレスにお送りいたしました #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'メッセージ内のリンクをクリックして、',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'Eメールアドレスを確認してください。',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'ゴミ箱やスパムフォルダもご確認ください。 送信者 ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'ゴミ箱やスパムフォルダもご確認ください。 電子メールの差出人はparkeraccount@logintest.parker.comです。',
        UPDATE_PASSWORD_HEADING: "パスワードを更新してください",
        UPDATE_PASSWORD_BODY: "電子メールの確認にご協力いただきありがとうございます。 新しいセキュリティポリシーに準拠するように、パスワードを更新する必要があります。",
        NEW_PASSWORD: "新しいパスワード ",
        RE_ENTER_PASSWORD: "新しいパスワード（確認用）",
        PASSWORD_RULES_HEADER: "パスワードは",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12文字以上の長さにする必要があります',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '1つの小文字',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '1文字以上の英大文字を含む ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '1つ以上の数字を含む ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '1つ以上の特殊文字を含む（!@#$%^&*',
                valid: false
            }],
        UPDATE_PASSWORD_BUTTON: "パスワードの更新",
        SUCCESS_HEADER_2: 'パスワードが正常に更新されました',
        LOGIN_REDIRECT: 'ここをクリック',
        LOGIN_REDIRECT_2: 'ログイン',
        SUCCESS_IMPORTANT_MESSAGE: '重要: 今後、お客様の電子メールアドレスがお客様のユーザーIDとなります',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'IT': {
        ERR_GENERIC: "Si \u00e8 verificato un errore generico.",
        SIGN_IN_TEXT: 'ACCEDI',
        SIGN_IN_FAILED: 'Accesso non riuscito',
        USERNAME: 'Nome utente',
        PASSWORD: 'Password',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VERIFICA DELL’ACCOUNT',
        EMAIL_VERIFICATION_MESSAGE: 'Stiamo aggiornando la procedura di accesso per fornirti un’esperienza Single Sign-On più sicura su tutte le applicazioni Parker. Al termine dell’aggiornamento, potrai utilizzare l’indirizzo e-mail qui sotto per accedere.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Indirizzo e-mail',
        MODAL_EMAIL_MESSAGE: 'Hai inserito l’indirizzo e-mail #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Conferma che l’indirizzo e-mail è corretto: questo sarà anche il tuo ID utente',
        EMAIL_CONFIRM_KEY: 'Conferma',
        EMAIL_CANCEL_KEY: 'Annulla',
        SUCCESS_HEADER_1: 'GRAZIE PER AVER AGGIORNATO L’E-MAIL.',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'È stata inviata una e-mail di conferma a . Fai clic sul link all’interno del messaggio per confermare l’indirizzo e-mail.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'È stata inviata una e-mail di conferma a #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Fare clic sul collegamento contenuto nel messaggio per',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: "confermare l'indirizzo e-mail.",
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Assicurarsi di verificare anche nella casella della posta  ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Assicurati di verificare anche nella casella della posta indesiderata. Il mittente è parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "AGGIORNAMENTO DELLA PASSWORD",
        UPDATE_PASSWORD_BODY: "Grazie per aver verificato l’indirizzo e-mail. In conformità ai nostri nuovi criteri di sicurezza, è necessario aggiornare la password.",
        NEW_PASSWORD: "Nuova password ",
        RE_ENTER_PASSWORD: "Reimmetti la password ",
        PASSWORD_RULES_HEADER: "La password deve includere almeno",

        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 caratteri ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Una lettera minuscola',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Una lettera maiuscola ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un numero ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un carattere speciale (!@#$%^&*)',
                valid: false
            }],
        UPDATE_PASSWORD_BUTTON: "Modifica password",
        SUCCESS_HEADER_2: 'LA PASSWORD È STATA AGGIORNATA CORRETTAMENTE ',
        LOGIN_REDIRECT: 'Fai clic qui ',
        LOGIN_REDIRECT_2: "per effettuare l'accesso",
        SUCCESS_IMPORTANT_MESSAGE: 'Importante: il tuo indirizzo e-mail corrisponde ora al tuo ID utente.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'HU': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'BEJELENTKEZÉS',
        SIGN_IN_FAILED: 'Bejelentkezés sikertelen!',
        USERNAME: 'Felhasználónév',
        PASSWORD: 'Jelszó',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'KÉRJÜK, EROSÍTSE MEG FIÓKJÁT',
        EMAIL_VERIFICATION_MESSAGE: 'Frissítjük bejelentkezési eljárásunkat, hogy biztonságosabb, egységes bejelentkezési lehetoséget nyújtsunk a Parker alkalmazásokhoz. A frissítést követoen az alább megadott e-mail címmel jelentkezhet be.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Az Ön e-mail címe',
        MODAL_EMAIL_MESSAGE: 'Így adta meg e-mail címét: #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Kérjük, erosítse meg, hogy a megadott e-mail cím helyes, és megértette, hogy ez az e-mail cím lesz az Ön felhasználói azonosítója',
        EMAIL_CONFIRM_KEY: 'Megerosítés',
        EMAIL_CANCEL_KEY: 'Mégse',
        SUCCESS_HEADER_1: 'KÖSZÖNJÜK, HOGY FRISSÍTETTE E-MAIL CÍMÉT',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Megerosíto e-mailt küldtünk a következo címre: . Kérjük, kattintson az üzenetben található linkre az e-mail címe megerosítéséhez.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Egy megerősítő e-mailt küldtünk az alábbi e-mail címre: #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Kérjük, kattintson az üzenetben lévő hivatkozásra',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'az e-mail címe megerősítéséhez',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Mindenképpen nézze meg a levélszemét vagy spam mappáját ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Mindenképpen nézze meg a levélszemét vagy spam mappáját is. A küldo az parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "KÉRJÜK, FRISSÍTSE JELSZAVÁT",
        UPDATE_PASSWORD_BODY: "Köszönjük, hogy megerősítette e-mail címét. Frissítenie kell a jelszavát, hogy megfeleljen új biztonsági szabályzatunknak.",
        NEW_PASSWORD: "Új jelszó ",
        RE_ENTER_PASSWORD: "Adja meg újra a jelszót",
        PASSWORD_RULES_HEADER: "A jelszónak legalább egyet kell tartalmaznia az alábbiak közül:",
        UPDATE_PASSWORD_BUTTON: "Jelszó frissítése",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 karakter hosszúságú ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Egy kisbetű',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Egy nagybetűt ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Egy számot',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Egy speciális karaktert (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'JELSZAVÁT SIKERESEN FRISSÍTETTE ',
        LOGIN_REDIRECT: 'Kattintson ide',
        LOGIN_REDIRECT_2: "a belépéshez",
        SUCCESS_IMPORTANT_MESSAGE: 'Fontos: az e-mail címe lesz az Ön felhasználói azonosítója.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'FR': {
        ERR_GENERIC: "Une erreur g\ufffdn\ufffdrique s'est produite.",
        SIGN_IN_TEXT: 'SE CONNECTER',
        SIGN_IN_FAILED: 'Échec de la connexion.',
        USERNAME: "Nom d'utilisateur",
        PASSWORD: 'Mot de passe',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VEUILLEZ VÉRIFIER VOTRE COMPTE ',
        EMAIL_VERIFICATION_MESSAGE: "Nous mettons à jour notre processus d'authentification pour vous proposer une expérience d'authentification unique, plus sécurisée, dans plusieurs applications Parker. Une fois cette mise à jour effectuée, vous utiliserez l'adresse e-mail indiquée ci-dessous pour vous authentifier.",
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Votre adresse e-mail',
        MODAL_EMAIL_MESSAGE: "Vous avez saisi l'adresse e-mail suivante #mailid",
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Veuillez confirmer que cette adresse e-mail est correcte et que vous avez compris que votre adresse e-mail sera votre ID utilisateur',
        EMAIL_CONFIRM_KEY: 'Confirmer',
        EMAIL_CANCEL_KEY: 'Annuler',
        SUCCESS_HEADER_1: "MERCI D'AVOIR MIS À JOUR VOTRE ADRESSE E-MAIL",
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: "Nous venons d'envoyer un e- mail de confirmation à l'adresse . Veuillez cliquer sur le lien dans ce message pour confirmer votre adresse e-mail.",
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: "Nous venons d'envoyer un e-mail de confirmation à l'adresse #mailid.",
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Veuillez cliquer sur le lien dans ce message pour',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirmer votre adresse e-mail.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: "N'oubliez pas de vérifier votre dossier de courrier indésirable. ",
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: " N'oubliez pas de vérifier votre dossier de courrier indésirable. L'expéditeur est parkeraccount@logintest.parker.com",
        UPDATE_PASSWORD_HEADING: "VEUILLEZ METTRE À JOUR VOTRE MOT DE PASSE",
        UPDATE_PASSWORD_BODY: "Merci d'avoir vérifié votre adresse e-mail. Vous devez mettre à jour votre mot de passe pour être en règle avec nos nouvelles politiques de sécurité.",
        NEW_PASSWORD: "Nouveau mot de passe ",
        RE_ENTER_PASSWORD: "Saisir à nouveau le mot de passe ",
        PASSWORD_RULES_HEADER: "Le mot de passe doit comprendre au moins",

        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 caractères',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Une minuscule',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Une majuscule ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un chiffre ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un caractère spécial (!@#$%^&*)',
                valid: false
            }],
        UPDATE_PASSWORD_BUTTON: "Mettre à jour le mot de passe",
        SUCCESS_HEADER_2: 'VOTRE MOT DE PASSE A ÉTÉ MIS À JOUR AVEC SUCCÈS ',
        LOGIN_REDIRECT: 'Cliquez ici ',
        LOGIN_REDIRECT_2: 'pour vous connecter ',
        SUCCESS_IMPORTANT_MESSAGE: 'Important : votre adresse e-mail est désormais votre ID utilisateur.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'FI': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'KIRJAUDU',
        SIGN_IN_FAILED: 'Kirjautuminen epäonnistui!',
        USERNAME: 'Käyttäjänimi',
        PASSWORD: 'Salasana',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VAHVISTA KÄYTTÄJÄTILISI',
        EMAIL_VERIFICATION_MESSAGE: 'Päivitämme kirjautumisprosessiamme, jotta voimme tarjota sinulle entistä turvallisemman kertakirjautumisen erilaisiin Parker-applikaatioihin. Tämän päivityksen jälkeen voit kirjautua sisään alla annetulla sähköpostiosoitteella.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Sähköpostiosoitteesi',
        MODAL_EMAIL_MESSAGE: 'Annoit sähköpostiosoitteesi nimellä #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Vahvista, että sähköpostiosoite on oikein ja että ymmärrät, että sähköpostiosoitteesi on jatkossa käyttäjätunnuksesi.',
        EMAIL_CONFIRM_KEY: 'Vahvista',
        EMAIL_CANCEL_KEY: 'Peruuta',
        SUCCESS_HEADER_1: 'KIITOS SÄHKÖPOSTIOSOITTEESI PÄIVITYKSESTÄ',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Olemme lähettäneet vahvistussähköpostiviestin osoitteeseen . Vahvista sähköpostiosoitteesi napsauttamalla viestissä olevaa linkkiä.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Olemme lähettäneet vahvistussähköpostin osoitteeseen #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Napsauta viestissä olevaa linkkiä, jotta voit',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'vahvistaa sähköpostiosoitteesi.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Muista tarkistaa myös roskapostikansiosi. Lähettäjä on ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Muista tarkistaa myös roskapostikansiosi. Lähettäjä on parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "PÄIVITÄ SALASANASI",
        UPDATE_PASSWORD_BODY: "Kiitos, että vahvistit sähköpostiosoitteesi. Sinun on päivitettävä salasanasi uusien tietoturvakäytäntöjemme täyttämiseksi.",
        NEW_PASSWORD: "Uusi salasana",
        RE_ENTER_PASSWORD: "Anna salasana uudelleen ",
        PASSWORD_RULES_HEADER: "Salasanassa on oltava vähintään",

        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 merkkiä',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Yksi pieni kirjain',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'yksi iso kirjain ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'yksi numero',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'yksi erikoismerkki (!@#$%^&*).',
                valid: false
            }],
        UPDATE_PASSWORD_BUTTON: "Päivitä salasana",
        SUCCESS_HEADER_2: 'SALASANASI PÄIVITYS ONNISTUI ',
        LOGIN_REDIRECT: 'Napsauta tätä',
        LOGIN_REDIRECT_2: 'kirjautumista varten',
        SUCCESS_IMPORTANT_MESSAGE: 'Tärkeää: sähköpostiosoitteesi on nyt käyttäjätunnuksesi.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'ES': {
        ERR_GENERIC: "Se ha producido un error gen\u00e9rico.",
        SIGN_IN_TEXT: 'INICIAR SESIÓN',
        SIGN_IN_FAILED: 'Error al iniciar sesión',
        USERNAME: 'Nombre de usuario',
        PASSWORD: 'Contraseña',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VERIFIQUE SU CUENTA ',
        EMAIL_VERIFICATION_MESSAGE: 'Estamos actualizando nuestro proceso de inicio de sesión para proporcionarle una experiencia de autenticación única y más segura en las diversas aplicaciones de Parker. Después de esta actualización, tendrá que utilizar la dirección de correo electrónico que se proporciona abajo para iniciar la sesión.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Su dirección de correo electrónico',
        MODAL_EMAIL_MESSAGE: 'Ha introducido su correo electrónico como #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Confirme que esta dirección de correo electrónico es correcta y que comprende que su dirección de correo electrónico será su ID de usuario',
        EMAIL_CONFIRM_KEY: 'Confirmar',
        EMAIL_CANCEL_KEY: 'Cancelar',
        SUCCESS_HEADER_1: 'GRACIAS POR ACTUALIZAR SU CORREO ELECTRÓNICO',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Hemos enviado un correo electrónico de confirmación a . Haga clic en el enlace del mensaje para confirmar su dirección de correo electrónico.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Hemos enviado un correo electrónico de confirmación a #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Haga clic en el enlace del mensaje para',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirmar su dirección de correo electrónico.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Asegúrese de comprobar su carpeta de mensajes no deseados  ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Asegúrese de comprobar su carpeta de mensajes no deseados o de correo basura. El remitente es parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "ACTUALICE SU CONTRASEÑA",
        UPDATE_PASSWORD_BODY: "Gracias por verificar su correo electrónico. Es necesario que actualice su contraseña para cumplir con nuestras nuevas políticas de seguridad.",
        NEW_PASSWORD: "Nueva contraseña",
        RE_ENTER_PASSWORD: "Reintroducir contraseña ",
        PASSWORD_RULES_HEADER: "La contraseña debería incluir al menos",
        UPDATE_PASSWORD_BUTTON: "Actualizar contraseña",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 caracteres de longitud',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Una letra en minúsculas',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Una letra en mayúsculas',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un número',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Un carácter especial (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'LA CONTRASEÑA SE HA ACTUALIZADO CORRECTAMENTE ',
        LOGIN_REDIRECT: 'Haga clic aquí ',
        LOGIN_REDIRECT_2: 'para iniciar sesión ',
        SUCCESS_IMPORTANT_MESSAGE: 'Importante: ahora, su dirección de correo electrónico será su ID de usuario.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'DE': {
        ERR_GENERIC: "Ein generischer Fehler ist aufgetreten.",
        SIGN_IN_TEXT: 'ANMELDEN',
        SIGN_IN_FAILED: 'Anmeldung fehlgeschlagen!',
        USERNAME: 'Benutzername',
        PASSWORD: 'Passwort',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'BITTE ÜBERPRÜFEN SIE IHR KONTO',
        EMAIL_VERIFICATION_MESSAGE: 'Wir aktualisieren unseren Anmeldungsprozess, um Ihnen ein sichereres Single Sign On-Verfahren über mehrere Parker Anwendungen hinweg zu bieten. Nach dieser Aktualisierung werden Sie die folgende E-Mail-Adresse verwenden, um sich anzumelden.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Ihre E-Mail-Adresse',
        MODAL_EMAIL_MESSAGE: 'Sie haben folgende E-Mail-Adresse eingegeben: #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Bitte bestätigen Sie, dass diese E-Mail-Adresse korrekt ist und Sie verstanden haben, dass Ihre E-Mail-Adresse Ihre Benutzer-ID sein wird.',
        EMAIL_CONFIRM_KEY: 'Bestätigen',
        EMAIL_CANCEL_KEY: 'Abbrechen',
        SUCCESS_HEADER_1: 'VIELEN DANK FÜR DIE AKTUALISIERUNG IHRER E-MAIL-ADRESSE',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Wir haben eine Bestätigungs-E-Mail an folgende Adresse gesendet: Bitte klicken Sie auf den Link in der Nachricht, um Ihre E-Mail-Adresse zu bestätigen.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Wir haben eine Bestätigungs-E-Mail an folgende Adresse  #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Bitte klicken Sie auf den Link in der Nachricht, um',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirm your email address.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Make sure to check your junk or spam folder. The sender is ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Sehen Sie bitte in Ihrem Junkmail- oder Spam-Ordner nach. Der Absender ist „parkeraccount@logintest.parker.com“.',
        UPDATE_PASSWORD_HEADING: "BITTE AKTUALISIEREN SIE IHR PASSWORT",
        UPDATE_PASSWORD_BODY: "Vielen Dank, dass Sie Ihre E-Mail-Adresse überprüft haben. Gemäß unseren neuen Sicherheitsrichtlinien müssen Sie Ihr Passwort ändern.",
        NEW_PASSWORD: "Neues Passwort",
        RE_ENTER_PASSWORD: "Passwort erneut eingeben",
        PASSWORD_RULES_HEADER: "Das Passwort muss folgende Anforderungen erfüllen:",
        UPDATE_PASSWORD_BUTTON: "Passwort aktualisieren",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Länge von 12 Zeichen ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Mindestens ein Kleinbuchstabe',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Mindestens ein Großbuchstabe ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Mindestens eine Zahl ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Mindestens ein Sonderzeichen (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'IHR PASSWORT WURDE ERFOLGREICH AKTUALISIERT',
        LOGIN_REDIRECT: 'Klicken Sie hier, ',
        LOGIN_REDIRECT_2: 'um sich anzumelden ',
        SUCCESS_IMPORTANT_MESSAGE: 'Wichtig: Ihre E-Mail-Adresse ist jetzt Ihre Benutzer-ID.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'DA': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'LOG PÅ',
        SIGN_IN_FAILED: 'Logon mislykkedes!',
        USERNAME: 'Brugernavn',
        PASSWORD: 'Adgangskode',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'BEKRÆFT DIN KONTO ',
        EMAIL_VERIFICATION_MESSAGE: 'Vi opdaterer vores loginproces for at give dig en mere sikker enkeltlogonoplevelse på tværs af flere Parker-områder. Efter denne opdatering skal du bruge den e-mailadresse, der er angivet nedenfor, til at logge på.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Din e-mailadresse',
        MODAL_EMAIL_MESSAGE: 'Du har indtastet din e-mail som #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Bekræft, at denne e-mailadresse er korrekt, og at du forstår, at din e-mailadresse er dit bruger-id',
        EMAIL_CONFIRM_KEY: 'Bekræft',
        EMAIL_CANCEL_KEY: 'Annuller',
        SUCCESS_HEADER_1: 'TAK, FORDI DU HAR OPDATERET DIN E-MAIL',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har sendt en bekræftelses-e-mail til . Klik på linket i meddelelsen for at bekræfte din e-mailadresse.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Vi har sendt en bekræftelses-e-mail til #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Klik på linket i meddelelsen for at',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'confirm your email address.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Make sure to check your junk or spam folder. The sender is ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Sørg for at kigge i din junk- eller spammappe. Afsenderen er parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "OPDATER DIN ADGANGSKODE",
        UPDATE_PASSWORD_BODY: "Tak, fordi du har bekræftet din e-mail. Du skal opdatere din adgangskode for at overholde vores nye sikkerhedspolitikker.",
        NEW_PASSWORD: "Ny adgangskode",
        RE_ENTER_PASSWORD: "Indtast adgangskoden igen",
        PASSWORD_RULES_HEADER: "Adgangskode skal mindst indeholde",
        UPDATE_PASSWORD_BUTTON: "Opdater adgangskode",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 tegn',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Et lille bogstav',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Ét stort bogstav ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Ét tal ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Et specialtegn (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'DIN ADGANGSKODE ER BLEVET OPDATERET ',
        LOGIN_REDIRECT: 'Klik her',
        LOGIN_REDIRECT_2: 'for at logge ind',
        SUCCESS_IMPORTANT_MESSAGE: 'Vigtigt: Din e-mailadresse er nu dit bruger-id.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'CS': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'PŘIHLÁSIT SE',
        SIGN_IN_FAILED: 'Přihlášení se nezdařilo!',
        USERNAME: 'Uživatelské jméno',
        PASSWORD: 'Heslo',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'OVĚŘTE SVŮJ ÚČET',
        EMAIL_VERIFICATION_MESSAGE: 'We are updating our sign in process to provide you with a more secure, single sign on experience across multiple Parker applications. After this update, you will use the email address provided below to sign in. ',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Your Email Address',
        MODAL_EMAIL_MESSAGE: 'You entered your email as #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Please confirm this email address is correct and you understand your email address will be your User ID',
        EMAIL_CONFIRM_KEY: 'Confirm',
        EMAIL_CANCEL_KEY: 'Cancel',
        SUCCESS_HEADER_1: 'THANK YOU FOR UPDATING YOUR EMAIL',
        // SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'We have sent a confirmation email to . Please click the link in the message to confirm your email address.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Odeslali jsme potvrzovací e-mail na adresu #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Kliknutím na odkaz ve zprávě',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'potvrďte e-mailovou adresu.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Zkontrolujte i složku nevyžádané pošty. Odesilatel je ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        // SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Make sure to check your junk or spam folder. The sender is parkeraccount@logintest.parker.com',
        UPDATE_PASSWORD_HEADING: "AKTUALIZUJTE SVÉ HESLO.",
        UPDATE_PASSWORD_BODY: "Děkujeme Vám za ověření e-mailu. Abyste splnili naše nové zásady zabezpečení, musíte si aktualizovat své heslo.",
        NEW_PASSWORD: "Nové heslo ",
        RE_ENTER_PASSWORD: "Znovu zadejte heslo ",
        PASSWORD_RULES_HEADER: "Heslo by mělo obsahovat minimálně",
        UPDATE_PASSWORD_BUTTON: "Aktualizovat heslo",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 znaků ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Jedno malé písmeno',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Jedno velké písmeno ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Jedno číslo ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Jeden speciální znak (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'VAŠE HESLO BYLO ÚSPĚŠNĚ AKTUALIZOVÁNO ',
        LOGIN_REDIRECT: 'Kliknutím sem',
        LOGIN_REDIRECT_2: 'se přihlaste',
        SUCCESS_IMPORTANT_MESSAGE: 'Důležité: Vaše e-mailová adresa bude nyní Vaším ID uživatele.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    },
    'VI': {
        ERR_GENERIC: "A generic error has occurred.",
        SIGN_IN_TEXT: 'ĐĂNG NHẬP',
        SIGN_IN_FAILED: 'Đăng nhập thất bại!',
        USERNAME: 'Tên người dùng',
        PASSWORD: 'Mật khẩu',
        Sign_In_BUTTON: 'Sign In',
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'VUI LÒNG XÁC MINH TÀI KHOẢN CỦA BẠN',
        EMAIL_VERIFICATION_MESSAGE: 'Chúng tôi đang cập nhật quy trình đăng nhập của mình nhằm mang đến cho bạn trải nghiệm đăng nhập một lần an toàn hơn trên nhiều ứng dụng Parker. Sau lần cập nhật này, bạn sẽ sử dụng địa chỉ email được cung cấp dưới đây để đăng nhập.',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Địa chỉ email của bạn',
        MODAL_EMAIL_MESSAGE: 'Bạn đã nhập địa chỉ email của mình là #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Vui lòng xác nhận địa chỉ email này là chính xác và bạn hiểu rõ địa chỉ email này sẽ là ID người dùng của bạn',
        EMAIL_CONFIRM_KEY: 'Xác nhận',
        EMAIL_CANCEL_KEY: 'Hủy',
        SUCCESS_HEADER_1: 'CẢM ƠN BẠN ĐÃ CẬP NHẬT EMAIL',
        //SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Chúng tôi đã gửi email xác nhận đến . Vui lòng nhấp vào liên kết trong thư để xác minh địa chỉ email của bạn.',
        //SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Đảm bảo đã kiểm tra thư mục rác hoặc spam của bạn. Người gửi sẽ là parkeraccount@logintest.parker.com',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'Chúng tôi đã gửi email xác nhận đến #mailid.',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2: 'Vui lòng nhấp vào liên kết trong thư để',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3: 'xác nhận địa chỉ email của bạn.',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Đảm bảo đã kiểm tra thư mục rác hoặc spam của bạn. Người gửi là ',
        SUCCESS_HEADER_1_EMAIL_DEFAULT_2: process.env.REACT_APP_EMAIL,
        UPDATE_PASSWORD_HEADING: "VUI LÒNG CẬP NHẬT MẬT KHẨU CỦA BẠN",
        UPDATE_PASSWORD_BODY: "Cảm ơn bạn đã xác minh email. Bạn cần cập nhật mật khẩu của mình để đáp ứng chính sách bảo mật mới của chúng tôi.",
        NEW_PASSWORD: "Mật khẩu mới",
        RE_ENTER_PASSWORD: "Nhập lại mật khẩu",
        PASSWORD_RULES_HEADER: "Mật khẩu cần có độ dài ít nhất",
        UPDATE_PASSWORD_BUTTON: "Cập nhật mật khẩu",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 ký tự',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Một chữ thường',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Một chữ hoa',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Một chữ số ',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'Một ký tự đặc biệt (!@#$%^&*)',
                valid: false
            }],
        SUCCESS_HEADER_2: 'MẬT KHẨU CỦA BẠN ĐÃ ĐƯỢC CẬP NHẬT THÀNH CÔNG ',
        LOGIN_REDIRECT: 'Nhấp vào đây',
        LOGIN_REDIRECT_2: 'để đăng nhập',
        SUCCESS_IMPORTANT_MESSAGE: 'Quan trọng: Địa chỉ email này giờ đây sẽ là ID người dùng của bạn.',
        MY_PARKER: {
            subHead: 'Subhead',
            heading: 'Main Heading Goes Right Here',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
    }
}
