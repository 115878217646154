import React, { Fragment } from "react";
import { getQueryString } from "../util/widgetUtil";
import { activateUser } from "../services/util";
import { DOMAIN_PARKER, DOMAIN_TFS, Context } from "../Constant";
import { Row, Col } from "react-bootstrap";
import { getAppInfo} from '../services/util';

class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: false,
      formErrorMessage: "",
    };
  }
  componentDidMount() {
    const userGuid = getQueryString("activateToken");
    const appId = getQueryString("appId");

    if(appId)
    {
      getAppInfo(appId, (resp) => {
        if (resp.hasOwnProperty("errorCode")) {
          this.setState({
            formErrors: true,
            formErrorMessage:
              "An error occured.",
          });
        } else {
            window.location.href = resp._links.appLinks[0].href;
        }
    });            
    }

    activateUser(userGuid, (respData) => {
      if (respData.hasOwnProperty("errorCode")) {
        this.setState({
          formErrors: true,
          formErrorMessage:
            "You have accessed an account recovery link that has expired or been previously used.",
        });
      } else {
        this.setState({
          formErrors: false,
          formErrorMessage: "",
        });
        let formedUrl = window.location.origin;

        
        const stateToken = getQueryString("stateToken");
        const repost = getQueryString("repost");
        const haveQueryParams = window.location.search !== "" ? true : false;
        if(haveQueryParams) {
          formedUrl += window.location.search;
        }

        if (stateToken && stateToken !== "") {
          if(haveQueryParams) {
            formedUrl += "&stateToken=" + stateToken;
          } else {
            formedUrl += "?stateToken=" + stateToken;
          }
         
        }
        if (repost && repost !== "") {
          if((stateToken && stateToken !== "") || haveQueryParams) {
            formedUrl += "&repost=" + repost;
          } else {
            formedUrl += "?repost=" + repost;
          }
         
        }
        if(!appId)
        {
          window.location.href = formedUrl;
        }
        
      }
    });
  }
  render() {
    const { domainName } = this.props.context;
    return (
      <Fragment>
        {this.state.formErrors ? (
          <Fragment>
            <Row>
              <Col sm={12} className="logoLeft">
                <a href="/">
                  {domainName === DOMAIN_PARKER ? (
                    <img src="../assets/img/logo-black-hex.jpg" alt="Logo" />
                  ) : domainName === DOMAIN_TFS ? (
                    <img src="../assets/img/tfs-logo.png" alt="Logo" />
                  ) : null}
                </a>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <hr
                  className={
                    domainName === DOMAIN_PARKER
                      ? "yellow"
                      : domainName === DOMAIN_TFS
                      ? "blue"
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="">
                <span className="widget-body-text-email">
                  {this.state.formErrorMessage}
                </span>
              </Col>
            </Row>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {(state) => <ActivateAccount context={state} contextProps={props} />}
  </Context.Consumer>
);
