import React from 'react';

import {
    //WIDGET_TNC,
    //WIDGET_PP,
    //FOOTER_API
} from '../config/properties';
import {
    Context, DOMAIN_BALDWIN, DOMAIN_TFS, DOMAIN_PARKER
} from "../Constant";

import { FetchFooterDetails } from '../services/util';

import { getLangMapForPPAndTandC, privacyPolicyLabel, termsNConditionLabel } from '../util/widgetUtil';
import { getPath } from '../apiconfig';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            termsNConditionURL:[],
            privacyPolicyURL:[]

        }
    }

    componentDidMount() {
        const langId = (this.props.contextProps.langId).toString();
        const langCode = getLangMapForPPAndTandC().get(langId).toString();     
        FetchFooterDetails(langCode, (staticFooter) => {
            const termsNConditionURL = staticFooter.filter(txt => {
                return (String(txt.text).toUpperCase() === 'TERMS AND CONDITIONS');
            });
            const privacyPolicyURL = staticFooter.filter(txt => {
                return (String(txt.text).toUpperCase() === 'PRIVACY POLICIES');
            });
            this.setState({
                termsNConditionURL,
                privacyPolicyURL
            })
        })
    }

    render() {
        const { termsNConditionURL,privacyPolicyURL} = this.state;
        const langId = (this.props.contextProps.langId).toString();
        const linkURL = getLangMapForPPAndTandC().get(langId);

        const loc = window.location.href;
        const domainName = loc.indexOf(DOMAIN_TFS) > -1 ? DOMAIN_TFS : loc.indexOf(DOMAIN_BALDWIN) > -1 ? DOMAIN_BALDWIN : DOMAIN_PARKER;
        const _termsNConditionURL = domainName === DOMAIN_TFS ? getPath('getTFSTnC') : domainName === DOMAIN_BALDWIN ? getPath('getTnC') : getPath('getTnC');
        const _privacyPolicyURL = domainName === DOMAIN_TFS ? getPath('getTFSPP') : domainName === DOMAIN_BALDWIN ? getPath('getPP') : getPath('getPP');

		//const _termsNConditionURL = termsNConditionURL.length ? termsNConditionURL[0].url : WIDGET_TNC;
        //const _privacyPolicyURL = privacyPolicyURL.length ? privacyPolicyURL[0].url : WIDGET_PP;

        //const _termsNConditionURL = termsNConditionURL.length ? termsNConditionURL[0].url : getPath('getTFSTnC');
        //const _privacyPolicyURL = privacyPolicyURL.length ? privacyPolicyURL[0].url : getPath('getTFSPP');

        const _termsNConditionLabel = termsNConditionURL.length ? termsNConditionURL[0].text : termsNConditionLabel.get(langId);
        const _privacyPolicylabel = privacyPolicyURL.length ? privacyPolicyURL[0].text : privacyPolicyLabel.get(langId);

        return (
            <footer className="footer" role="contentinfo">
                <p>
                    <a href={_termsNConditionURL} >
                        {termsNConditionLabel.get(langId)}
                    </a>
                    {" | "}
                    <a href={_privacyPolicyURL} >
                        {privacyPolicyLabel.get(langId)}
                    </a>
                </p>
            </footer>
        )
    }
}


export default props => (
    <Context.Consumer>
        {state => <Footer context={state} contextProps={props} />}
    </Context.Consumer>
);