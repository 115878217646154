import React, { Component, Fragment } from 'react';
import { DOMAIN_BALDWIN, DOMAIN_TFS, Context } from "../Constant";
import { getQueryString } from "../util/widgetUtil";

import TFS_LOGO from '../component/tfs-logo.png';
import Header from './Header';

import {
    getAppInfo
  } from "../services/util";

import PARKER_LOGO from './logo-black-hex.jpg';


class RegistrationSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appLink:"",
            appId: getQueryString("appId")
        }
        this.gotoLoginPage = this.gotoLoginPage.bind(this);
    }


    componentDidMount() {
        if (this.state.appId != null)
        {
        try {
            getAppInfo(this.state.appId, (resp) => {
            if (resp.hasOwnProperty("errorCode")) {
                //no app found
            } else {
                this.setState({
                    formErrors: false,
                    formErrorMessage: "",
                    appLink: resp._links.appLinks[0].href
                });
            }
            }); 
        } catch (error) {
            //no app found 
        }
        }
    }

    gotoLoginPage() {
        const { langId, history } = this.props.contextProps;
        history.push({
            pathname: '/',
            search:  this.props.context.history.location.search,
            state: {
                langId: langId
            }
        });
    }

    render() {
        const { domainName } = this.props.context;
        const { appLink } = this.state;
        const logo =  domainName === DOMAIN_TFS ? TFS_LOGO : PARKER_LOGO;
        
        const registerButtonTfs = {
            background:'#007dc1',
            color:'#fff'
        }
        const registerButtonParker = {
            background:'#ffb91d',
            color:'#424242'
        }
        const registerButtonBaldwin = {
            background:'#424242',
            color:'#fff'
        }
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div id="okta-login-container" className="login-container">
                        <Header />
                        <div  id="okta-sign-in" className="auth-container main-container no-beacon">
                            <div className="okta-sign-in-header auth-header">
                                <img src={logo} className="auth-org-logo" alt="logo" />
                                <div data-type="beacon-container" className="beacon-container"></div>
                            </div>
                            <div className="auth-content">
                                <div className="auth-content-inner">
                                    <div className="registration-complete">
                                        <form
                                            method="POST"
                                            action="/"
                                            slot="content"
                                            id="form15"
                                            className="o-form o-form-edit-mode"
                                        >
                                            <div  className="o-form-content o-form-theme clearfix">
                                                <div className="o-form-error-container" ></div>
                                                <div className="o-form-fieldset-container" >
                                                    <div>
                                                        <span className="title-icon icon icon-16 confirm-16-green"></span>
                                                        <h2 style={{ textAlign: 'center', paddingTop: '15px', paddingBottom: '20px'  }}>Verification email sent</h2>
                                                        <span>Please check your email and verify your account to continue.</span>
                                                        <a className="button button-primary button-wide back-btn"  href={appLink}  onClick={this.gotoLoginPage} style = {domainName === DOMAIN_TFS ? registerButtonTfs: domainName === DOMAIN_BALDWIN ? registerButtonBaldwin: registerButtonParker}> Back to Sign In</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

export default props => (
    <Context.Consumer>
        {state => <RegistrationSuccess context={state} contextProps={props} />}
    </Context.Consumer>
);