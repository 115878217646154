import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

import {
    updateEmailAddress
} from "../actions/main-action";

import {
    Localization
} from "../util/localization";

import {
    LANG_IDS
} from "../util/Translation";

import {
    migrationUpdate
} from "../services/util";

// import { Context } from "../App";

import {
    DOMAIN_PARKER,
    DOMAIN_TFS,
    Context
} from "../Constant";

class EmailVerification extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            userConfirmation: false,
            validEmailAddress: true,
            show: false,
            emailFieldValue: "",
            disableConfirmButton: true,
            username: "",
            errMessage: "",
            showError: false,
            WCToken: "",
            WCTrustedToken: "",
            showLoader: false,
            langId: "-1"
        }
        this.onVerifyUsername = this.onVerifyUsername.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onModalConfirm = this.onModalConfirm.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.displayEmailAddress = this.displayEmailAddress.bind(this);
    }

    componentDidMount() {
        try {
            const { history } = this.props.contextProps;
            const stateVal = history && history.location && history.location.state ? history.location.state : null;
            // const emailFieldValue = stateVal ? stateVal.email : "";
            const username = stateVal ? stateVal.username : "";
            const WCToken = stateVal ? stateVal.WCToken : "";
            const WCTrustedToken = stateVal ? stateVal.WCTrustedToken : "";
            const langId = stateVal ? stateVal.langId : "-1";

            this.setState({
                // emailFieldValue,
                username,
                WCToken,
                WCTrustedToken,
                langId
            })
        } catch (err) {
            return err;
        }

    }

    /**
     * @func onVerifyUsername
     * @params none 
     * @desc function is responsible to show the confimation modal popup
     */
    onVerifyUsername() {
        this.setState({ show: true });
    }

    /**
     * @func onModalConfirm
     * @params none 
     * @desc function is responsible to call migration update API and based on the response route to success screen 
     */
    onModalConfirm() {
        const { emailFieldValue, username, WCToken, WCTrustedToken, langId } = this.state;
        this.setState({ showLoader: true, showError: false });
        migrationUpdate((resp) => {

            if (resp.hasOwnProperty("Success")) {
                this.setState({
                    errMessage: "",
                    showError: false,
                    showLoader: false
                });
                this.props.contextProps.history.push({
                    pathname: '/success',
                    state: {
                        langId: langId
                    }
                })
            } else {
                let errMessage = "";
                try {
                    const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
                    const msg = localeFileText.ERR_GENERIC;

                    if (resp.hasOwnProperty("errors")) {
                        errMessage = resp.errors[0].errorMessage;
                    } else {
                        errMessage = msg; // "Some technical issue occured, Please try again later";
                    }
                    this.setState({
                        errMessage,
                        showError: true,
                        showLoader: false
                    });
                } catch (err) {
                    return err;
                }

            }

        }, emailFieldValue, username, WCToken, WCTrustedToken, langId);
        this.handleClose();
    }

    /**
     * @func updateEmail
     * @params e 
     * @desc function is responsible to close the modal confirmation popup
     */
    updateEmail(e) {
        this.setState({
            emailFieldValue: e.target.value
        })
    }

    /**
     * @func validateEmail
     * @params evt 
     * @desc function is responsible to validate the email entered by user 
     */
    validateEmail(e) {
        const emailField = e.target;
        let disableConfirmButton = true;
        let validEmailAddress = false;
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField.value) == false) {
            validEmailAddress = false;
            disableConfirmButton = true;
        } else {
            validEmailAddress = true;
            disableConfirmButton = false;
            this.props.contextProps.dispatch(updateEmailAddress(emailField.value));
        }

        this.setState({ validEmailAddress, emailFieldValue: emailField.value, disableConfirmButton });
    }


    /**
     * @func handleClose
     * @params none 
     * @desc function is responsible to close the modal confirmation popup
     */
    handleClose() {
        this.setState({ show: false });
    }

    /**
     * @func displayEmailAddress
     * @params none 
     * @desc function is responsible for display Email Address Markup
     */
    displayEmailAddress(langId) {
        // let msg = this.props.localeText.MODAL_EMAIL_MESSAGE;
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        let msg = localeFileText.MODAL_EMAIL_MESSAGE;
        msg = msg.replace("#mailid", " <span style=font-weight:bold>" + this.state.emailFieldValue + "<\/span>")
        const marketingText = msg;
        return { __html: marketingText };
    }

    render() {
        const { domainName } = this.props.context;

        const { langId } = this.state;
        const { localeText } = this.props.contextProps;

        let localeFileText = Localization[LANG_IDS[langId]];
        if (localeFileText === undefined) {
            localeFileText = Localization[LANG_IDS["-1"]];
        }
        return (
            <Container>
                <React.Fragment>
                    {
                        this.state.showLoader
                            ?
                            <div className="loader"></div>
                            :
                            null
                    }
                    <Row>
                        <Col sm={12} className="logoLeft">
                            <a href="/">

                                {
                                    domainName === DOMAIN_PARKER
                                        ?
                                        <img src="assets/img/logo-black-hex.jpg" alt="Logo" />
                                        :
                                        domainName === DOMAIN_TFS
                                            ?
                                            <img src="assets/img/tfs-logo.png" alt="Logo" />
                                            : null
                                }
                            </a>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} className="email_verification-wrap">
                            <h2 className="okta-form-title o-form-head-email">
                                {localeFileText.EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr className={domainName === DOMAIN_PARKER ? "yellow" : domainName === DOMAIN_TFS ? "blue" : ""} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="">
                            <span className="widget-body-text-email">
                                {localeFileText.EMAIL_VERIFICATION_MESSAGE}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="paddingTop30px">
                            <span className="widget-body-text-email">
                                {localeFileText.EMAIL_VERIFICATION_EMAIL_ADDRESS}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="paddingTop10px o-form-input">
                            <input
                                className="inputEmailBox"
                                type="text"
                                placeholder={String(localeFileText.EMAIL_VERIFICATION_EMAIL_ADDRESS).toLowerCase()}
                                name="username"
                                value={this.state.emailFieldValue}
                                onKeyUp={(e) => { this.validateEmail(e) }}
                                onChange={(e) => { this.updateEmail(e) }}
                                aria-label="Username"
                                autoComplete="off" />
                        </Col>
                    </Row>
                    {
                        this.state.showError
                            ?
                            <Row>
                                <Col sm={12} className="">
                                    <br />
                                    <span className="widget-body-text-email colorRed">
                                        {this.state.errMessage}
                                    </span>
                                </Col>
                            </Row>
                            :
                            null
                    }
                    <Row>
                        <Col xm={12} className="paddingTop10px">
                            <Button
                                type="button"
                                style={{ color: domainName === DOMAIN_PARKER ? "#000" : "#fff" }}
                                className={domainName === DOMAIN_PARKER ? "confirmBtn" : domainName === DOMAIN_TFS ? "confirmBtn_tfs" : ""}
                                disabled={this.state.disableConfirmButton}
                                onClick={this.onVerifyUsername}>
                                {localeFileText.EMAIL_CONFIRM_KEY}
                            </Button>

                        </Col>
                    </Row>
                    <Modal show={this.state.show} onHide={this.handleClose} className={domainName === DOMAIN_TFS ? "modalLineColor_tfs" : ""}>
                        <Modal.Header className="modal-header" closeButton>

                        </Modal.Header>
                        <Row>
                            <Col sm={12} className="padding50px">
                                <span
                                    className="widget-body-text-email"
                                    dangerouslySetInnerHTML={this.displayEmailAddress(langId)}>
                                </span>
                            </Col>
                        </Row>
                        <Row className="paddingBottom20px">
                            <Col sm={11} xs={11} className="padding50px paddingRight10px">
                                <span className="widget-body-text-email" style={{ padding: "2px" }}>
                                    {localeFileText.MODAL_EMAIL_CONFIRMATION_MESSAGE}
                                </span>
                            </Col>
                        </Row>
                        <Row className="paddingBottom20px">
                            <Col md={{ span: 1, offset: 1 }}></Col>
                            <Col sm={3} className="padding50px">
                                <Button
                                    type="button"
                                    className={domainName === DOMAIN_PARKER ? "confirmBtnModal" : domainName === DOMAIN_TFS ? "confirmBtnModal_tfs" : ""}
                                    style={{ color: "#000" }}
                                    onClick={this.onModalConfirm}
                                >
                                    {localeFileText.EMAIL_CONFIRM_KEY}
                                </Button>
                            </Col>
                            <Col sm={3} className="modalCancelBtn">
                                <Button
                                    type="button"
                                    className={domainName === DOMAIN_PARKER ? "confirmBtnModal cancelBtn" : domainName === DOMAIN_TFS ? "confirmBtnModal_tfs cancelBtn_tfs" : ""}
                                    style={{ color: "#000" }}
                                    onClick={this.handleClose}
                                >
                                    {localeFileText.EMAIL_CANCEL_KEY}
                                </Button>
                            </Col>
                        </Row>
                    </Modal>
                </React.Fragment>
            </Container>
        )
    }

}

export default props => (
    <Context.Consumer>
        {state => <EmailVerification context={state} contextProps={props} />}
    </Context.Consumer>
);

// export default EmailVerification;