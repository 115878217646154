
export const ENV = process.env.REACT_APP_ENV;
// export const MIGRATION_AUTHENTICATE_URL = process.env.REACT_APP_AUTHENTICATE_URL;
// export const MIGRATION_UPDATE_URL = process.env.REACT_APP_UPDATE_URL;
// export const MIGRATION_FINISH_URL = process.env.REACT_APP_FINISH_URL;
// export const CHANGEPASSWORD_URL = process.env.REACT_APP_CHANGEPASSWORD_URL;
// export const MIGRATION_TOKEN_URL = process.env.REACT_APP_TOKEN_URL;
// export const MIGRATION_LOCALE_URL = process.env.REACT_APP_LOCALE_URL;
// export const REACT_APP_AUTHORIZATION_KEY = process.env.REACT_APP_AUTHORIZATION_KEY;
// export const REACT_APP_PHCONNECT_URL = process.env.REACT_APP_PHCONNECT_URL;
// export const REACT_APP_PCM_AUTHORIZATION = process.env.REACT_APP_PCM_AUTHORIZATION;
// export const REACT_APP_PCMURL = process.env.REACT_APP_PCMURL;
// export const REACT_APP_PCM_LINKURL = process.env.REACT_APP_PCM_LINKURL;
//export const FOOTER_API = process.env.REACT_APP_TFS_FOOTER_API;
//export const WIDGET_TNC = process.env.REACT_APP_TnC;
//export const WIDGET_PP = process.env.REACT_APP_PPURL;
// export const PARKER_APP_URL = process.env.REACT_APP_PARKER_APP_URL;
// export const TFS_APP_URL = process.env.REACT_APP_TFS_APP_URL;
//export const PARKER_HOME_PAGE = process.env.REACT_APP_PARKER_HOME_PAGE;
//export const TFS_HOME_PAGE = process.env.REACT_APP_TFS_HOME_PAGE;
// export const TFSREDIRECT_URL = process.env.REACT_APP_TFSREDIRECT_URL;


// export const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
// export const REACT_APP_TFS_REQCONTEXT = process.env.REACT_APP_TFS_REQCONTEXT;
