import React, { Component } from 'react';
import { DOMAIN_BALDWIN, DOMAIN_TFS, Context } from "../Constant";

import TFS_LOGO from '../component/tfs-logo.png';
// import PARKER_LOGO from './logo-black-hex.jpg';

import Header from './Header';
import Footer from './Footer';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.gotoLoginPage = this.gotoLoginPage.bind(this);
    }
    gotoLoginPage() {
        console.log(this.props);
        const { langId, history } = this.props.contextProps;
        history.push({
            pathname: '/',
            search:  this.props.context.history.location.search,
            state: {
                langId: langId
            }
        });
    }
    render() {
        const { langId, domainName } = this.props.context;
        /*const loginbgCls = DOMAIN_TFS ? "loginBackground_TFS" : DOMAIN_BALDWIN ? "loginBackground_baldwin" : "loginBackground";*/

        const username = (undefined !== this.props.context.history.location.state) ? this.props.context.history.location.state.username : '';

        const BackButtonTfs = {
            background: "#13628D",
            color: "#fff",
            cursor: "pointer",
          };
        const BackButtonParker = {
            background: "#ffb91d",
            color: "#424242",
            cursor: "pointer",
        };
        const BackButtonBaldwin = {
            background: "#424242",
            color: "#fff",
            cursor: "pointer",
        };

        const buttonStyle =
            domainName === DOMAIN_TFS
            ? BackButtonTfs
            : domainName === DOMAIN_BALDWIN
            ? BackButtonBaldwin
            : BackButtonParker;
        return (
            <div className="content-wrapper">
                <div id="okta-login-container" className="login-container">
                    <Header />
                    <div id="okta-sign-in" className="auth-container main-container no-beacon">
                        <div className="okta-sign-in-header auth-header">
                            <img src={TFS_LOGO} className="auth-org-logo" alt="Parker Hannifin - DEV logo" />
                            <div className="beacon-container"></div>
                        </div>
                        <div className="auth-content">
                            <div className="auth-content-inner">
                                <div className="password-reset-email-sent">
                                    <form
                                        method="POST"
                                        id="form22"
                                        className="o-form o-form-edit-mode"
                                    >
                                        <div className="o-form-content o-form-theme clearfix">
                                            <h2 className="okta-form-title o-form-head">Email sent!</h2>
                                            <p className="okta-form-subtitle o-form-explain" >Email has been sent to {" " + username + " "} with instructions on resetting your password.</p>
                                            <div className="o-form-error-container" ></div>
                                            <div className="o-form-fieldset-container" >
                                                <div>
                                                    <span className="accessibility-text" role="status">Email sent!</span>
                                                </div>
                                                <a onClick={this.gotoLoginPage} href="" style={buttonStyle} className="button button-primary button-wide link-button" >Back to Sign In</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer langId={langId} />
                </div>
            </div>
        )
    }
}

export default props => (
    <Context.Consumer>
        {state => <PasswordReset context={state} contextProps={props} />}
    </Context.Consumer>
);