import React, { Component } from "react";

import axios from "axios";
import oauthSignature from "oauth-signature";
import {
  createOktaEmailObject,
  getUserDetails,
  forgotPassword,
} from "../services/util";
import { DOMAIN_BALDWIN, DOMAIN_PARKER, DOMAIN_TFS, Context } from "../Constant";

import TFS_LOGO from "../component/tfs-logo.png";
// import PARKER_LOGO from './logo-black-hex.jpg';

import Header from "./Header";
import Footer from "./Footer";
import { getAPIUrl, getPath } from "../apiconfig";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isButtonDisabled: false
    };
    this.setUsernameHandler = this.setUsernameHandler.bind(this);
    this.gotoLoginPage = this.gotoLoginPage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  setUsernameHandler(e) {
    this.setState({
      username: e.target.value,
    });
  }
  gotoLoginPage() {
    const { langId, history } = this.props.contextProps;
    history.push({
      pathname: "/",
      search: this.props.context.history.location.search,
      state: {
        langId: langId,
      },
    });
  }

  handleSubmit = () => {
    const { langId, history } = this.props.contextProps;
    const { username } = this.state;
    const obj = {
      emailFieldValue: username,
    };
    this.setState({ isButtonDisabled: true }); //disable button to prevent multiple submits
    if (username === "") {
      this.setState({
        formErrors: true,
        formErrorMessage: "This field cannot be left blank",
        isButtonDisabled: false
      });
      return;
    }
    try {
      // const self = this;
      getUserDetails(username, (resp) => {
        if (resp && resp.hasOwnProperty("errorCode")) {
          this.setState({
            formErrors: true,
            formErrorMessage: "User does not exist",
            isButtonDisabled: false
          });
        } else if (
          resp.hasOwnProperty("statusCode") &&
          resp.statusCode !== 200
        ) {
          this.setState({
            formErrors: true,
            // formErrorMessage: 'User does not exist'
            formErrorMessage: resp.message,
            isButtonDisabled: false
          });
        } else {
          if (resp.length === 0) {
            this.setState({
              formErrors: true,
              formErrorMessage: "User does not exist",
              isButtonDisabled: false
            });
            return;
          }
          this.setState({
            formErrors: false,
            formErrorMessage: "",
          });
          const { domainName } = this.props.context;
          forgotPassword(username, domainName, (respData) => {
            if (respData.hasOwnProperty("errorCode")) {
              this.setState({
                formErrors: true,
                formErrorMessage: "Some technical error occured",
                isButtonDisabled: false
              });
            } else {
              this.setState({
                formErrors: false,
                formErrorMessage: "",
              });
              history.push({
                pathname: "passwordreset",
                state: {
                  username: this.state.username,
                },
              });
            }
          });
        }
      });
    } catch (err) {
      this.setState({
        formErrors: true,
        formErrorMessage: "Some technical error occured",
        isButtonDisabled: false
      });
    }
  };


  render() {
    const { langId, domainName } = this.props.context;
    /*const loginbgCls =
      domainName === DOMAIN_TFS ? "loginBackground_TFS" : DOMAIN_BALDWIN ? "loginBackground_baldwin" : "loginBackground";*/

    const resetButtonTfs = {
      background: "#13628D",
      color: "#fff",
      cursor: "pointer",
    };
    const resetButtonTfs_disabled = {
      background: "#4cb8f2",
      color: "#fff",
      cursor: "text",
    };
    const resetButtonParker = {
      background: "#ffb91d",
      color: "#424242",
      cursor: "pointer",
    };
    const resetButtonParker_disabled = {
      backgroundColor: "#ecc97d",
      color: "#424242",
      cursor: "text",
    };
    const resetButtonBaldwin = {
      background: "#424242",
      color: "#fff",
      cursor: "pointer",
    };
    const { username, formErrorMessage, formErrors } = this.state;
    const buttonStyle =
        domainName === DOMAIN_TFS
        ? resetButtonTfs
        : domainName === DOMAIN_BALDWIN
        ? resetButtonBaldwin
        : resetButtonParker;
    return (
      <div className="content-wrapper">
        <div id="okta-login-container" className="login-container">
          <Header />
          <div id="widget-container">
            <div
              id="okta-sign-in"
              className="auth-container main-container no-beacon"
            >
              <div className="okta-sign-in-header auth-header">
                <div className="beacon-container"></div>
              </div>
              <div className="auth-content">
                <div className="auth-content-inner">
                  <div className="forgot-password">
                    <div
                      id="form44"
                      className="o-form forgot-password-email-enabled o-form-edit-mode"
                    >
                      <div className="o-form-content o-form-theme clearfix">
                        <h2 className="okta-form-title o-form-head">
                          Reset Password
                        </h2>
                        {formErrors ? (
                          <div className="o-form-error-container o-form-has-errors">
                            <div>
                              <div
                                className="okta-form-infobox-error infobox infobox-error"
                                role="alert"
                              >
                                <span className="icon error-16"></span>
                                <p>{formErrorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="o-form-fieldset-container">
                          <div className="o-form-fieldset o-form-label-top">
                            <div className="okta-form-label o-form-label">
                              <label htmlFor="account-recovery-username">
                                Email or Username&nbsp;
                              </label>
                            </div>
                            <div className="o-form-input">
                              <span className="o-form-input-name-username o-form-control okta-form-input-field input-fix">
                                <input
                                  type="text"
                                  placeholder=""
                                  name="username"
                                  id="account-recovery-username"
                                  value={this.state.username}
                                  aria-label=""
                                  autoComplete="off"
                                  onChange={(e) => this.setUsernameHandler(e)}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="o-form-button-bar">
                            <button
                              style={buttonStyle}
                              className="button button-primary"
                              type="submit"
                              value="Reset via Email"
                              onClick={this.handleSubmit}
                              disabled={this.state.isButtonDisabled}
                              >
                              Reset via Email
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="auth-footer">
                      <a onClick={this.gotoLoginPage} href="" className="link help">
                        Back to Sign In
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer langId={langId} />
        </div>
      </div>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {(state) => <ForgotPassword context={state} contextProps={props} />}
  </Context.Consumer>
);
