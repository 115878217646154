import React from 'react';
import { getLogo } from "../util/domains";

import {
    Context
} from "../Constant";

const Header = props => {
    const { domainName } = props.context;
    return (
        <header className="header" role="banner">
            <div className="phlogo">
                <a href="/">
                    <img src={getLogo()} alt="Logo" />
                </a>
            </div>
        </header>
    )
}

export default props => (
    <Context.Consumer>
        {state => <Header context={state} contextProps={props} />}
    </Context.Consumer>
);