import React, { Component } from 'react';
import { getLogo } from "../util/domains";

import {
    migrationAuthenticate
} from "../services/util";

import {
    Localization
} from "../util/localization";

import {
    LANG_IDS
} from "../util/Translation";

// import { Context } from "../App";
import {
    DOMAIN_PARKER,
    DOMAIN_TFS,
    Context
} from "../Constant";

class SignInScreen extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            WCToken: "",
            WCTrustedToken: "",
            errorMessage: "",
            error: false,
            showLoader: false
        }
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.checkUserMigration = this.checkUserMigration.bind(this);
        this.hidePassword = this.hidePassword.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.getUrlParams = this.getUrlParams.bind(this);
    }

    componentDidMount() {
        try {
            const urlParams = this.getUrlParams();
            const loginHint = urlParams && urlParams.login_hint ? urlParams.login_hint : "";
            this.setState({ userName: loginHint });
        } catch (e) {
            return e;
        }
    }

    /**
     * @func getUrlParams
     * @params evt
     * @desc function is responsible for fetching the url params
     */
    getUrlParams() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    /**
     * @func updateUsername
     * @params evt 
     * @desc function is responsible for updating the username
     */
    updateUsername(evt) {
        try {
            this.setState({
                userName: evt.target.value
            });
        } catch (e) {
            return e;
        }
    }

    /**
     * @func updatePassword
     * @params evt 
     * @desc function is responsible for updating the password
     */
    updatePassword(evt) {
        try {
            this.setState({
                password: evt.target.value
            });
        } catch (e) {
            return e;
        }
    }

    /**
     * @func checkUserMigration
     * @params none 
     * @desc function is responsible to call authenticate API and based on the results route to email verification screen
     */
    checkUserMigration() {
        try {
            this.setState({ error: false, showLoader: true });
            const username = this.state.userName;
            const password = this.state.password;
            const langId = this.props.contextProps.langId
            if (username !== "" && password !== "") {
                migrationAuthenticate((resp) => {
                    if (resp && resp.hasOwnProperty("errors")) {
                        const errors = resp.errors;
                        const errMessage = errors[0].errorMessage;
                        this.setState({ errorMessage: errMessage, error: true, showLoader: false });
                    } else {
                        this.props.contextProps.history.push({
                            pathname: '/email-verification',
                            state: {
                                username,
                                email: resp.data.Email,
                                WCToken: resp.data.WCToken,
                                WCTrustedToken: resp.data.WCTrustedToken,
                                langId: langId
                            }
                        })
                        this.setState({ errorMessage: "", error: false, showLoader: false });
                    }
                }, username, password, langId);
            }
        } catch (e) {
            return e;
        }
    }

    /**
     * @func hidePassword
     * @params evt 
     * @desc function is resposible to hide the password once user mouse out the icon in password field.
     */
    hidePassword(evt) {
        this.refs.passwordField.type = "password";
    }

    /**
     * @func showPassword
     * @params evt 
     * @desc function is resposible to show the password entered by user once user clicks the icon in password field.
     */
    showPassword(evt) {
        this.refs.passwordField.type = "text";
    }

    render() {
        const { localeText, langId } = this.props.contextProps;
        const { domainName } = this.props.context;
        let localeFileText = Localization[LANG_IDS[langId]];
        if (localeFileText === undefined) {
            localeFileText = Localization[LANG_IDS["-1"]];
        }
        const logoParker = "assets/img/logo-black-hex.jpg";
        const logoTfs = "assets/img/tfs-logo.png";
        return (
            <div id="root">
                {
                    this.state.showLoader
                        ?
                        <div className="loader"></div>
                        :
                        null
                }
                <div>
                    {
                        domainName === DOMAIN_PARKER || domainName === DOMAIN_TFS
                            ?
                            <header className="header" role="banner">
                                <div className="phlogo">
                                    <a>
                                    <img src={getLogo()} alt="Logo" />
                                    </a>
                                </div>
                            </header>
                            :
                            null
                    }
                    <main className="main" role="main">

                        <div className="login-wrap">
                            <div>
                                <div id="invlogin-sign-in" className="auth-container main-container no-beacon">
                                    <div className="invlogin-sign-in-header auth-header">
                                        <div className="beacon-container"></div>
                                    </div>
                                    {
                                        domainName === DOMAIN_TFS && false
                                            ?
                                            <header className="header" role="banner">
                                                <div className="phlogo_tfs">
                                                    <a>
                                                        <img src={getLogo()} alt="Logo" />
                                                    </a>
                                                </div>
                                            </header>
                                            :
                                            null
                                    }


                                    <div className="auth-content">
                                        <div className="auth-content-inner">
                                            <div className="primary-auth">
                                                <form className="primary-auth-form o-form o-form-edit-mode">
                                                    <div className="o-form-content o-form-theme clearfix">
                                                        <h2 className="invlogin-form-title o-form-head">{localeFileText.SIGN_IN_TEXT}</h2>
                                                        {
                                                            this.state.error
                                                                ?
                                                                <div className="o-form-error-container o-form-has-errors" data-se="o-form-error-container">
                                                                    <div>
                                                                        <div className="invlogin-form-infobox-error infobox infobox-error" role="alert">
                                                                            <span className="icon error-16"></span>
                                                                            <p>{localeFileText.SIGN_IN_FAILED}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        <div className="o-form-error-container"></div>
                                                        <div className="o-form-fieldset-container">
                                                            <div className="o-form-fieldset o-form-label-top">
                                                                <div className="o-form-input">
                                                                    <span className="o-form-input-name-username o-form-control invlogin-form-input-field input-fix">
                                                                        <span className="input-tooltip icon form-help-16"></span>
                                                                        <span className="icon input-icon person-16-gray"></span>
                                                                        <input
                                                                            type="text"
                                                                            placeholder={localeFileText.USERNAME}
                                                                            name="username"
                                                                            id="invlogin-signin-username"
                                                                            aria-label="Username"
                                                                            autoComplete="off"
                                                                            value={this.state.userName}
                                                                            onChange={(e) => { this.updateUsername(e) }} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="o-form-fieldset o-form-label-top">
                                                                <div className="o-form-input">
                                                                    <span className="o-form-input-name-password o-form-control invlogin-form-input-field input-fix">
                                                                        <span
                                                                            className="input-tooltip icon form-help-16"
                                                                            onClick={this.showPassword}
                                                                            onMouseOut={this.hidePassword}
                                                                        />


                                                                        <span className="icon input-icon remote-lock-16"></span>
                                                                        <input
                                                                            ref="passwordField"
                                                                            type="password"
                                                                            placeholder={localeFileText.PASSWORD}
                                                                            name="password"
                                                                            id="invlogin-signin-password"
                                                                            aria-label="Password"
                                                                            autoComplete="off"
                                                                            onChange={(e) => { this.updatePassword(e) }} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top margin-btm-0">
                                                            <div data-se="o-form-input-container" className="o-form-input">
                                                                <span data-se="o-form-input-remember" className="o-form-input-name-remember">
                                                                    <div className="custom-checkbox"><input type="checkbox" name="remember" id="input39"/><label for="input39" data-se-for-name="remember" className="">Remember me</label></div>
                                                                </span>
                                                            </div> 
                                                        </div>*/}
                                                        </div>
                                                    </div>
                                                    <div className="o-form-button-bar">
                                                        <input className={domainName === DOMAIN_PARKER ? "button button-primary" : domainName === DOMAIN_TFS ? "button button-primary_tfs" : ""} type="button" value={localeFileText.Sign_In_BUTTON} onClick={this.checkUserMigration} />
                                                    </div>
                                                </form>
                                                {/*<div className="auth-footer">
                                                <a href="#" data-se="needhelp" aria-expanded="false" aria-controls="help-links-container" className="link help js-help">      Forgot your User ID or Password?      </a>      
                                                <ul className="help-links js-help-links" id="help-links-container" style={{'display': 'none'}}>
                                                    <li>        <a href="#" data-se="forgot-password" className="link js-forgot-password">        Forgot password?        </a>        </li>
                                                    <li>        <a href="https://dev-329637.invlogin.com/help/login" data-se="help-link" className="link js-help-link" target="_blank">        Help        </a>        </li>
                                                </ul>
                                            </div>
                                            <div className="registration-container">
                                                <div className="content-container">
                                                    <span className="registration-label">Don't have an account?</span>
                                                    <a title="Sign up" aria-label="Sign up" className="registration-link" href="#">Sign up</a>
                                                </div>
                                            </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }

}

export default props => (
    <Context.Consumer>
        {state => <SignInScreen context={state} contextProps={props} />}
    </Context.Consumer>
);