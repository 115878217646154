import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import RootComp from "./component/RootComp";
import EmailVerification from "./component/EmailVerification";
import SuccessMessage from "./component/SuccessMessage";
import MyParkerSlideshow from './component/MyParkerSlideshow';
import MyParkerContent from './component/MyParkerContent';

import Registration from "./widget/Registration";
import RegistrationSuccess from "./widget/RegistrationSuccess";
import ForgotPassword from "./widget/ForgotPassword";

import { DOMAIN_PARKER, DOMAIN_TFS, DOMAIN_BALDWIN, Context } from "./Constant";
import PasswordReset from "./widget/PasswordReset";
import ActivateAccount from "./widget/ActivateAccount";
import RequestAccess from "./widget/RequestAccess";
import RequestSuccess from "./widget/RequestSuccess";
import Approve from "./widget/Approve";
import Deny from "./widget/Deny";

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { getPath } from "./apiconfig";
import { getTheme } from "./util/domains";

class App extends Component {
  isMyParker = ( 'theme-myparker' === getTheme() ) ? true : false;
  parsedMyParker = false;

  constructor(props) {
    super(props);
    this.state = {
      domainName: "",
      langId: "-1"
    };
    this.getCookie = this.getCookie.bind(this);
  }

  useBodyClass(className) {
    let addBodyClass = className => document.body.classList.add(className),
    removeBodyClass = className => document.body.classList.remove(className);

      // Set up
      className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

      // Clean up
      return () => {
          className instanceof Array
              ? className.map(removeBodyClass)
              : removeBodyClass(className);
      };
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  componentDidMount() {
    let domainName = this.state.domainName;
    const loc = window.location.href;
    domainName = loc.indexOf(DOMAIN_TFS) > -1 ? DOMAIN_TFS : loc.indexOf(DOMAIN_BALDWIN) > -1 ? DOMAIN_BALDWIN : DOMAIN_PARKER;
    document.title = domainName === DOMAIN_TFS ? "TFS" : domainName === DOMAIN_BALDWIN ? "Baldwin Filters" : "Parker";
    const getCookieLangId = this.getCookie("langId");
    const langId = getCookieLangId !== "" ? getCookieLangId : this.state.langId;
    this.setState({ domainName, langId });
    this.useBodyClass(getTheme());
  }

  render() {
    const {
      history,
      dispatch,
      updatePassword,
      emailAddress,
      localeText
    } = this.props;

    const myParkerContent = (el) => (
      <>
        <div className="my-parker-wrapper">
          <div className="container-fluid">
              <div className="row align-items-center my-parker-container">
                  <div className="okta-login-wrapper">
                      {setComponent(el)}
                  </div>
                  <div className="welcome-greeting-wrapper">
                    <MyParkerContent localeText={localeText} />
                  </div>
              </div>
          </div>
        </div>
      </>
  );

  const setComponent = (el) => {
    switch( el ) {
      case 'email-verification' :
        return <EmailVerification history={history} dispatch={dispatch} localeText={localeText} />;
      case 'register' :
        return <Registration history={history} />
      case 'register-success' :
        return <SuccessMessage history={history} screen="1" emailAddress={emailAddress} localeText={localeText} />;
      case 'request' :
        return <RequestAccess history={history} />;
      case 'request-success' :
        return <RequestSuccess history={history} />;
      case 'password-success' :
        return <SuccessMessage history={history} screen="2" localeText={localeText} />;
      case 'activate' :
        return <ActivateAccount history={history} />;
      case 'verify' :
        return <RegistrationSuccess history={history} />;
      case 'forgotpassword' :
        return <ForgotPassword history={history} />;
      case 'password' :
        return <PasswordReset history={history} />;
      case 'root' :
      default:
        return <RootComp history={history} dispatch={dispatch} updatePassword={updatePassword} localeText={localeText} />;
    }
  }

  const getFormComponent = (el) => {
    if( this.isMyParker ) {
      return myParkerContent(el);
    } else {
      return setComponent(el);
    }
  };

    return (
      <Context.Provider value={{ domainName: this.state.domainName, langId: this.state.langId, dispatch: this.props.dispatch, history: this.props.history , isMyParker:this.isMyParker }} >
        <Context.Consumer>
          {() => (
            <>
              <Route exact path="/email-verification"  render={() => (
                 <>{getFormComponent('email-verification')}</>
              )} />

              <Route exact path="/success" render={() => ( 
                <>{getFormComponent('register-success')}</>
              )} />

              <Route exact path="/passwordSuccess" render={() => (
                <>{getFormComponent('password-success')}</>
              )} />

              <Route exact path="/request-success" render={() => (
                <>{getFormComponent('request-success')}</>
              )} />

              <Route exact path="/register" render={() => (
                <>{getFormComponent('register')}</>
              )} />

              <Route exact path="/request-access" render={() => (
                <>{getFormComponent('request')}</>
              )}/>

              <Route exact path="/activate-account" render={() => (
                <>{getFormComponent('activate')}</>
              )} />

              <Route exact path="/registration-verification" render={() => (
                <>{getFormComponent('verify')}</>
              )}/>

              <Route exact path="/forgotpassword" render={() => (
                 <>{getFormComponent('forgotpassword')}</>
              )}/>

              <Route exact path="/passwordreset" render={() => (
                <>{getFormComponent('password')}</>
              )}/>

              <Security issuer={getPath('redirectUrl')}
                  clientId={getPath('getApproverClientID')} 
                  redirectUri={window.location.origin + '/implicit/callback'}
                  scope="okta.groups.manage"
                  >
                <Route path="/implicit/callback" component={LoginCallback} />
                <SecureRoute path="/approve" component={Approve} />
                <SecureRoute path="/deny" component={Deny} />
              </Security>

              <Route exact path="/" render={() => (
                <>{getFormComponent('root')}</>
              )}/>
            </>
          )}
        </Context.Consumer>
      </Context.Provider>
    );
  }
}

const myRouter = withRouter(App);

const mapStateToProps = (state) => ({
  emailAddress: state.emailAddress,
  updatePassword: state.updatePassword,
  localeText: state.localeText
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(myRouter);
