import React, { Component, Fragment } from 'react';
import { DOMAIN_TFS, Context } from "../Constant";

import TFS_LOGO from '../component/tfs-logo.png';
import Header from './Header';
import Footer from './Footer';
import PARKER_LOGO from './logo-black-hex.jpg';

class RequestSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.gotoLoginPage = this.gotoLoginPage.bind(this);
    }


    componentDidMount() {
        console.log('Inside component did mount');
    }

    gotoLoginPage() {
        const { langId, history } = this.props.contextProps;
        history.push({
            pathname: '/',
            search:  this.props.context.history.location.search,
            state: {
                langId: langId
            }
        });
    }

    render() {
        const { langId, domainName } = this.props.context;
        const logo =  domainName === DOMAIN_TFS ? TFS_LOGO : PARKER_LOGO;
        
        return (
            <Fragment>
              <div className="content-wrapper">
                <div id="okta-login-container" className={"regcontainer"}>
                  <Header />
                  <div id="widget-container">
                        <div  id="okta-sign-in" className="auth-container main-container no-beacon">
                            <div className="okta-sign-in-header auth-header">
                                <img src={logo} className="auth-org-logo" alt="logo" />
                                <div data-type="beacon-container" className="beacon-container"></div>
                            </div>
                            <div className="auth-content">
                                <div className="auth-content-inner">
                                    <div className="registration-complete">
                                        <form
                                            method="POST"
                                            action="/"
                                            slot="content"
                                            id="form15"
                                            className="o-form o-form-edit-mode"
                                        >
                                            <div  className="o-form-content o-form-theme clearfix">
                                                <div className="o-form-error-container" ></div>
                                                <div className="o-form-fieldset-container" >
                                                    <div>
                                                        <span className="title-icon icon icon-16 confirm-16-green"></span>
                                                        <h2 style={{ textAlign: 'center', paddingTop: '15px', paddingBottom: '20px'  }}>Access request sent</h2>
                                                        <span>You will receive an email once your request has been reviewed.</span>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                  <Footer langId={langId}/>
                </div>
              </div>
            </Fragment>
          );
    }
}

export default props => (
    <Context.Consumer>
        {state => <RequestSuccess context={state} contextProps={props} />}
    </Context.Consumer>
);