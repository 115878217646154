export function updateEmailAddress(payload) {
  return {
    type: "UPDATE_EMAIL_ADDRESS",
    payload
  }
}

export function updatePassword(payload) {
  return {
    type: "UPDATE_PASSWORD",
    payload
  }
}

export function getLocaleText(payload) {
  return {
    type: "GET_LOCALE_TEXT",
    payload
  }
}


