import React, { Component } from 'react';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './okta.css';

import { ENV } from '../config/properties';

import {
    DOMAIN_PARKER,
    DOMAIN_TFS,
    DOMAIN_BALDWIN,
    Context
} from "../Constant";

import Header from '../widget/Header';
import Footer from '../widget/Footer';

import {
    getLangMap,
    getLocaleMap,
    getQueryString,
    getCookie,
    WidgetFeatures
} from '../util/widgetUtil';
import { getPath } from '../apiconfig';

class LoginScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            signInWidget: null,
            displayWidget: false
        }
        this.getParameterByName = this.getParameterByName.bind(this);
    }

    componentDidMount() {
        console.log('Inside mount!');
        const { localeText, langId } = this.props.contextProps;
        const { domainName,isMyParker } = this.props.context;
        const locale = getLocaleMap().get(langId.toString());
        var countryCode = getQueryString('countryCode');
        console.log('countryCode param ' + countryCode);
        if (countryCode === null) {
            countryCode = getCookie('countrycode');
            console.log('countryCode cookie ' + countryCode);
        }
        if (countryCode === "") {
            countryCode = "687PDC";
            console.log('countryCode set to default ' + countryCode);
        }
        if (langId === null) {
            langId = getCookie('langId');
            console.log('langId cookie ' + langId);
        }
        if (langId === "") {
            langId = "-1";
            console.log('langId set to default ' + langId);
        }

        // var redirectUrl = domainName === DOMAIN_TFS ? TFS_APP_URL : PARKER_APP_URL;
        var redirectUrl = domainName === DOMAIN_TFS ? getPath('getTFSSSOUrl') : domainName === DOMAIN_BALDWIN ? getPath('getBaldwinSSOUrl') : getPath('getParkerSSOUrl');
        redirectUrl = redirectUrl + '?langId=' + langId + '&countryCode=' + countryCode;
        if(isMyParker){
            redirectUrl=getPath('getMyParkerDashBoardSSO');
        }
        var reqContext = this.getUrlParameter('reqContext');
        if (reqContext !== null && reqContext !== undefined && reqContext !== '') {
            console.log('reqContext query param ' + reqContext);
            redirectUrl = decodeURIComponent(reqContext);
        }

        var signInText = "Don\'t have an account?";
        if (domainName != DOMAIN_PARKER)
        {
            signInText = "You can sign in using your Parker.com\r\naccount information.\r\n\r\nDon\'t have an account?";
        }
        
        let config = {
            language: getLangMap().get(langId.toString()),
            i18n: {
                //Overrides default text when using English. Override other languages by adding additional sections.
                'en': {
                    'registration.signup.label': signInText
                }
            },
            baseUrl: getPath('redirectUrl'), // // baseUrl: REACT_APP_REDIRECT_URL
            redirectUri: redirectUrl,
            features: WidgetFeatures,
            idpDiscovery: {
                requestContext: redirectUrl
            },
            helpLinks: {
                forgotPassword: '/forgotpassword' + this.props.context.history.location.search
            }
        };
        /** for help link url **/
       
        if( domainName === DOMAIN_BALDWIN){
            config.helpLinks.help = getPath('getBaldwinHelpLink')
        }else if( domainName === DOMAIN_TFS){
            config.helpLinks.help = getPath('getTFSHelpLink')
        }
        // add registration based on domainName
        if (isMyParker) {
            config = {
                ...config,
                'features.registration': false,
                i18n: {
                    ...config.i18n,
                    'en': {
                        ...config.i18n.en,
                        'primaryauth.username.placeholder': 'Username/Email',
                        'primaryauth.username.tooltip': 'Username/Email',
                        'oform.next': 'Sign In'
                    }
                }
            }
        } else {
            config.registration = {
                click: () => {
                    const serachParams = this.props.context.history.location.search;
                    this.props.context.history.push({
                        pathname: '/register',
                        search: serachParams,
                        state: {
                            langId: langId,
                            pageName: 'register'
                        }
                    });
                }
            }
        }
        /***************/ 
        const signIn = new OktaSignIn(config);
        if (this.state.signInWidget === null) {
            this.setState({ signInWidget: signIn })
        }
       //  this.loginWithActivationToken(signIn);

        //code block to check if active session --- exists -- start
        // var homePageURL = domainName === DOMAIN_TFS ? TFS_HOME_PAGE : PARKER_HOME_PAGE;
        console.log("Cookie name = ", String(ENV).toLowerCase() + '_login_flag');
        const login_flag = getCookie(String(ENV).toLowerCase() + '_login_flag');
        let homePageURL = domainName === DOMAIN_TFS ? getPath('getTFS_HOME_SP_SSO_PAGE') : domainName === DOMAIN_BALDWIN ? getPath('getBALDWIN_HOME_SP_SSO_PAGE') : getPath('getPARKER_HOME_SP_SSO_PAGE');
        if (login_flag !== '' && String(login_flag).toLowerCase() === 'yes') {
            homePageURL = domainName === DOMAIN_TFS ? getPath('getTFSHomePage') : domainName === DOMAIN_BALDWIN ? getPath('getBALDWIN_HOME_SP_SSO_PAGE') : getPath('getParkerHomePage');
        }
        if(isMyParker){
            homePageURL=getPath('getMyParkerDashBoardSSO');
        }
        // Check for an existing authClient transaction
        /*  signIn.authClient.session.get(function (res) {
            // Session exists, show logged in state.
            console.log('res:' + res);
            if (res.status === 'ACTIVE') {
                console.log('A session exists!');
                window.location.href = homePageURL;
            }
        }); */
        signIn.authClient.session.exists().then(exists => {
            if (exists) {
                //console.log('A session exists!');
                window.location.href = homePageURL;
            } else {
                this.setState({ displayWidget: true })
            }
        }).catch(() => {
            console.log("error checking session");
        });
        //code block to check if active session --- exists -- ends

        signIn.renderEl({
            //el: domainName === DOMAIN_TFS ? '#widget-container_tfs' : domainName === DOMAIN_BALDWIN ? '#widget-container_baldwin' : '#widget-container'
            el: '#widget-container'
        }, function success(res) {

            if (res.status === 'SUCCESS') {
                // Handle success when the widget is not configured for OIDC

                if (res.type === 'SESSION_STEP_UP') {
                    // Session step up response
                    // If the widget is not configured for OIDC and the authentication type is SESSION_STEP_UP,
                    // the response will contain user metadata and a stepUp object with the url of the resource
                    // and a 'finish' function to navigate to that url
                    console.log(res.user);
                    console.log('Target resource url: ' + res.stepUp.url);
                    res.stepUp.finish();
                    return;
                } else {
                    // If the widget is not configured for OIDC, the response will contain
                    // user metadata and a sessionToken that can be converted to an Okta
                    // session cookie:
                    res.session.setCookieAndRedirect(redirectUrl);
                    return;
                }
            }
        });
    }

    loginWithActivationToken (signIn) {
        const activateToken  = this.getParameterByName('activateToken', window.location.href);
        if(activateToken && activateToken !== "") {
            return signIn.authClient.signIn({ token:activateToken});
        }        
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    componentWillUnmount() {
        this.state.signInWidget.remove();
    }

    getUrlParameter(paramName){
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(paramName);
    }

    render() {
        const { domainName } = this.props.context;
        const { langId } = this.props.contextProps;
        return (
            <div id="okta-login-container" className="login-container" style={this.state.displayWidget ? { opacity: 1 } : { opacity: 0 }}>
                <Header />
                    <div id='widget-container'></div>
                <Footer langId={langId} />
            </div>
        );
    }
}

export default props => (
    <Context.Consumer>
        {state => <LoginScreen context={state} contextProps={props} />}
    </Context.Consumer>
);