import React, { Component, Fragment } from 'react';

import SignInScreen from './SignInScreen';
import UpdatePassword from './UpdatePassword';
import LoginScreen from './LoginScreen';
import ForgotPassword from '../widget/ForgotPassword';

class RootComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: "LOGIN_PAGE",
            langId: -1,
            ieVersion: null
        }
        this.getCookie = this.getCookie.bind(this);
    }

    componentDidMount() {
        const ieVersion = window.ie !== undefined ? window.ie : null;
        const getCookieLangId = this.getCookie("langId");
        const langId = getCookieLangId !== "" ? getCookieLangId : this.state.langId;
        this.setState({ langId });
        const loc = window.location.href;
        let pageName = this.state.pageName;
        if (loc.indexOf("login_hint") > -1) {
            pageName = "LOGIN_PAGE";
        } else if (loc.indexOf("updatepassword") > -1) {
            pageName = "UPDATE_PASSWORD_PAGE";
        }
        else if (loc.indexOf("type=forgotpassword") > -1) {
            pageName = "OKTA_WIDGET_FORGOTPASSWORD";
        } else {
            pageName = "OKTA_SIGNIN_WIDGET";
        }
        this.setState({
            pageName,
            ieVersion
        });
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    render() {
        const { localeText } = this.props;

        const widgetContent = (
            this.state.pageName === "LOGIN_PAGE"
            ?
            <SignInScreen
                history={this.props.history}
                dispatch={this.props.dispatch}
                langId={this.state.langId}
                localeText={localeText} />
            :
            this.state.pageName === "UPDATE_PASSWORD_PAGE"
                ?
                <UpdatePassword
                    history={this.props.history}
                    updatePassword={this.props.updatePassword}
                    dispatch={this.props.dispatch}
                    langId={this.state.langId}
                    localeText={localeText} />
                : this.state.pageName === "OKTA_SIGNIN_WIDGET" ? <LoginScreen langId={this.state.langId} />
                : this.state.pageName === "OKTA_WIDGET_FORGOTPASSWORD" ? <ForgotPassword langId={this.state.langId} history={this.props.history} />
                : null
        );

        return (
            <Fragment>
                {
                    this.state.ieVersion && this.state.ieVersion <= 9
                        ?
                        <Fragment></Fragment>
                        :
                        <Fragment>
                            { widgetContent }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default RootComp;