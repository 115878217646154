import React, { Component, Fragment } from "react";
import axios from "axios";
import { getQueryString } from "../util/widgetUtil";

import {
  createOktaEmailObject,
  registerUser,
  getUsersFromGroup,
  createOktaEmailObject_Approval,
  getAppInfo,
  addUserToSaba
} from "../services/util";
import { DOMAIN_TFS, Context, DOMAIN_BALDWIN, DOMAIN_PARKER } from "../Constant";

import Header from "./Header";
import Footer from "./Footer";
import { getAPIUrl, getPath } from "../apiconfig";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: false,
      emailAddressValue: "",
      passwordValue: "",
      showWarning: false,
      showWarning_firstName: false,
      showWarning_lastName: false,
      userLastName: "",
      userFirstName: "",
      orgValue: "",
      isPasswordValid: false,
      isEmailValid: false,
      appId: "",
      appLink:"",
      isButtonDisabled: false,
      areaOfInterest:'',
      areaOfInterestText:'',
      showWarning_areaOfInterest:false,
      showWarning_organization: false,
      applicationName:''
    };
    this.setEmailAddressHandler = this.setEmailAddressHandler.bind(this);
    this.validateEmailAddress = this.validateEmailAddress.bind(this);
    this.setPasswordHandler = this.setPasswordHandler.bind(this);
    this.validatePasswordHandler = this.validatePasswordHandler.bind(this);
    this.setUserFirstNameHandler = this.setUserFirstNameHandler.bind(this);
    this.setUserLastNameHandler = this.setUserLastNameHandler.bind(this);
    this.setOrganisationHandler = this.setOrganisationHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.gotoLoginPage = this.gotoLoginPage.bind(this);
    this.callSmartCommAPI = this.callSmartCommAPI.bind(this);
    this.setAreaOfInterestHandler = this.setAreaOfInterestHandler.bind(this);
  }

  componentDidMount() {
    this.state.appId = getQueryString("appId");
    if (this.state.appId != null)
    {
      try {
        getAppInfo(this.state.appId, (resp) => {
          if (resp.hasOwnProperty("errorCode")) {
            //no app found
          } else {
            this.setState({
              formErrors: false,
              formErrorMessage: "",
              appLink: resp._links.appLinks[0].href,
              applicationName: resp.label
            });
          }
        }); 
      } catch (error) {
        //no app found 
      }
    }

  }

  setEmailAddressHandler(e) {
    this.setState({
      emailAddressValue: e.target.value,
    });
  }

  validateEmailAddress() {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var isEmailValid = reg.test(this.state.emailAddressValue);
    if (!isEmailValid) {
      this.setState({
        formErrors: true,
        formErrorMessage:
          "Provided value for property 'email' does not match required pattern",
        isEmailValid: false,
        isButtonDisabled: false
      });
    }
    else {
      this.setState({
        formErrors: false,
        formErrorMessage: "",
        isEmailValid: true,
      });
    }

    if(this.state.emailAddressValue.includes("@parker.com"))
    {
      isEmailValid = false;
      this.setState({
        formErrors: true,
        formErrorMessage:
          "Sign up is not needed for employees, please use your email and AD password to login",
        isEmailValid: false,
        isButtonDisabled: false
      });
    } 
    return isEmailValid;
  }

  setPasswordHandler(e) {
    this.setState({
      passwordValue: e.target.value,
    });
  }

  validatePasswordHandler() {
    const { passwordValue } = this.state;
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const hasLowerCaseChar = /[a-z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    const hasUpperCaseChar = /[A-Z]/.test(passwordValue);
    const hasSpecialChar = format.test(passwordValue);

    if (passwordValue === "") {
      this.setState({ showWarning: true, isButtonDisabled: false});
    } else {
      this.setState({ showWarning: false });
      const isPasswordValid =
        passwordValue !== "" &&
        passwordValue.length >= 12 &&
        hasLowerCaseChar &&
        hasNumber &&
        hasUpperCaseChar &&
        hasSpecialChar;
      if (!isPasswordValid) {
        let formErrorMessage = "";
        if (
          !hasLowerCaseChar ||
          !hasNumber ||
          !hasUpperCaseChar ||
          !hasSpecialChar
        )
          formErrorMessage =
            "Passwords must contain a mixture of uppercase, lowercase, digits, and symbols";
        else if (passwordValue.length < 12)
          formErrorMessage =
            "Passwords must be at least 12 characters in length";

        this.setState({
          formErrors: true,
          formErrorMessage,
          isPasswordValid: false,
          isButtonDisabled: false
        });
      } else {
        this.setState({
          formErrors: false,
          formErrorMessage: "",
          isPasswordValid: true,
        });
      }
      return isPasswordValid;
    }
  }

  setUserFirstNameHandler(e) {
    this.setState({
      userFirstName: e.target.value,
    });
  }

  setUserLastNameHandler(e) {
    this.setState({
      userLastName: e.target.value,
    });
  }

  setOrganisationHandler(e) {
    this.setState({
      orgValue: e.target.value,
      showWarning_organization:false
    });
  }

  setAreaOfInterestHandler(e) {
    e.preventDefault();
    let { selectedIndex } = e.nativeEvent.target;
    this.setState({
      areaOfInterest: e.target.value,
      areaOfInterestText: e.nativeEvent.target[selectedIndex].text,
      showWarning_areaOfInterest: false
    })
  }

  handleSubmit = () => {
    const {
      emailAddressValue,
      passwordValue,
      userFirstName,
      userLastName,
      orgValue,
      id,
      areaOfInterest,
      areaOfInterestText
    } = this.state;
    const obj = {
      emailFieldValue: emailAddressValue,
      passwordValue,
      userFirstName,
      userLastName,
      orgValue,
      id,
      areaOfInterest,
      areaOfInterestText
    };
    this.setState({ isButtonDisabled: true }); //disable button to prevent multiple submits
    var isEmailValid = this.validateEmailAddress();
    const leanerAppId = getPath("getLeanerAppId");
    const parkerIdAppId= getPath('getParkerIdAppId');
    const storeAppId = getPath('getStoreAppId');
    const distributorAppId= getPath('getDistributorAppId');
    const isPassowrdValid = isEmailValid && this.validatePasswordHandler();
    if (!isPassowrdValid || !isEmailValid) {
      return;
    }
    if (userFirstName === "") {
      this.setState({ showWarning_firstName: true, isButtonDisabled: false });
    } else {
      this.setState({ showWarning_firstName: false });
    }

    if (userLastName === "") {
      this.setState({ showWarning_lastName: true, isButtonDisabled: false});
    } else {
      this.setState({ showWarning_lastName: false });
    }

    if (userFirstName === "" || userLastName === "") {
      return;
    }
    
    if (leanerAppId === this.state.appId) {
      if (!orgValue) {
        this.setState({ showWarning_organization: true, isButtonDisabled: false });
        return;
      }

      if (!areaOfInterest) {
        this.setState({ showWarning_areaOfInterest: true, isButtonDisabled: false });
        return;
      }
    }

    try {
      // const self = this;
      const { langId, domainName } = this.props.context;
      registerUser(obj, domainName, (resp) => {
        if (resp.hasOwnProperty("errorCode")) {
          this.setState({
            formErrors: true,
            formErrorMessage: "User is already registered",
            isButtonDisabled: false
          });
        } else {
          this.setState({
            formErrors: false,
            formErrorMessage: "",
          });
          const formedUrl = window.location.search !== "" ?
            window.location.origin + '/activate-account/' +
            window.location.search +
            "&activateToken=" +
            resp.id :  window.location.origin + '/activate-account/?activateToken=' +
            resp.id;
            obj.id = resp.id;
            const { langId, domainName } = this.props.context;
          if (parkerIdAppId === this.state.appId || leanerAppId === this.state.appId || storeAppId === this.state.appId || distributorAppId === this.state.appId)
            {
              const oktaEmailObj = createOktaEmailObject(
                "register",
                obj,
                formedUrl,
                domainName,
                this.state.appId,
                this.state.applicationName
              );
              this.callSmartCommAPI(oktaEmailObj, resp.id); 
              
              //Add user to Saba
              if (getPath("getLeanerAppId") === this.state.appId) {
                addUserToSaba({ userid: emailAddressValue, fname: userFirstName, lname: userLastName });
              }

              //get approvers and send approval email
              try {
                // const self = this;
                let approverGroupId;
                switch (this.state.appId) {
                  case leanerAppId:
                    approverGroupId = getPath('getLeanerApproverGroupId')[this.state.areaOfInterest];
                    break;
                  case parkerIdAppId:
                    approverGroupId = getPath('getParkerIdApproverGroupId');
                    break;
                  case storeAppId:
                    approverGroupId = getPath('getStoreApproverGroupId');
                    break;
                  case distributorAppId:
                    approverGroupId = getPath('getDistributorApproverGroupId');
                }
                  getUsersFromGroup(approverGroupId, (resp) => {
                  if (resp.hasOwnProperty("errorCode")) {
                    this.setState({
                      formErrors: true,
                      formErrorMessage: "An error occurred",
                      isButtonDisabled: false
                    });
                  } else {
                    this.setState({
                      formErrors: false,
                      formErrorMessage: "",
                    });

                    //send email to each approver
                    for (var i=0; i < resp.length; i++) {
                       const oktaEmailObj = createOktaEmailObject_Approval(
                         "approve",
                         obj,
                         resp[i].profile.email,
                         resp[i].profile.firstName,
                         this.state.appId,
                         this.state.applicationName
                       );
                       //console.log(oktaEmailObj);
                       this.callSmartCommAPI(oktaEmailObj, resp.id);             
                    }         
                    
                  }
                });
              } catch (err) {
                this.setState({
                  formErrors: true,
                  formErrorMessage: "An error occurred, approval to application cannot be completed at this time",
                  isButtonDisabled: false
                });
              }
            }
            else
            {
              const oktaEmailObj = createOktaEmailObject(
                "register",
                obj,
                formedUrl,
                domainName,
                ''
              );
              this.callSmartCommAPI(oktaEmailObj, resp.id); 
            }
         
        }
      });
    } catch (err) {
      this.setState({
        formErrors: true,
        formErrorMessage: "Registration cannot be completed at this time",
        isButtonDisabled: false
      });
    }
  };

  callSmartCommAPI(obj, activationToken) {
    const { langId, history } = this.props.contextProps;
    const inputData = window.btoa(new XMLSerializer().serializeToString(obj));

    const postData = {
      name: "Okta_Email_" + Date.now(),
      inputData: inputData,
      config: "157862427",
      range: "1",
      queue: getPath("getSmartCommQueue"),
    };

    //user has be created at this point (work on custom email for approval flow ex. "Your Parker account has been create but awaiting approval for [appname]...")
    //send user confirmation email to activate account
    axios({
      method: "post",
      url: getAPIUrl("getJobsAPIUrl"),
      headers: {
      },
      data: postData,
    })
      .then((resp) => {
        //console.log(resp);
        if (resp && resp.status === 201) {
          const serachParams = this.props.context.history.location.search;
          history.push({
            pathname: "registration-verification",
            search: serachParams,
            state: {
              userDetails: "",
            },
          });
        } else {
          this.setState({
            formErrors: true,
            formErrorMessage: "Registration cannot be completed at this time",
            isButtonDisabled: false
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          formErrors: true,
          formErrorMessage: "Registration cannot be completed at this time",
          isButtonDisabled: false
        });
      });
  }

  gotoLoginPage() {
      const { langId, history } = this.props.contextProps;
      history.push({
        pathname: "/",
        search: this.props.context.history.location.search,
        state: {
          langId: langId,
        },
      }); 
  }

  render() {
    const {
      formErrors,
      formErrorMessage,
      emailAddressValue,
      passwordValue,
      showWarning,
      userFirstName,
      userLastName,
      orgValue,
      isPasswordValid,
      isEmailValid,
      showWarning_firstName,
      showWarning_lastName,
      appLink,
      showWarning_organization
    } = this.state;
    const { langId, domainName } = this.props.context;
    const registerButtonTfs = {
      background: "#13628D",
      color: "#fff",
      cursor: "pointer",
    };
    const registerButtonTfs_disabled = {
      background: "#4cb8f2",
      color: "#fff",
      cursor: "text",
    };
    const registerButtonParker = {
      background: "#ffb91d",
      color: "#424242",
      cursor: "pointer",
    };
    const registerButtonBaldwin = {
      background: "#424242",
      color: "#fff",
      cursor: "pointer",
    };
    const registerButtonParker_disabled = {
      background: "#ecc97d",
      color: "#424242",
      cursor: "text",
    };
    const isRegisterButtonDisabled = !(isEmailValid && isPasswordValid);
    const registerButtonStyle =
      domainName === DOMAIN_TFS
        ? registerButtonTfs
        : domainName === DOMAIN_PARKER
        ? registerButtonParker
        : domainName === DOMAIN_BALDWIN
        ? registerButtonBaldwin
        : registerButtonParker;

    /*const loginbgCls =
      domainName === DOMAIN_TFS ? "loginBackground_TFS" : DOMAIN_BALDWIN ? "loginBackground_baldwin" : "loginBackground";*/
      
    const commonClass = "o-form-control okta-form-input-field input-fix";
    const displayWarning = (
      <p
        className="okta-form-input-error o-form-input-error o-form-explain"
        role="alert"
        style={{ display: "block" }}
      >
        <div
          className="icon icon-16 error-16-small"
          role="img"
          aria-label="Error"
        ></div>
        This field cannot be left blank
      </p>
    );


    return (
      <Fragment>
        <div className="content-wrapper">
          <div id="okta-login-container" className={"regcontainer"}>
            <Header />
            <div id="widget-container">
              <div id="okta-sign-in" className="auth-container main-container">
                <div className="auth-content">
                  <div className="registration">
                    <div className="o-form">
                      <h2 className="okta-form-title o-form-head">Create Account</h2>
                      {formErrors ? (
                        <div className="o-form-error-container o-form-has-errors">
                          <div>
                            <div
                              className="okta-form-infobox-error infobox infobox-error"
                              role="alert"
                            >
                              <div className="icon error-16"></div>
                              <p>{formErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-input">
                          <div
                            className={
                              commonClass + " " + "o-form-input-name-email"
                            }
                          >
                            <input
                              type="text"
                              placeholder="Email address: *"
                              value={emailAddressValue}
                              aria-label="Email address:"
                              autoComplete="off"
                              onChange={(e) => this.setEmailAddressHandler(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-input">
                          <div
                            className={
                              commonClass + " " + "o-form-input-name-password"
                            }
                          >
                            <div className="icon input-icon remote-lock-16"></div>
                            <input
                              type="password"
                              placeholder="Password: *"
                              name="password"
                              value={passwordValue}
                              aria-label="Password: *"
                              autoComplete="off"
                              onChange={(e) => this.setPasswordHandler(e)}
                              // onBlur={this.validatePasswordHandler}
                            />
                          </div>
                          {showWarning ? displayWarning : null}
                        </div>
                      </div>
                      <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-input">
                          <div
                            className={
                              commonClass + " " + "o-form-input-name-firstName"
                            }
                          >
                            <input
                              type="text"
                              placeholder="First Name: *"
                              name="firstName"
                              value={userFirstName}
                              aria-label="First Name:"
                              autoComplete="off"
                              onChange={(e) => this.setUserFirstNameHandler(e)}
                            />
                          </div>
                          {showWarning_firstName && displayWarning}
                        </div>
                      </div>
                      <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-input">
                          <div
                            className={
                              commonClass + " " + "o-form-input-name-lastName"
                            }
                          >
                            <input
                              type="text"
                              placeholder="Last Name: *"
                              name="lastName"
                              onChange={(e) => this.setUserLastNameHandler(e)}
                              value={userLastName}
                              aria-label="Last Name:"
                              autoComplete="off"
                            />
                          </div>
                          {showWarning_lastName && displayWarning}
                        </div>
                      </div>
                      <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-input">
                          <div
                            className={
                              commonClass +
                              " " +
                              "o-form-input-name-organization"
                            }
                          >
                            <input
                              type="text"
                              placeholder={`Organization:${getPath("getLeanerAppId") === this.state.appId ? ' *' : ''}`}
                              name="organization"
                              value={orgValue}
                              aria-label="Organization:"
                              autoComplete="off"
                              onChange={(e) => this.setOrganisationHandler(e)}
                            />
                          </div>
                          {showWarning_organization && displayWarning}
                        </div>
                      </div>
                      {getPath("getLeanerAppId") === this.state.appId && <div className="o-form-fieldset o-form-label-top">
                        <div className="o-form-select">
                          <div className="o-form-control okta-form-select-field select-name-area-interest">
                            <select value={this.state.areaOfInterest} onChange={this.setAreaOfInterestHandler}
                              name="areaOfInterest"
                            >
                              <option value='' disabled hidden>Area of interest: *</option>
                              <option value="interest_1">Filtration and Purification</option>
                              <option value="interest_2">High Purity and Instrumentation</option>
                              <option value="interest_3">Seals and Shielding</option>
                              <option value="interest_4">Chomerics</option>
                              <option value="interest_5">HVAC & Refrigeration</option>
                            </select>
                          </div>
                          {this.state.showWarning_areaOfInterest && displayWarning}
                        </div>
                      </div>}
                      <div className="required-fields-label" style={{ color: '#a7a7a7', paddingBottom: '20px', fontSize: '90%'}}>
                        * indicates required field
                      </div>

                      <div className="o-form-button-bar">
                        <button
                          style={registerButtonStyle}
                          className="button button-primary"
                          value="Register"
                          onClick={this.handleSubmit}
                          disabled={this.state.isButtonDisabled}
                        >
                          Register
                        </button>
                      </div>
                    </div>
                    <div className="regisFooter">
                      <a href={appLink} onClick={this.gotoLoginPage} className="link help" style={{fontSize: '90%'}}>
                        Back to Sign In
                      </a>                
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer langId={langId} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {(state) => <Registration context={state} contextProps={props} />}
  </Context.Consumer>
);
