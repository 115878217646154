import React from 'react';
import { useSelector } from 'react-redux'

import { Localization } from "../util/localization";
import { LANG_IDS } from "../util/Translation";

const MyParkerClose = () => {
    const langId = useSelector((state) => state.langId)

    const getSubhead = () => {
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        return localeFileText.MY_PARKER.subHead;
    };

    const getHeader = () => {
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        return localeFileText.MY_PARKER.heading;
    };

    const getContent = () => {
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        return localeFileText.MY_PARKER.content;
    };

    return (
        <>
            <div className="text-light my-parker-content">
                <h1>{getHeader()}</h1>
                <p dangerouslySetInnerHTML={{ __html: getContent() }}/>
            </div>
        </>
    );
};
  
export default MyParkerClose;