import {DOMAIN_LOCAL, DOMAIN_BALDWIN, DOMAIN_TFS, DOMAIN_PARKER, DOMAIN_MYPARKER } from "../Constant";

import TFS_LOGO from '../images/tfs-logo.png';
import PARKER_LOGO from '../images/logo-black-hex.jpg';
import BALDWIN_LOGO from '../images/baldwin-logo.jpg';
import MYPARKER_LOGO from '../images/my-parker-logo.svg';

//Get the theme string of the current domain
export const getTheme = () => {
    const loc = window.location.hostname,
        domainConstants = {DOMAIN_LOCAL, DOMAIN_BALDWIN, DOMAIN_TFS, DOMAIN_PARKER, DOMAIN_MYPARKER};

    let currDomainRef = '',
        currTheme = 'theme-parker';

    for (var k in domainConstants) {
        if(loc.indexOf(domainConstants[k]) > -1) {
            currDomainRef = k;
        }
    }

    switch( currDomainRef ) {
        case 'DOMAIN_BALDWIN' :
            currTheme = 'theme-baldwin';
            break;
        case 'DOMAIN_TFS' :
            currTheme = 'theme-tfs';
            break;
        case 'DOMAIN_MYPARKER' :
            currTheme = 'theme-myparker';
            break;
        case 'DOMAIN_PARKER' :
        case 'DOMAIN_LOCAL' :
        default :
            currTheme = 'theme-parker';
            break;
    }
    
    return currTheme;
}

export const getLogo = () => {
    let logo = '';

    switch (getTheme()) {
        case 'theme-baldwin' :
            logo = BALDWIN_LOGO;
            break;
        case 'theme-tfs' :
            logo = TFS_LOGO;
            break;
        case 'theme-myparker' :
            logo = MYPARKER_LOGO;
            break;
        case 'theme-parker' :
        default :
            logo = PARKER_LOGO;
            break;
    }

    return logo;
}