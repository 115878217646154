import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import {
    // REACT_APP_REDIRECT_URL,
    // REACT_APP_PHCONNECT_URL,
    // REACT_APP_TFS_REQCONTEXT,
    // REACT_APP_PCM_LINKURL,
    // TFSREDIRECT_URL
} from "../config/properties.js";
import {
    DID_NOT_RECEIEVE_MAIL,
} from "../util/Messages";

import {
    Localization
} from "../util/localization";

import {
    LANG_IDS
} from "../util/Translation";

// import { Context } from "../App";

import {
    DOMAIN_PARKER,
    DOMAIN_TFS,
    DOMAIN_BALDWIN,
    Context
} from "../Constant";

import {
    getPCMLink
} from "../services/util";
import { getPath } from '../apiconfig.js';

class SuccessMessage extends React.Component {
    constructor(props) {
        super(props);
        this.createEmailAddressMarkup = this.createEmailAddressMarkup.bind(this);
        this.createSenderMarkeup = this.createSenderMarkeup.bind(this);
        this.createImportantMessage = this.createImportantMessage.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.getRequestURL = this.getRequestURL.bind(this);
        this.getRequestURL_TFSContext = this.getRequestURL_TFSContext.bind(this);
        this.getRequestURL_BaldwinContext = this.getRequestURL_BaldwinContext.bind(this);
        this.state = {
            wCSRequestURL: "",
            langId: "-1",
            cookieLangId: "",
            cookieCountryCode: "",
            showPCMLink: false

        }

    }

    componentDidMount() {
        const self = this;
        const { history } = this.props.contextProps;
        const stateVal = history && history.location && history.location.state ? history.location.state : null;
        const langId = stateVal ? stateVal.langId : "-1";
        const userGuid = stateVal ? stateVal.userGuid : '';
        const getCookieLangId = this.getCookie("langId");
        const cookieLangId = getCookieLangId !== "" ? getCookieLangId : this.state.cookieLangId;

        const getCookiecountryCode = this.getCookie("countryCode");
        const cookieCountryCode = getCookiecountryCode !== "" ? getCookiecountryCode : this.state.cookieCountryCode;

        const getCookieWCSRequestURL = this.getCookie("WCSRequestURL");
        const wCSRequestURL = getCookieWCSRequestURL !== "" ? getCookieWCSRequestURL : this.state.wCSRequestURL;
        if (userGuid) {
            getPCMLink(userGuid, (resp) => {
                if (resp && !resp.hasOwnProperty("errorSummary")) {
                    const userGrp = resp.data.filter(d => {
                        var profileName = d.profile.name;
                        return (profileName === 'DIV_QCD_Users');
                    });
                    self.setState({
                        showPCMLink: userGrp.length ? true : false
                    })
                }
            });
        }
        this.setState({ wCSRequestURL, langId, cookieLangId, cookieCountryCode });
    }

    /**
     * @func createEmailAddressMarkup
     * @params none 
     * @desc function is responsible for creating Email Address Markup
     */
    createEmailAddressMarkup() {
        // let msg = this.props.localeText.SUCCESS_HEADER_1_EMAIL_CONFIRMATION;
        const { langId } = this.state;
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        let msg = localeFileText.SUCCESS_HEADER_1_EMAIL_CONFIRMATION;
        msg = msg.replace("#mailid", "<span style=font-weight:bold>" + this.props.contextProps.emailAddress + "<\/span>");
        msg = msg + localeFileText.SUCCESS_HEADER_1_EMAIL_CONFIRMATION_2;
        msg = msg + " <span style=font-weight:bold>" + localeFileText.SUCCESS_HEADER_1_EMAIL_CONFIRMATION_3 + "<\/span>";
        return { __html: msg };
    };

    /**
     * @func createSenderMarkeup
     * @params none 
     * @desc function is responsible for creating sender Markup
     */
    createSenderMarkeup() {
        // const marketingText = this.props.localeText.SUCCESS_HEADER_1_EMAIL_DEFAULT;
        const { langId } = this.state;
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        const marketingText = localeFileText.SUCCESS_HEADER_1_EMAIL_DEFAULT + "<a href=''>" + localeFileText.SUCCESS_HEADER_1_EMAIL_DEFAULT_2 + "</a>";

        return { __html: marketingText };
    }

    /**
     * @func createImportantMessage
     * @params none 
     * @desc function is responsible for creating success message Markup
     */
    createImportantMessage() {
        // const localeTxt = Localization[LANG_IDS[this.state.langId]];
        const { langId } = this.state;
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        const marketingText = "<span style=font-weight:bold>" + localeFileText.SUCCESS_IMPORTANT_MESSAGE + "</span>";
        return { __html: marketingText };
    }

    getCookie(cname) {
        let name = cname + "=";
        // let decodedCookie = decodeURIComponent(document.cookie);
        let decodedCookie = document.cookie;
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getRequestURL() {
        const {
            wCSRequestURL,
            cookieLangId,
            cookieCountryCode
        } = this.state;
        const { domainName } = this.props.context;

        // let requestURL = domainName === DOMAIN_PARKER ? REACT_APP_REDIRECT_URL : TFSREDIRECT_URL
        let requestURL = domainName === DOMAIN_TFS ? getPath('getTFSRedirectUrl') : domainName === DOMAIN_BALDWIN ? getPath('redirectUrlBaldwin') : getPath('redirectUrl');
        
        if (cookieLangId !== "") {
            requestURL += "?langId=" + cookieLangId;
        }
        if (cookieLangId !== "" && cookieCountryCode !== "") {
            requestURL += "&countryCode=" + cookieCountryCode;
        } else if (cookieLangId === "" && cookieCountryCode !== "") {
            requestURL += "?countryCode=" + cookieCountryCode;
        }
        if (cookieLangId !== "" && cookieCountryCode !== "" && wCSRequestURL !== "") {
            requestURL += "&reqContext=" + wCSRequestURL;
        } else if (cookieLangId === "" && cookieCountryCode === "" && wCSRequestURL !== "") {
            requestURL += "?reqContext=" + wCSRequestURL;
        } else if (cookieLangId !== "" && cookieCountryCode === "" && wCSRequestURL !== "") {
            requestURL += "&reqContext=" + wCSRequestURL;
        }
        else if (cookieLangId === "" && cookieCountryCode !== "" && wCSRequestURL !== "") {
            requestURL += "&reqContext=" + wCSRequestURL;
        }
        return requestURL;
    }

    getRequestURL_TFSContext(strRequestURL) {
        let requestURL = strRequestURL;
        const {
            wCSRequestURL,
            cookieLangId,
            cookieCountryCode
        } = this.state;

		/*if (wCSRequestURL === "" && cookieLangId === "" && cookieCountryCode === "") {
            requestURL += "?reqContext=" + REACT_APP_TFS_REQCONTEXT;
        } else if (wCSRequestURL === "") {
            requestURL += "&reqContext=" + REACT_APP_TFS_REQCONTEXT;
        }*/

        if (wCSRequestURL === "" && cookieLangId === "" && cookieCountryCode === "") {
            requestURL += "?reqContext=" + getPath('getTFSReqContext');
        } else if (wCSRequestURL === "") {
            requestURL += "&reqContext=" + getPath('getTFSReqContext');
        }
        return requestURL;
    }

    getRequestURL_BaldwinContext() {
        const {
            
            cookieLangId,
            cookieCountryCode
        } = this.state;
        const { domainName } = this.props.context;

        // let requestURL = domainName === DOMAIN_PARKER ? REACT_APP_REDIRECT_URL : TFSREDIRECT_URL
        let requestURL = domainName === DOMAIN_TFS ? getPath('getTFSRedirectUrl') : domainName === DOMAIN_BALDWIN ? getPath('redirectUrlBaldwin') : getPath('redirectUrl');
        requestURL += "?reqContext=" + getPath('getBaldwinReqContext');
        
        if (cookieLangId !== "") {
            requestURL += "&langId=" + cookieLangId;
        }
        if (cookieCountryCode !== "") {
            requestURL += "&countryCode=" + cookieCountryCode;
        } 
        
        return requestURL;
    }

    render() {
        //const { localeText } = this.props.contextProps;
        const { domainName } = this.props.context;
        const { langId } = this.state;
        // const localeFileText = Localization[LANG_IDS[langId]];
        const localeFileText = Localization[LANG_IDS[langId]] ? Localization[LANG_IDS[langId]] : Localization[LANG_IDS["-1"]];
        const redirectUrl = this.getRequestURL(); // REACT_APP_REDIRECT_URL + "/?reqContext=" + this.state.wCSRequestURL;
        const tfsRedirectURL = this.getRequestURL_TFSContext(redirectUrl);
        const baldwinRedirectURL = this.getRequestURL_BaldwinContext()
        return (
            <Container>
                <Row>
                    <Col sm={12} className="logoLeft">
                        <a href="/">
                            {
                                domainName === DOMAIN_PARKER
                                    ?
                                    <img src="assets/img/logo-black-hex.jpg" alt="Logo" />
                                    :
                                    domainName === DOMAIN_TFS
                                    ?
                                    <img src="assets/img/tfs-logo.png" alt="Logo" />
                                    :
                                    domainName === DOMAIN_BALDWIN
                                    ?
                                    <img src="assets/img/baldwin-logo.jpg" alt="Logo" />
                                    : null
                            }
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <hr className={domainName === DOMAIN_PARKER ? "yellow" : domainName === DOMAIN_TFS ? "blue" : domainName === DOMAIN_BALDWIN ? "red" : ""} />
                    </Col>
                </Row>
                {
                    this.props.contextProps.screen === "1"
                        ?
                        <React.Fragment>
                            <Row>
                                <Col sm={12} className="email_verification-wrap">
                                    <h2 className="okta-form-title o-form-head-email">
                                        {localeFileText.SUCCESS_HEADER_1}
                                    </h2>
                                </Col>
                            </Row>
                            <Row className="paddingBottom20px">
                                <Col sm={12} className="paddingTop20px paddingBottom20px">
                                    <span
                                        className="widget-body-text-email"
                                        dangerouslySetInnerHTML={this.createEmailAddressMarkup()}>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="paddingBottom20px">
                                <Col sm={12} className="paddingBottom20px">
                                    {
                                        /*<span className="widget-body-text-email"> {localeText.SUCCESS_HEADER_1_EMAIL_DEFAULT}
                                        <a href="">{localeText.SUCCESS_HEADER_1_SENDER_MAIL}</a>
                                        </span>*/
                                    }
                                    <span
                                        className="widget-body-text-email"
                                        dangerouslySetInnerHTML={this.createSenderMarkeup()}>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="paddingBottom20px">
                                <Col sm={12} className="paddingBottom20px">
                                    <span className="widget-body-text-email">
                                        {DID_NOT_RECEIEVE_MAIL}
                                    </span>
                                </Col>
                            </Row>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Row>
                                <Col sm={12} className="email_verification-wrap paddingBottom20px">
                                    <h2 className="okta-form-title o-form-head-email">
                                        {localeFileText.SUCCESS_HEADER_2}
                                    </h2>
                                </Col>
                            </Row>
                            {
                                domainName === DOMAIN_PARKER
                                    ?
                                    <Row className="paddingBottom20px">
                                        <Col sm={12} className="paddingBottom20px">
                                            <span className="widget-body-text-email">
{/*REACT_APP_PHCONNECT_URL*/}
                                                <a href={getPath('getPhConnectUrl')}> {localeFileText.LOGIN_REDIRECT}</a> {localeFileText.LOGIN_REDIRECT_2 + " to PHConnect"}
                                            </span>
                                        </Col>
                                        <Col sm={12} className="paddingBottom20px">
                                            <span className="widget-body-text-email">
                                                <a href={redirectUrl}> {localeFileText.LOGIN_REDIRECT}</a> {localeFileText.LOGIN_REDIRECT_2 + " to Parker.com"}
                                            </span>
                                        </Col>
                                        {
                                            this.state.showPCMLink
                                                ?
                                                <Col sm={12} className="paddingBottom20px">
                                                    <span className="widget-body-text-email">
{/*REACT_APP_PCM_LINKURL*/}
                                                        <a href={getPath('getPCMLinkURL')}> {localeFileText.LOGIN_REDIRECT}</a> {"to login to PCM"}
                                                    </span>
                                                </Col>
                                                : null
                                        }


                                    </Row>
                                    :
                                    domainName === DOMAIN_TFS
                                    ?
                                    <Row className="paddingBottom20px">
                                        <Col sm={12} className="paddingBottom20px">
                                            <span className="widget-body-text-email">
                                                <a href={tfsRedirectURL}> {localeFileText.LOGIN_REDIRECT}</a> {localeFileText.LOGIN_REDIRECT_2}
                                            </span>
                                        </Col>

                                    </Row>
                                    :
                                    <Row className="paddingBottom20px">
                                    <Col sm={12} className="paddingBottom20px">
                                        <span className="widget-body-text-email">
                                            <a href={baldwinRedirectURL}> {localeFileText.LOGIN_REDIRECT}</a> {localeFileText.LOGIN_REDIRECT_2}
                                        </span>
                                    </Col>

                                </Row>

                            }


                            <Row className="paddingBottom20px">
                                <Col sm={12} className="paddingBottom20px">
                                    <span
                                        className="widget-body-text-email"
                                        dangerouslySetInnerHTML={this.createImportantMessage()}>
                                    </span>
                                </Col>
                            </Row>
                        </React.Fragment>
                }
            </Container>
        )
    }
}

// export default SuccessMessage;
export default props => (
    <Context.Consumer>
        {state => <SuccessMessage context={state} contextProps={props} />}
    </Context.Consumer>
);