
export const WidgetFeatures = {
    autoPush: false,
    callRecovery: false,
    consent: false,
    deviceFingerprinting: true,
    emailRecovery: true,
    hideSignOutLinkInMFA: false,
    idpDiscovery: true,
    multiOptionalFactorEnroll: true,
    registration: true,
    rememberMe: false,
    restrictRedirectToForeground: false,
    router: false,
    securityImage: false,
    selfServiceUnlock: false,
    showPasswordToggleOnSignInPage: false,
    smsRecovery: false,
    useDeviceFingerprintForSecurityImage: true,
    webauthn: true,
    windowsVerify: false,
}


export const getLangMapForPPAndTandC = () => {
    const langMapForPPAndTandC = new Map([["-6", "EN"],
    ["-7", "EN"], ["-27", "EN"],
    ["-28", "EN"], ["-23", "EN"],
    ["-24", "EN"], ["-2", "FR"],
    ["-3", "EN"], ["-31", "EN"],
    ["-4", "EN"], ["-10", "EN"],
    ["-29", "EN"], ["-25", "EN"],
    ["-22", "EN"], ["-21", "EN"],
    ["-20", "EN"], ["-9", "EN"],
    ["-5", "ES"], ["-26", "EN"],
    ["-8", "EN"], ["-32", "EN"],
    ["-30", "EN"], ["-1", "EN"],
    ["-34", "EN"]
    ]);
    return langMapForPPAndTandC;
}

export const getLangMap = () => {
    const langMap = new Map([["-6", "pt-BR"],
    ["-7", "zh-CN"], ["-27", "cs"],
    ["-28", "da"], ["-23", "ar"],
    ["-24", "fi"], ["-2", "fr"],
    ["-3", "de"], ["-31", "hu"],
    ["-4", "it"], ["-10", "ja"],
    ["-29", "nl-NL"], ["-25", "nb"],
    ["-22", "pl"], ["-21", "ro"],
    ["-20", "ru"], ["-9", "ko"],
    ["-5", "es"], ["-26", "sv"],
    ["-8", "zh"], ["-32", "th"],
    ["-30", "tr"], ["-1", "en"],
    ["-34", "vi"]
    ]);
    return langMap;
}

export const getLocaleMap = () => {
    const localeMap = new Map([["-6", "pt_BR"],
    ["-7", "zh_CN"], ["-27", "cs_CZ"],
    ["-28", "da_DK"], ["-23", "ar_EG"],
    ["-24", "fi_FI"], ["-2", "fr_FR"],
    ["-3", "de_DE"], ["-31", "hu_HU"],
    ["-4", "it_IT"], ["-10", "ja_JP"],
    ["-29", "nl_NL"], ["-25", "nb_NO"],
    ["-22", "pl_PL"], ["-21", "ro_RO"],
    ["-20", "ru_RU"], ["-9", "ko_KR"],
    ["-5", "es_ES"], ["-26", "sv_SE"],
    ["-8", "zh_TW"], ["-32", "th_TH"],
    ["-30", "tr_TR"], ["-1", "en_US"],
    ["-34", "vi_VN"]
    ]);

    return localeMap;
}

export const getQueryString = (field, url) => {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
}

export const getUrlParameter = name => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getCookie = input => {

    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var name = String(cookies[i].split('=')[0]).toLowerCase();
        var value = String(cookies[i].split('=')[1]).toLowerCase();
        if (name === input) {
            return value;
        } else if (value === input) {
            return name;
        }
    }
    return "";
};




export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const termsNConditionLabel = new Map([["-1", "Terms and Conditions"], ["-6", "Termos e Condições"],
["-7", "条款和条件"], ["-27", "Obchodní podmínky"],
["-28", "Handelsbetingelser"], ["-23", "ar"],
["-24", "Ehdot"], ["-2", "Conditions"],
["-3", "Allgemeine Geschäftsbedingungen"], ["-31", "Általános szállítási feltételek"],
["-4", "Termini e condizioni"], ["-10", "契約条件"],
["-29", "Algemene voorwaarden"], ["-25", "Vilkår og betingelser"],
["-22", "Terminy i warunki"], ["-21", "ro"],
["-20", "Условия и сроки"], ["-9", "이용 약관"],
["-5", "Condiciones"], ["-26", "Villkor"],
["-8", "條款和條件"], ["-32", "ข้อตกลงและเงื่อนไข"],
["-30", "Şartlar ve Koşullar"],
["-34", "Điều khoản và Điều kiện"]
]);

export const privacyPolicyLabel = new Map([["-1", "Privacy Policies"], ["-6", "Informações legais"],
["-7", "站点信息"], ["-27", "Zásady ochrany osobních údajů"],
["-28", "Fortrolighedspolitik"], ["-23", "ar"],
["-24", "Sivuston tiedot"], ["-2", "Mention légale"],
["-3", "Impressum"], ["-31", "Adatvédelem"],
["-4", "Informativa sulla Privacy"], ["-10", "プライバシー"],
["-29", "Privacy beleid"], ["-25", "Områdeinformasjon"],
["-22", "Polityka prywatności"], ["-21", "ro"],
["-20", "Конфиденциальность"], ["-9", "사이트 정보"],
["-5", "Información del sitio"], ["-26", "Platsinformation"],
["-8", "隱私權政策"], ["-32", "นโยบายความเป็นส่วนตัว"],
["-30", "Gizlilik Politikaları"],
["-34", "Chính sách bảo mật"]
])