import React, { Fragment } from 'react';
import Header from './Header';
import PARKER_LOGO from './logo-black-hex.jpg';
import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';
import { getUserDetails, getAppInfo, createOktaEmailObject_Access, callSmartCommAPI, DeleteUser,deleteUserToSaba} from '../services/util';
import {  getPath } from "../apiconfig";
const Deny = () => {
    const { authState, authService } = useOktaAuth();
    const [message, SetMessage] = useState(null);

    useEffect(() => {
        if (!authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          //setUserInfo(null);
        } else 
        {
          var path = window.location.pathname.split('/');
          var userId = path[2];
          var groupId = path[3];
          var appId = path[4];     

          const accessToken = (authState.accessToken);

          //Delete user to Saba
          if (getPath("getLeanerAppId") === appId) {
            deleteUserToSaba(userId);
          }
          //get user info
          getUserDetails(userId, (resp) => {
            if (resp && resp.hasOwnProperty("errorCode")) {
                SetMessage("User no longer exists in the system");
            }  
            else {
                var username = resp.profile.login;
                var firstname = resp.profile.firstName;

                
                //if user is staged then delete the user
                if(resp.status === 'STAGED')
                {
                    console.log('in staged');
                    //deactivate user
                    DeleteUser(username, accessToken, (resp) => {
                      if (resp.hasOwnProperty("errorCode")) {
                          SetMessage("An error occureed deleting " + username + ".");
                      } 
                      else 
                      {
                        //delete user
                        DeleteUser(username, accessToken, (resp) => {
                          if (resp.hasOwnProperty("errorCode")) {
                              SetMessage("An error occureed deleting " + username + ".");
                          } 
                      });  
                      }
                  });  
                    
                }

                //get app info and user info
                getAppInfo(appId, (resp) => {
                    if (resp.hasOwnProperty("errorCode")) {

                    } else {
                        const oktaEmailObj = createOktaEmailObject_Access(username, firstname, resp.label, "deny");
                        //console.log(oktaEmailObj);
                        callSmartCommAPI(oktaEmailObj);             
                        SetMessage("An email will be sent to " + username + " informing them their access has been denied to "  + resp.label);
                    }
                });            
                    
                               
            }
          });   
          
        }
      }, [authState, authService]); // Update if authState changes

        return (
            <Fragment>
              <div className="loginBackground">
                <div id="okta-login-container" className={"regcontainer"}>
                  <Header />
                  <div id="widget-container">
                        <div  id="okta-sign-in" className="auth-container main-container no-beacon">
                            <div className="okta-sign-in-header auth-header">
                                <img src={PARKER_LOGO} className="auth-org-logo" alt="logo" />
                                <div data-type="beacon-container" className="beacon-container"></div>
                            </div>
                            <div className="auth-content">
                                <div className="auth-content-inner">
                                    <div className="registration-complete">
                                        <form
                                            method="POST"
                                            action="/"
                                            slot="content"
                                            id="form15"
                                            className="o-form o-form-edit-mode"
                                        >
                                            <div  className="o-form-content o-form-theme clearfix">
                                                <div className="o-form-error-container" ></div>
                                                <div className="o-form-fieldset-container" >
                                                  <div style={{ textAlign: 'center'}}>
                                                        <span className="remove-16-red" style={{ display: 'table', margin:'0 auto', paddingBottom: '10px' }}></span>
                                                        <h2 style={{ textAlign: 'center', paddingTop: '15px', paddingBottom: '20px'  }}>User denied access</h2>
                                                        <span>{message}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
};

export default Deny;