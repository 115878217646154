import React, { Fragment } from 'react';
import Header from './Header';
import PARKER_LOGO from './logo-black-hex.jpg';
import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';
import { getGroupsFromUser, getUserDetails, addUserToGroup, getAppInfo, createOktaEmailObject_Access, callSmartCommAPI} from '../services/util';
import { getPath } from "../apiconfig";

const Approve = () => {
    const { authState, authService } = useOktaAuth();
    const [message, SetMessage] = useState(null);

    useEffect(() => {
        if (!authState.isAuthenticated) {
          // When user isn't authenticated
        } else {
          //check if user is already in group
          var path = window.location.pathname.split('/');
          var userId = path[2];
          var groupId = path[3];
          var appId = path[4];        

          //use accesstoken for any changes that need made
          const accessToken = (authState.accessToken);

          getGroupsFromUser(userId, (resp) => {
            if (resp.hasOwnProperty("errorCode")) {
                SetMessage("User no longer exists in the system");
            } 
            else {
              var addUser = true;

              //check if user already exists in group
              for (var i=0; i < resp.length; i++) {
                if(resp[i].id === groupId)
                {
                    addUser = false;
                    SetMessage("User has already been granted access to application");
                }       
              }  
              
              //add user to group and send email
              if(addUser === true)
              {
                //get user info
                getUserDetails(userId, (resp) => {
                    if (resp && resp.hasOwnProperty("errorCode")) {
                        SetMessage("An error occured: could not find user infromation");
                    }  
                    else {
                        //check if user is pending or active status
                        //if pending, they will need to be sent to activation url first
                        var link = "";
                        var username = resp.profile.login;
                        var firstname = resp.profile.firstName;
                        var lastName = resp.profile.lastName;

                        if(resp.status === 'STAGED')
                        {
                            link =  getPath('getBaseReactUrl') + "/activate-account/?activateToken=" + resp.id + '&appId=' + appId;
                        }

                        //add user to app group
                        addUserToGroup((resp) => {
                            if (resp.hasOwnProperty("errorMessage")) {
                                SetMessage("There was an issue adding user to group for access");
                            } else {
                                //get app info 
                                getAppInfo(appId, (resp) => {
                                    if (resp.hasOwnProperty("errorCode")) {

                                    } else {
                                        if (link === "") {
                                            link = resp._links.appLinks[0].href;
                                        }
                                        
                                        const oktaEmailObj = createOktaEmailObject_Access(username, firstname, resp.label, link);
                                        //console.log(oktaEmailObj);
                                        callSmartCommAPI(oktaEmailObj);
                                        SetMessage("An email will be sent to " + username + " with a link to access " + resp.label);
                                    }
                                });            
                            }
                        }, userId, groupId, accessToken);                 
                    }

                 
                  });                               
              }
              
            }
          });


        }
      }, [authState, authService]); // Update if authState changes
        return (
            <Fragment>
              <div className="content-wrapper">
                <div id="okta-login-container" className={"regcontainer"}>
                  <Header />
                  <div id="widget-container">
                        <div  id="okta-sign-in" className="auth-container main-container no-beacon">
                            <div className="okta-sign-in-header auth-header">
                                <img src={PARKER_LOGO} className="auth-org-logo" alt="logo" />
                                <div data-type="beacon-container" className="beacon-container"></div>
                            </div>
                            <div className="auth-content">
                                <div className="auth-content-inner">
                                    <div className="registration-complete">
                                        <form
                                            method="POST"
                                            action="/"
                                            slot="content"
                                            id="form15"
                                            className="o-form o-form-edit-mode"
                                        >
                                            <div  className="o-form-content o-form-theme clearfix">
                                                <div className="o-form-error-container" ></div>
                                                <div className="o-form-fieldset-container" >
                                                    <div style={{ textAlign: 'center'}}>
                                                        <span className="title-icon icon icon-16 confirm-16-green"></span>
                                                        <h2 style={{ textAlign: 'center', paddingTop: '15px', paddingBottom: '20px'  }}>User granted access</h2>
                                                        <span>{message}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
};

export default Approve;