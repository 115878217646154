export const TRANSLATION = {
    SIGN_IN_SCREEN: [],
    EMAIL_VERIFICATION_SCREEN: [
        1883,
        1883,
        1906,
        1907,
        1886,
        1887,
        1888,
        1889,
        404,
        1890,
        1891,
        1892
    ],
    SUCCESS_SCREEN: [],
    UPDATE_PASSWORD_SCREEN: [
        1893,
        1894,
        1895,
        1896,
        1897,
        1898,
        1899,
        1900,
        1901
    ],
    PASSWORD_SUCCESS_SCREEN: [
        1903,
        1904,
        1905
    ]
}

export const assignLocaleToIDs = (localeData, langId) => {
    const localeStateKeys = {
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: localeData["1883"][LANG_IDS[langId]],
        EMAIL_VERIFICATION_MESSAGE: localeData["1906"][LANG_IDS[langId]],
        EMAIL_VERIFICATION_EMAIL_ADDRESS: localeData["1886"][LANG_IDS[langId]],
        MODAL_EMAIL_MESSAGE: localeData["1887"][LANG_IDS[langId]] + "#mailid",
        MODAL_EMAIL_CONFIRMATION_MESSAGE: localeData["1888"][LANG_IDS[langId]],
        EMAIL_CONFIRM_KEY: localeData["1889"][LANG_IDS[langId]],
        EMAIL_CANCEL_KEY: localeData["404"][LANG_IDS[langId]],
        SUCCESS_HEADER_1: localeData["1890"][LANG_IDS[langId]],
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: localeData["1891"][LANG_IDS[langId]],
        SUCCESS_HEADER_1_EMAIL_DEFAULT: localeData["1892"][LANG_IDS[langId]],
        UPDATE_PASSWORD_HEADING: localeData["1893"][LANG_IDS[langId]],
        UPDATE_PASSWORD_BODY: localeData["1894"][LANG_IDS[langId]],
        NEW_PASSWORD: localeData["1895"][LANG_IDS[langId]],
        RE_ENTER_PASSWORD: localeData["1896"][LANG_IDS[langId]],
        SUCCESS_HEADER_2: localeData["1903"][LANG_IDS[langId]],
        LOGIN_REDIRECT: localeData["1904"][LANG_IDS[langId]],
        SUCCESS_IMPORTANT_MESSAGE: localeData["1905"][LANG_IDS[langId]],
        PASSWORD_RULES_HEADER: "",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: localeData["1897"][LANG_IDS[langId]],
                valid: false
            },
            /*{
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One lowercase',
                valid: false
            },*/
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: localeData["1898"][LANG_IDS[langId]],
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: localeData["1899"][LANG_IDS[langId]],
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: localeData["1900"][LANG_IDS[langId]],
                valid: false
            }],
        UPDATE_PASSWORD_BUTTON: localeData["1901"][LANG_IDS[langId]],
        SIGN_IN_TEXT: 'Sign In',
        SIGN_IN_FAILED: 'Sign in failed!',
        USERNAME: 'username',
        PASSWORD: 'password',
        Sign_In_BUTTON: 'Sign-In'
    }
    return localeStateKeys;
}

export const LANG_IDS = {
    "-1": "EN",
    "-6": "PT",
    "-7": "ZH",
    "-27": "CS",
    "-28": "DA",
    "-23": "AR",
    "-24": "FI",
    "-2": "FR",
    "-3": "DE",
    "-31": "HU",
    "-4": "IT",
    "-10": "JA",
    "-29": "NL",
    "-25": "NB",
    "-22": "PL",
    "-21": "RO",
    "-20": "RU",
    "-9": "KO",
    "-5": "ES",
    "-26": "SV",
    "-8": "ZH",
    "-32": "TH",
    "-30": "TR",
    "-34": "VI"
}


