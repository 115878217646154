export const ACCOUNT_UPDATED = 'Your account has been updated. A verification email has been sent to #emailaddress. if you do not recieve the verification email, click the re-send button below.';
// export const PASSWORD_RULES = ['Password must be at least 12 characters in length', 'Password must include at least 1 lower-case, 1 upper-case, 1 Number and 1 Special Character', 'Shouldn\'t the previous four passwords']
export const PASSWORD_RULES = [
    { iconOk: 'assets/img/ok2.png', iconCross: 'assets/img/cross41.png', text: '12 characters in length', valid: false },
    { iconOk: 'assets/img/ok2.png', iconCross: 'assets/img/cross41.png', text: 'One lowercase', valid: false },
    { iconOk: 'assets/img/ok2.png', iconCross: 'assets/img/cross41.png', text: 'One Uppercase', valid: false },
    { iconOk: 'assets/img/ok2.png', iconCross: 'assets/img/cross41.png', text: 'One Number', valid: false },
    { iconOk: 'assets/img/ok2.png', iconCross: 'assets/img/cross41.png', text: 'One Special Character(!@#$%^&*)', valid: false }
];

export const EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE = "PLEASE VERIFY YOUR ACCOUNT";
export const EMAIL_VERIFICATION_EMAIL_ADDRESS = "Your Email Address";
export const EMAIL_VERIFICATION_MESSAGE = "We are updating our sign in process to provide you with a more secure, single sign on experience across multiple Parker applications. After this update, you will use the email address  provided below to sign in.";

export const MODAL_EMAIL_MESSAGE = "You entered your email as #mailid";
export const MODAL_EMAIL_CONFIRMATION_MESSAGE = "Please confirm this email address is correct and you understand your email address will be your User ID ";

export const SUCCESS_HEADER_1 = "THANK YOU FOR UPDATING YOUR EMAIL";
export const SUCCESS_HEADER_1_EMAIL_CONFIRMATION = "We have sent a confirmation email to #mailid. Please click the link in the message to <span style=font-weight:bold>confirm your email address</span>.";
export const SUCCESS_HEADER_1_EMAIL_DEFAULT = "Make sure to check your junk or spam folder. The sender is ";
export const SUCCESS_HEADER_1_SENDER_MAIL = "parkeraccount@logintest.parker.com"; // "idp.support@logintest.parker.com"; // "senderid@noreply.com"
// export const DID_NOT_RECEIEVE_MAIL = "Didn't receive your email confirmation? Click Here to restart the process.";
export const DID_NOT_RECEIEVE_MAIL = "";


export const SUCCESS_HEADER_2 = "YOUR PASSWORD HAS BEEN UPDATED SUCCESSFULLY";
export const SUCCESS_IMPORTANT_MESSAGE = "<span style=font-weight:bold>Important: Your Email Address will now be your User ID</span>."

export const UPDATE_PASSWORD_HEADING = "PLEASE UPDATE YOUR PASSWORD";
export const UPDATE_PASSWORD_BODY = "Thank you for verifying your email. You need to update your password to meet our new security policies.";
