import { getAPIUrl, getPath } from "../apiconfig";

import {
    TRANSLATION,
    LANG_IDS
} from "../util/Translation";

import { DOMAIN_BALDWIN, DOMAIN_TFS } from "../Constant";

import {
    // MIGRATION_AUTHENTICATE_URL,
    // MIGRATION_UPDATE_URL,
    // MIGRATION_FINISH_URL,
    // MIGRATION_TOKEN_URL,
    // MIGRATION_LOCALE_URL,
    // REACT_APP_AUTHORIZATION_KEY,
    // REACT_APP_PCM_AUTHORIZATION,
    // REACT_APP_PCMURL,
    // FOOTER_API,
    // CHANGEPASSWORD_URL
} from "../config/properties.js";

const axios = require('axios');
require('es6-promise').polyfill();

export function getLocaleInfo(func, pageName, langId) {
    try {

        // const url = MIGRATION_LOCALE_URL;
        const url =  getAPIUrl('getTranslations');
        const translations = [
            ...TRANSLATION.SIGN_IN_SCREEN,
            ...TRANSLATION.EMAIL_VERIFICATION_SCREEN,
            ...TRANSLATION.SUCCESS_SCREEN,
            ...TRANSLATION.UPDATE_PASSWORD_SCREEN,
            ...TRANSLATION.PASSWORD_SUCCESS_SCREEN
        ].join();

        const payload = {
            "translations": {
                "ids": translations,
                "locales": LANG_IDS[langId]
            }
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': "application/json"
        }

        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {

            });
    } catch (err) {

    }
}

export function migrationAuthenticate(func, username, password, langId) {
    try {
        // const url = MIGRATION_AUTHENTICATE_URL;
        const url= getAPIUrl("migrationAuthenticate");
        var headers = {
            'Accept': 'application/json',
            'Content-Type': "application/json"
        }
        const payload = {
            "logonId": String(username).toLowerCase(),
            "logonPassword": password,
            "langId": langId
        };

        /*fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: payload
        }).then(function (response) {
            return response.json()
        }).then(function (json) {
            console.log('parsed json', json)
        }).catch(function (ex) {
            console.log('parsing failed', ex)
        })*/

        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResponse = {
                    errors: [{ errorMessage: "" }]
                }
                if (error && error.response) {
                    errResponse = error.response.data
                }
                func(errResponse);
            });
    } catch (err) {
        return err;
    }
}

export function migrationUpdate(func, emailFieldValue, username, WCToken, WCTrustedToken, langId) {
    try {
        // const url = MIGRATION_UPDATE_URL
        const url = getAPIUrl('migrationUpdate');
        var headers = {
            'Accept': 'application/json',
            'Content-Type': "application/json"
        }
        const payload = {
            "username": String(username).toLowerCase(),
            "email": String(emailFieldValue).toLowerCase(),
            "langId": langId
        };

        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response.data);
            })
            .catch((error) => {
                let errResp = {
                    errors: [{
                        errorMessage: "The User ID/Email you entered already exists. Kindly provide another value."
                    }]
                }
                if (error && error.response) {
                    errResp = error.response.data
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }
}

export function migrationFinish(func, newPassword, loginId, langId) {
    try {
        // const url = MIGRATION_FINISH_URL;
        const url = getAPIUrl('migrationFinish')
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getPath('getAuthorizationKey')
            // 'Authorization': REACT_APP_AUTHORIZATION_KEY
        }

        //support for + symbol in VOM user email 12/4/20
        loginId = loginId.replace("+","%2B");
        
        const payload = {
            "username": String(loginId).toLowerCase(),
            "password": newPassword,
            "langId": langId
        }
        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response);

            })
            .catch((error) => {
                let errResp = {
                    errorMessage: ""
                }
                if (error && error.response && error.response.data) {
                    errResp = {
                        errorMessage: error.response.data.message
                    }
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }

}

export function changePassword(func, newPassword, stateToken) {
    try {
        // const url = CHANGEPASSWORD_URL + loginId;
        const url = getAPIUrl('changePassword');
        var headers = {
            // 'Ocp-Apim-Subscription-Key': '3582726943d84cdc9647533f5559058a'
            'Ocp-Apim-Subscription-Key': getPath('getOCMSubscriptionKey')
        }
        const payload = {
            "stateToken": stateToken,
            "newPassword": newPassword
        }
        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResp = {
                    errorMessage: ""
                }
                if (error && error.response && error.response.data) {
                    errResp = {
                        errorMessage: error.response.data.errorCauses[0].errorSummary
                    }
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }

}

export function addUserToGroup(func, userId, groupId, accessToken) {
    try {
        const url = getAPIUrl('addUserToGroup').replace("#userid#", userId).replace("#groupid#", groupId);
        var headers = {
            'Authorization': 'Bearer ' + accessToken
        }
        const payload = "";

        axios.put(url, payload, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResp = {
                    errorMessage: ""
                }
                if (error && error.response && error.response.data) {
                    errResp = {
                        errorMessage: error.response.data.message
                    }
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }

}

export function validateToken(func, token, langId) {
    try {
        // const url = MIGRATION_TOKEN_URL;
        const url = getAPIUrl('getTokenUrl');
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        const payload = {
            "recoveryToken": token,
            "langId": langId
        }
        axios.post(url, payload, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResp = {
                    errorSummary: ""
                }
                if (error && error.response) {
                    errResp = error.response.data
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }
}


export function getPCMLink(userId, func) {
    try {
        const url = getAPIUrl('getPCMUrl').replace('guid', userId);

        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        axios.get(url, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResp = {
                    errorSummary: ""
                }
                if (error && error.response) {
                    errResp = error.response.data
                }
                func(errResp);
            });
    } catch (err) {
        return err;
    }
}

export function callSmartCommAPI(obj) {
    //const { langId, history } = this.props.contextProps;
    const inputData = window.btoa(new XMLSerializer().serializeToString(obj));

    const postData = {
      name: "Okta_Email_" + Date.now(),
      inputData: inputData,
      project: "-1",
      //project: "157775803",
      config: "157862427",
      range: "1",
      queue: getPath("getSmartCommQueue"),
    };

    //user has be created at this point (work on custom email for approval flow ex. "Your Parker account has been create but awaiting approval for [appname]...")
    //send user confirmation email to activate account
    axios({
      method: "post",
      // url: 'https://apidev.parker.com/dev/smartcomm/api/v5/jobs',
      url: getAPIUrl("getJobsAPIUrl"),
      headers: {
        "Ocp-Apim-Subscription-Key": getPath("getJObAPIOCMSubscriptionKey"),
        //'Ocp-Apim-Subscription-Key': 'c926f915fa8f4305866ca03c51d98e6a'
      },
      data: postData,
    })
      .then((resp) => {
        //console.log(resp);
        if (resp && resp.status === 201) {
            //complete
        } else {
            //other
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

export const createOktaEmailObject = (type, obj, token, domainName, appId, applicationName= '') => {

    const doc = document.implementation.createDocument("", "", null);
    const oktaemail = doc.createElement("oktaemail");

    const emailsettings = doc.createElement("emailsettings");

    const imagesource = doc.createElement('imagesource');
    if (domainName === DOMAIN_TFS)
    {
        imagesource.textContent = "https://www.totalfiltrationservices.com/parker/TFS/assets/images/logo.png";
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        imagesource.textContent = "https://ph.baldwinfilters.com/wcsstore/ParkerB2BStorefrontAssetStore/images/baldwinlogo.jpg";
    }
    else
    {
        imagesource.textContent = "https://ph.parker.com/wcsstore/ParkerAuroraStorefrontAssetStore/images/logo.jpg";
    }   
    emailsettings.appendChild(imagesource);

    const color = doc.createElement('color');
    if (domainName === DOMAIN_TFS)
    {
        color.textContent = "#211F5F"
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        color.textContent = "#ED1C24"
    }
    else
    {
        color.textContent = "#E2AA11"
    }  
    emailsettings.appendChild(color);

    const emailType = doc.createElement('emailtype');
    if (type === 'register') {
        if (appId != '')
        {
            emailType.textContent = "access"; //this is a blank template email with just a text body
        }
        else
        {
            emailType.textContent = "activate";
        }     
    } else if (type === 'reset-password') {
        emailType.textContent = "reset";
    }
    emailsettings.appendChild(emailType);
    oktaemail.appendChild(emailsettings);

    const emailInformation = doc.createElement("emailinformation");
    const leanerAppId = getPath("getLeanerAppId");
    if(appId != '')
    {
        //update this to get app name dynamically
        const appName = leanerAppId === appId ? "PLI application" : applicationName;
        const bodyText = doc.createElement('bodytext');
        bodyText.textContent = 'Your access to '+ appName +' is currently pending approval and you will be notified by email when an action is taken.';
        emailInformation.appendChild(bodyText);

        const approveusername = doc.createElement('approveusername');
        approveusername.textContent = obj.emailFieldValue;
        emailInformation.appendChild(approveusername);
    }

    const fromName = doc.createElement('fromname');
    if (domainName === DOMAIN_TFS)
    {
        fromName.textContent = 'TFS Support';
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        fromName.textContent = 'Baldwin Filters Support';
    }
    else
    {
        fromName.textContent = 'Parker Support';
    }  
    emailInformation.appendChild(fromName);

    const fromEmail = doc.createElement('fromemail');
    if (domainName === DOMAIN_TFS)
    {
        fromEmail.textContent = getPath('getTFSEmailId');
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        fromEmail.textContent = getPath('getBaldwinEmailId');
    }
    else
    {
        fromEmail.textContent = getPath('getEmail');
    }    
    emailInformation.appendChild(fromEmail);

    const toName = doc.createElement('toname');
    toName.textContent = obj.userFirstName;
    emailInformation.appendChild(toName);
    const toEmail = doc.createElement('toemail');
    toEmail.textContent = obj.emailFieldValue;
    emailInformation.appendChild(toEmail);

    if (type === 'register') {
        const subject = doc.createElement('subject');
        subject.textContent = 'Activate Account';
        if(appId != '')
        {
            subject.textContent = `Pending Approval to ${leanerAppId === appId ? `PLI Application` : applicationName}`;
        }
        else
        {
            const activationLink = doc.createElement('activationlink');
            activationLink.textContent = token;
            emailInformation.appendChild(activationLink);
        }
        emailInformation.appendChild(subject);

    } else if (type === 'reset-password') {
        const subject = doc.createElement('subject');
        subject.textContent = 'Account password reset';
        emailInformation.appendChild(subject);

        const resetlink = doc.createElement('resetlink');
        if (domainName === DOMAIN_TFS)
        {
            resetlink.textContent = getPath('redirectUrlTfs') + '/?mode=1&updatepassword=' + token;
        }
        else if (domainName === DOMAIN_BALDWIN)
        {
            resetlink.textContent =  getPath('redirectUrlBaldwin') + '/?mode=1&updatepassword=' + token;
        }
        else
        {
            resetlink.textContent = getPath('redirectUrl') + '/?mode=1&updatepassword=' + token;
        }    
        emailInformation.appendChild(resetlink);
    }

    if(appId === '')
    {
        const linkExpiration = doc.createElement('linkexpiration');
        linkExpiration.textContent = '7 days';
        emailInformation.appendChild(linkExpiration);
    
        const username = doc.createElement('username');
        username.textContent = obj.emailFieldValue;
        emailInformation.appendChild(username);
    
        const helpLink = doc.createElement('helplink');
        if (domainName === DOMAIN_TFS)
        {
            helpLink.textContent = getPath('redirectUrlTfs') + '/help/login';
        }
        else if (domainName === DOMAIN_BALDWIN)
        {
            helpLink.textContent = getPath('redirectUrlBaldwin') + '/help/login';
        }
        else
        {
            helpLink.textContent = getPath('redirectUrl') + '/help/login';
        } 
        emailInformation.appendChild(helpLink);
    }

    const orgname = doc.createElement('orgname');
    if (domainName === DOMAIN_TFS)
    {
        orgname.textContent = 'TFS';
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        orgname.textContent = 'Baldwin Filters';
    }
    else
    {
        orgname.textContent = 'Parker';
    }  
    emailInformation.appendChild(orgname);

    const orglink = doc.createElement('orglink');
    if (domainName === DOMAIN_TFS)
    {
        orglink.textContent = getPath('getTFSHomePage');
    }
    else if (domainName === DOMAIN_BALDWIN)
    {
        orglink.textContent = getPath('getBaldwinHomePage');
    }
    else
    {
        orglink.textContent = getPath('getParkerHomePage') ;
    } 
    emailInformation.appendChild(orglink);

    oktaemail.appendChild(emailInformation);
    doc.appendChild(oktaemail);
    //console.log(doc);
    return doc;
}

export const createOktaEmailObject_Approval = (type, obj, approveremail, approverfname, appId, applicationName='') => {

    const doc = document.implementation.createDocument("", "", null);
    const oktaemail = doc.createElement("oktaemail");

    const emailsettings = doc.createElement("emailsettings");

    const imagesource = doc.createElement('imagesource');
    imagesource.textContent = "https://ph.parker.com/wcsstore/ParkerAuroraStorefrontAssetStore/images/logo.jpg";
    emailsettings.appendChild(imagesource);

    const color = doc.createElement('color');
    color.textContent = "#E2AA11"
    emailsettings.appendChild(color);

    const emailType = doc.createElement('emailtype');
    emailType.textContent = "approve";
    emailsettings.appendChild(emailType);
    oktaemail.appendChild(emailsettings);

    const emailInformation = doc.createElement("emailinformation");

    const fromName = doc.createElement('fromname');
    fromName.textContent = 'Parker Support';
    emailInformation.appendChild(fromName);

    const fromEmail = doc.createElement('fromemail');
    fromEmail.textContent = getPath('getEmail');    
    emailInformation.appendChild(fromEmail);

    const toName = doc.createElement('toname');
    toName.textContent = approverfname;
    emailInformation.appendChild(toName);

    const toEmail = doc.createElement('toemail');
    toEmail.textContent = approveremail;
    emailInformation.appendChild(toEmail);

    //update to dynmaically get app ID
    const subject = doc.createElement('subject');
    const leanerAppId = getPath("getLeanerAppId");
    subject.textContent = leanerAppId === appId ? 'Approve Account - PLI Application' : `Approve Account - ${applicationName}`;
    emailInformation.appendChild(subject);

    const orgname = doc.createElement('orgname');
    orgname.textContent = 'Parker';
    emailInformation.appendChild(orgname);

    const orglink = doc.createElement('orglink');
    orglink.textContent = getPath('getParkerHomePage'); 
    emailInformation.appendChild(orglink);

    const appname = doc.createElement('appname');
    appname.textContent = leanerAppId === appId ? 'PLI application' : applicationName;
    emailInformation.appendChild(appname);

    const approveusername = doc.createElement('approveusername');
    approveusername.textContent = obj.emailFieldValue;
    emailInformation.appendChild(approveusername);

    const approvename = doc.createElement('approvename');
    approvename.textContent = obj.userFirstName + ' ' + obj.userLastName;
    emailInformation.appendChild(approvename);

    const approveorgname = doc.createElement('approveorgname');
    approveorgname.textContent = obj.orgValue;
    emailInformation.appendChild(approveorgname);
    
    if (obj.areaOfInterestText) {
        const areaofinterest = doc.createElement('areaofinterest');
        areaofinterest.textContent = obj.areaOfInterestText;
        emailInformation.appendChild(areaofinterest);
    }

    const denylink = doc.createElement('denylink');  
    const approvelink = doc.createElement('approvelink');

    const userid = obj.id;
    let groupid;
    if (appId === getPath("getLeanerAppId")) {
        groupid = getPath('getPLIGroupId');
    } else {
        groupid = getPath('getParkerIdGroupId');
    }
    denylink.textContent = getPath('getBaseReactUrl') + "/deny/" + userid+ "/" + groupid + "/" + appId;
    approvelink.textContent = getPath('getBaseReactUrl') + "/approve/" + userid + "/" + groupid+ "/" + appId;
    emailInformation.appendChild(denylink);
    emailInformation.appendChild(approvelink); 

    oktaemail.appendChild(emailInformation);
    doc.appendChild(oktaemail);

    return doc;
}


export const createOktaEmailObject_Access = (username, fname, appName, link) => {

    const doc = document.implementation.createDocument("", "", null);
    const oktaemail = doc.createElement("oktaemail");

    const emailsettings = doc.createElement("emailsettings");

    const imagesource = doc.createElement('imagesource');
    imagesource.textContent = "https://ph.parker.com/wcsstore/ParkerAuroraStorefrontAssetStore/images/logo.jpg";
    emailsettings.appendChild(imagesource);

    const color = doc.createElement('color');
    color.textContent = "#E2AA11"
    emailsettings.appendChild(color);

    const emailType = doc.createElement('emailtype');
    emailType.textContent = "access";
    emailsettings.appendChild(emailType);
    oktaemail.appendChild(emailsettings);

    const emailInformation = doc.createElement("emailinformation");

    const fromName = doc.createElement('fromname');
    fromName.textContent = 'Parker Support';
    emailInformation.appendChild(fromName);

    const fromEmail = doc.createElement('fromemail');
    fromEmail.textContent = getPath('getEmail');    
    emailInformation.appendChild(fromEmail);

    const toName = doc.createElement('toname');
    toName.textContent = fname;
    emailInformation.appendChild(toName);

    const toEmail = doc.createElement('toemail');
    toEmail.textContent = username;
    emailInformation.appendChild(toEmail);

    //update to dynmaically get app ID, approve or deny flag needed
    const subject = doc.createElement('subject');
    if(link === "deny")
    {
        subject.textContent = 'Access Denied to ' + appName;
    }
    else
    {
        subject.textContent = 'Access Granted to ' + appName;
    }
    emailInformation.appendChild(subject);

    const bodyText = doc.createElement('bodytext');
    if(link === "deny")
    {
        bodyText.textContent = 'Your account has been denied access to '+ appName +'.';
    }
    else
    {
        bodyText.textContent = 'Your account has been granted access to '+ appName +'. Click the below button to access '+ appName +'.';
    }   
    emailInformation.appendChild(bodyText);

    const orgname = doc.createElement('orgname');
    orgname.textContent = 'Parker';
    emailInformation.appendChild(orgname);

    const orglink = doc.createElement('orglink');
    orglink.textContent = getPath('getParkerHomePage'); 
    emailInformation.appendChild(orglink);

    const appname = doc.createElement('appname');
    appname.textContent = appName;
    emailInformation.appendChild(appname);

    const approveusername = doc.createElement('approveusername');
    approveusername.textContent = username;
    emailInformation.appendChild(approveusername);

    const approvelink = doc.createElement('approvelink');
    if(link != "deny")
    {
        approvelink.textContent = link;
        emailInformation.appendChild(approvelink); 
    }    
    

    oktaemail.appendChild(emailInformation);
    doc.appendChild(oktaemail);

    return doc;
}


export const FetchFooterDetails = (langCode, func) => {
    // const url = FOOTER_API + "?divisionId=350455&langId=" + langCode
    const url = getAPIUrl('getFooterUrl')+"?divisionId=350455&langId=" + langCode;

    axios.get(url)
        .then((response) => {
            const { result } = response.data;
            const staticFooter = result.staticFooter;
            func(staticFooter);
        })
        .catch((error) => {
            console.log("Error: ", error);
        });
}


export const DeleteUser = (userId, accessToken, func) => {
    try {
        const url = getAPIUrl('getUserDetails') + userId + '/';
        var headers = {
            'Authorization': 'Bearer ' + accessToken
        }

        axios.delete(url, { headers: headers })
            .then((response) => {
                func(response);
            })
            .catch((error) => {
                let errResp = {
                    errorMessage: ""
                }
                if (error && error.response && error.response.data) {
                    errResp = {
                        errorMessage: error.response.data.message
                    }
                }
                func(errResp);
            });

    } catch (err) {
        return err;
    }
}


/****
 * 
 * REGISTRATION APIS
 * 
 */

export const registerUser = (userInfo, domainName, fn) => {
    // const url = 'https://apidev.parker.com/test/okta/users?activate=false'
    const url = getAPIUrl('getRegisterUrl');
    var headers = {}
    
    const payload = {
        "profile": {
            "firstName": userInfo.userFirstName,
            "lastName": userInfo.userLastName,
            "email": userInfo.emailFieldValue,
            "login": userInfo.emailFieldValue
        },
        "credentials": {
            "password": { "value": userInfo.passwordValue }
        },
        "groupIds": [
            getPath('getParkerGroupId'),
            //add additional group id based on domain
            domainName === DOMAIN_TFS ? getPath('getTFSGroupId') : domainName === DOMAIN_BALDWIN ? getPath('getBaldwinGroupId') : ''
        ]
    }

    axios.post(url, payload, { headers: headers })
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export const activateUser = (userGuid, fn) => {
    // const url = 'https://apidev.parker.com/test/okta/users/' + userGuid + '/lifecycle/activate?sendEmail=false'
    const url = getAPIUrl('getActivateUrl').replace("#userGuid#", userGuid);
    var headers = {}

    axios.post(url, {}, { headers: headers })
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export const getUsersFromGroup = (groupID, fn) => {
    const url = getAPIUrl('getUsersFromGroup').replace("#groupid#", groupID);

    axios.get(url)
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export const getGroupsFromUser = (userID, fn) => {
    const url = getAPIUrl('getGroupsFromUser').replace("#userid#", userID);

    axios.get(url)
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export const getAppInfo = (appID, fn) => {
    const url = getAPIUrl('getAppInfo').replace("#appid#", appID);

    axios.get(url)
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });
}

export const getCurrentUser = (fn) => {
    const url = getAPIUrl('getCurrentUser');

    axios.get(url,{withCredentials: true})
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

/****
 * 
 * Forgot Password APIS
 * 
 */
export const getUserDetails = (userEmail, fn) => {
    // const url = 'https://apidev.parker.com/test/okta/users/' + userEmail;
    const url = getAPIUrl('getUserDetails') + userEmail + '/';

    axios.get(url)
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export const forgotPassword = (userEmail, domainName, fn) => {
    
    const url = getAPIUrl('getForgortPassword').replace("#userEmail#", userEmail).replace("#domainName#",  domainName === DOMAIN_TFS ? 'TFS' : domainName === DOMAIN_BALDWIN ? 'Baldwin' : '');

    var headers = {
        'Accept': 'application/json',
        'Content-Type': "application/json"
    }

    axios.get(url, { headers: headers })
        .then((response) => {
            const resp = response.data;
            fn(resp);
        })
        .catch((err) => {
            let errResp = {
                errorSummary: ""
            }
            if (err && err.response) {
                errResp = err.response.data;
            }
            fn(errResp)
        });

}

export function addUserToSaba(payload) {
    try {
        const url = getAPIUrl('addUserToSaba');
        var headers = {
            'Ocp-Apim-Subscription-Key': getPath("getPLISubscriptionKey"),
            'Content-Type': 'application/json'
        }
        axios.post(url, payload, { headers: headers });
    } catch (err) {
        console.log(err);
    }
}

export function deleteUserToSaba(id) {
    try {
        const url = getAPIUrl('deleteUserToSaba')+'?id='+id;
        var headers = {
            'Ocp-Apim-Subscription-Key': getPath("getPLISubscriptionKey"),
            'Content-Type': 'application/json'
        }
        axios.delete(url, { headers: headers });
    } catch (err) {
        console.log(err);
    }
}
