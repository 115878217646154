import React, { Component } from 'react';
import { PASSWORD_RULES } from '../util/Messages';
import { Container, Row, Col, Button } from "react-bootstrap";

import {
    updatePassword
} from '../actions/main-action';

import {
    migrationFinish,
    validateToken,
    changePassword
} from "../services/util";

import {
    UPDATE_PASSWORD_HEADING,
    UPDATE_PASSWORD_BODY
} from "../util/Messages";

import {
    Localization
} from "../util/localization";

import {
    LANG_IDS
} from "../util/Translation";

import {
    DOMAIN_PARKER,
    DOMAIN_TFS,
    DOMAIN_BALDWIN,
    Context
} from "../Constant";

class UpdatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordReqMatched: false,
            tokenValid: false,
            errMessage: "",
            loginId: "",
            passwordRules: [],
            showLoader: false,
            passwordError: false,
            userGuid: null
        }
        this.onUpdatePasswordBtnClick = this.onUpdatePasswordBtnClick.bind(this);
        this.matchPasswordRequirement = this.matchPasswordRequirement.bind(this);
        this.storePassword = this.storePassword.bind(this);
        this.checkPasswordValidations = this.checkPasswordValidations.bind(this);
        this.matchPasswordRules = this.matchPasswordRules.bind(this);
    }

    componentDidMount() {
        
        const self = this;
        const langId = this.props.contextProps.langId;
        this.setState({ showLoader: true });
        const loc = window.location.href;
        let token = loc.split("updatepassword")[1];
        token = token.substr(1, token.length);
        validateToken((resp) => {
            if (resp.hasOwnProperty("errorSummary")) {
                this.setState({
                    tokenValid: false,
                    errMessage: resp.errorSummary,
                    showLoader: false
                });
            } else {
                const loginId = resp && resp.data ? resp.data._embedded.user.profile.login : null;
                const userGuid = resp && resp.data ? resp.data._embedded.user.id : null;
                const stateToken = resp && resp.data ? resp.data.stateToken : null;
                this.setState({
                    tokenValid: true,
                    errMessage: "",
                    loginId,
                    showLoader: false,
                    userGuid,
                    stateToken
                });
            }
        }, token, langId);
        // this.setState({ passwordRules: PASSWORD_RULES });
        // this.setState({ passwordRules: this.props.localeText.PASSWORD_RULES });
        let localeFileText = Localization[LANG_IDS[this.props.contextProps.langId]];
        if (localeFileText === undefined) {
            localeFileText = Localization[LANG_IDS["-1"]];
        }
        this.setState({ passwordRules: localeFileText.PASSWORD_RULES });
    }

    static getDerivedStateFromProps(props, state) {
        const passwordRules = props.contextProps.localeText.PASSWORD_RULES;
        return {
            passwordRules
        }
    }

    /**
     * @func onUpdatePasswordBtnClick
     * @params none 
     * @desc function is responsible to call migration finish API and based on the response route to success screen 
     */
    onUpdatePasswordBtnClick() {
        const isModePresent = window.location.href.indexOf("mode=") > - 1 ? true : false;
        const newPassword = this.props.contextProps.updatePassword[1].value;
        const langId = this.props.contextProps.langId;
        this.setState({ showLoader: true });
        if (isModePresent) {
            changePassword((resp) => {
                this.setState({ showLoader: false });
                if (resp.hasOwnProperty("errorMessage")) {
                    this.setState({
                        passwordError: true,
                        errMessage: resp.errorMessage
                    });
                } else {
                    this.props.contextProps.history.push({
                        pathname: '/passwordSuccess',
                        state: {
                            langId: langId,
                            userGuid: this.state.userGuid
                        }
                    })
                }
            }, newPassword, this.state.stateToken);
        } else {
            migrationFinish((resp) => {
                this.setState({ showLoader: false });
                if (resp.hasOwnProperty("errorMessage")) {
                    this.setState({
                        passwordError: true,
                        errMessage: resp.errorMessage
                    });
                } else {
                    // this.props.history.push("/passwordSuccess");
                    this.props.contextProps.history.push({
                        pathname: '/passwordSuccess',
                        state: {
                            langId: langId,
                            userGuid: this.state.userGuid
                        }
                    })
                }
            }, newPassword, this.state.loginId, langId);
        }
    }

    /**
     * @func matchPasswordRequirement
     * @params evt 
     * @desc function is responsible to match password requirement
     */
    matchPasswordRequirement(evt) {
        const name = evt.target.name;
        this.props.contextProps.dispatch(updatePassword(this.checkPasswordValidations(name)));
    }

    /**
     * @func hasNumber
     * @params txtvalue 
     * @desc function is responsible to validate lowercase validation
     */
    hasNumber(txtValue) {
        return /\d/.test(txtValue);
    }

    /**
     * @func hasLowerCase
     * @params txtvalue 
     * @desc function is responsible to validate number validation
     */
    hasLowerCase(txtValue) {
        return (/[a-z]/.test(txtValue));
    }

    /**
     * @func hasUpperCase
     * @params txtValue 
     * @desc function is responsible to validate uppercase validation 
     */
    hasUpperCase(txtValue) {
        return (/[A-Z]/.test(txtValue));
    }

    /**
     * @func checkPasswordValidations
     * @params name 
     * @desc function is responsible to check password validations 
     */
    checkPasswordValidations(name) {
        let passwordReqMatched,
            lowerCase,
            upperCase,
            isNumberAvailable,
            isSpecialCharacterAvailable,
            passwordLengthMatched = false;
        let newPassword, conformPassword = "";

        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const passwordVal = [...this.props.contextProps.updatePassword];

        for (let i = 0; i < passwordVal.length; i++) {
            if (passwordVal[i].type === name) {
                passwordLengthMatched = passwordVal[i].value.length < 12 ? false : true;
                isSpecialCharacterAvailable = format.test(passwordVal[i].value);
                isNumberAvailable = this.hasNumber(passwordVal[i].value);
                lowerCase = this.hasLowerCase(passwordVal[i].value);
                upperCase = this.hasUpperCase(passwordVal[i].value);

                if (passwordLengthMatched && isNumberAvailable && lowerCase && upperCase && isSpecialCharacterAvailable) {
                    passwordVal[i].isValid = true;
                } else {
                    passwordVal[i].isValid = false;
                }
            }

            if (passwordVal[i].isValid) {
                if (passwordVal[i].type === "newPassword") {
                    newPassword = passwordVal[i].value;
                } else if (passwordVal[i].type === "reEnterPassword") {
                    conformPassword = passwordVal[i].value;
                }
                if (newPassword === conformPassword) {
                    this.setState({ passwordReqMatched: true });
                } else {
                    this.setState({ passwordReqMatched: false });
                }
            }

        }
        return passwordVal;
    }

    /**
     * @func storePassword
     * @params evt 
     * @desc function is responsible to store updated password
     */
    storePassword(evt) {
        const passwordVal = [...this.props.contextProps.updatePassword];
        for (let i = 0; i < passwordVal.length; i++) {
            if (passwordVal[i].type === evt.target.name) {
                passwordVal[i].value = evt.target.value;
                this.matchPasswordRules(evt);
                break;
            }
        }
        this.props.contextProps.dispatch(updatePassword(passwordVal));
    }

    /**
     * @func matchPasswordRules
     * @params e 
     * @desc function is responsible to match the password rules
     */
    matchPasswordRules(e) {
        const txtValue = e.target.value;
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        // const passwordRules = [...this.state.passwordRules];
        const localeFileText = Localization[LANG_IDS[this.props.contextProps.langId]];
        const passwordRules = localeFileText.PASSWORD_RULES;
        passwordRules[0].valid = txtValue.length >= 12 ? true : false;
        passwordRules[1].valid = this.hasLowerCase(txtValue);
        passwordRules[2].valid = this.hasUpperCase(txtValue);
        passwordRules[3].valid = this.hasNumber(txtValue);
        passwordRules[4].valid = format.test(txtValue);
        // passwordRules[0].valid = txtValue.length >= 12 && this.hasLowerCase(txtValue) ? true : false;
        // passwordRules[1].valid = this.hasLowerCase(txtValue);
        //passwordRules[1].valid = this.hasUpperCase(txtValue);
        // passwordRules[2].valid = this.hasNumber(txtValue);
        // passwordRules[3].valid = format.test(txtValue);

        this.setState({ passwordRules });
    }

    render() {
        const ulStyleOK = {
            'list-style-image': 'url("assets/img/ok2.png")',
            'list-style-position': 'inside'
        }
        const ulStyleCross = {
            'list-style-image': 'url("assets/img/cross41.png")',
            'list-style-position': 'inside'
        }

        const { domainName } = this.props.context;

        const passwordVal = [...this.props.contextProps.updatePassword];
        const localeText = this.props.contextProps.localeText;
        let localeFileText = Localization[LANG_IDS[this.props.contextProps.langId]];
        if (localeFileText === undefined) {
            localeFileText = Localization[LANG_IDS["-1"]];
        }

        return (
            <Container>
                {
                    this.state.tokenValid
                        ?
                        <React.Fragment>
                            {
                                this.state.showLoader
                                    ?
                                    <div className="loader"></div>
                                    :
                                    null
                            }
                            <Row>
                                <Col sm={12} className="logoLeft">
                                    <a href="/">
                                        {
                                            domainName === DOMAIN_PARKER
                                            ?
                                            <img src="assets/img/logo-black-hex.jpg" alt="Logo" />
                                            :
                                            domainName === DOMAIN_TFS
                                            ?
                                            <img src="assets/img/tfs-logo.png" alt="Logo" />
                                            :
                                            domainName === DOMAIN_BALDWIN
                                            ?
                                            <img src="assets/img/baldwin-logo.jpg" alt="Logo" />
                                            : null
                                        }
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="email_verification-wrap">
                                    <h2 className="okta-form-title o-form-head-email">
                                        {localeFileText.UPDATE_PASSWORD_HEADING}
                                    </h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                <hr className={domainName === DOMAIN_PARKER ? "yellow" : domainName === DOMAIN_TFS ? "blue" : domainName === DOMAIN_BALDWIN ? "red" : ""} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="">
                                    <span className="widget-body-text-email">
                                        {localeFileText.UPDATE_PASSWORD_BODY}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12} className="paddingTop30px ">
                                            <span className="widget-body-text-email">
                                                {localeFileText.NEW_PASSWORD}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="paddingTop10px  o-form-input">
                                            <input
                                                className="inputEmailBox"
                                                type="password"
                                                placeholder={String(localeFileText.NEW_PASSWORD).toLowerCase()}
                                                name="newPassword"
                                                onChange={(e) => { this.storePassword(e) }}
                                                onKeyUp={(e) => { this.matchPasswordRequirement(e) }}
                                                aria-label="password"
                                                onFocus={(e) => { this.matchPasswordRules(e) }}
                                                autoComplete="off" />&nbsp;
                                            {
                                                (passwordVal.length && passwordVal[0].isValid)
                                                    ?
                                                    <img src="assets/img/ok2.png" />
                                                    :
                                                    <img src="assets/img/cross41.png" />
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="paddingTop30px ">
                                            <span className="widget-body-text-email">
                                                {localeFileText.RE_ENTER_PASSWORD}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="paddingTop10px  o-form-input">
                                            <input
                                                className="inputEmailBox"
                                                type="password"
                                                placeholder={String(localeFileText.RE_ENTER_PASSWORD).toLowerCase()}
                                                name="reEnterPassword"
                                                onChange={(e) => { this.storePassword(e) }}
                                                onKeyUp={(e) => { this.matchPasswordRequirement(e) }}
                                                onFocus={(e) => { this.matchPasswordRules(e) }}
                                                aria-label="re-enter password"
                                                autoComplete="off" />&nbsp;
                                            {
                                                (passwordVal.length && passwordVal[1].isValid)
                                                    ?
                                                    <img src="assets/img/ok2.png" />
                                                    :
                                                    <img src="assets/img/cross41.png" />
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        this.state.passwordError
                                            ?
                                            <Row>
                                                <Col sm={12} className="">
                                                    <br />
                                                    <span className="widget-body-text-email colorRed">
                                                        {this.state.errMessage}
                                                    </span>
                                                </Col>
                                            </Row>
                                            : null

                                    }
                                    <Row>
                                        <Col xm={12} className="paddingTop10px ">
                                            <Button
                                                type="button"
                                                style={{ color: "#000" }}
                                                disabled={!this.state.passwordReqMatched}
                                                className={domainName === DOMAIN_PARKER ? "confirmBtn_updatePassword" : domainName === DOMAIN_TFS ? "confirmBtn_updatePassword_tfs" : "confirmBtn_updatePassword_baldwin"}
                                                onClick={this.onUpdatePasswordBtnClick}>
                                                {localeFileText.UPDATE_PASSWORD_BUTTON}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12} className="paddingTop30px  paddingBottom20px">
                                            <span className="widget-body-text-email">
                                                {localeFileText.PASSWORD_RULES_HEADER}
                                            </span>
                                        </Col>
                                    </Row>
                                    <React.Fragment>
                                        {
                                            localeFileText && localeFileText.PASSWORD_RULES.map((txt, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col sm={12} className="">
                                                            {
                                                                (txt.valid)
                                                                    ?
                                                                    <img src={txt.iconOk} />
                                                                    :
                                                                    <img src={txt.iconCross} />
                                                            }
                                                            <span className="widget-body-text-email"> {txt.text}</span>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                this.state.showLoader
                                    ?
                                    <div className="loader"></div>
                                    :
                                    null
                            }
                            <Row>
                                <Col sm={12} className="logoLeft">
                                    <a href="/">
                                        {
                                                domainName === DOMAIN_PARKER
                                                ?
                                                <img src="assets/img/logo-black-hex.jpg" alt="Logo" />
                                                :
                                                domainName === DOMAIN_TFS
                                                ?
                                                <img src="assets/img/tfs-logo.png" alt="Logo" />
                                                :
                                                domainName === DOMAIN_BALDWIN
                                                ?
                                                <img src="assets/img/baldwin-logo.jpg" alt="Logo" />
                                                : null
                                        }
                                    </a>
                                </Col>
                            </Row>
                            {/*<Row>
                                <Col sm={12} className="email_verification-wrap">
                                    <h2 className="okta-form-title o-form-head-email">{UPDATE_PASSWORD_HEADING}</h2>
                                </Col>
                            </Row>*/}
                            <Row>
                                <Col sm={12}>
                                    <hr className={domainName === DOMAIN_PARKER ? "yellow" : domainName === DOMAIN_TFS ? "blue" : domainName === DOMAIN_BALDWIN ? "red" : ""} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="">
                                    <span className="widget-body-text-email">
                                        {this.state.errMessage}
                                    </span>
                                </Col>
                            </Row>

                        </React.Fragment>
                }

            </Container>
        )
    }

}

// export default UpdatePassword;
export default props => (
    <Context.Consumer>
        {state => <UpdatePassword context={state} contextProps={props} />}
    </Context.Consumer>
);