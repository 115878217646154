const initialState = {
    emailAddress: "",
    updatePassword: [{
        type: 'newPassword',
        value: '',
        text: 'New Password',
        isValid: ""

    }, {
        type: 'reEnterPassword',
        value: '',
        text: 'Re-enter Password',
        isValid: ""

    }],
    localeText: {
        EMAIL_VERIFICATION_ACCOUNT_VERIFICATION_MESSAGE: 'PLEASE VERIFY YOUR ACCOUNT ',
        EMAIL_VERIFICATION_MESSAGE: 'We are updating our sign in process to provide you with a more secure, single sign on experience across multiple Parker applications. After this update, you will use the email address provided below to sign in. ',
        EMAIL_VERIFICATION_EMAIL_ADDRESS: 'Your Email Address',
        MODAL_EMAIL_MESSAGE: 'You entered your email as #mailid',
        MODAL_EMAIL_CONFIRMATION_MESSAGE: 'Please confirm this email address is correct and you understand your email address will be your User ID',
        EMAIL_CONFIRM_KEY: 'Confirm',
        EMAIL_CANCEL_KEY: 'Cancel',
        SUCCESS_HEADER_1: 'THANK YOU FOR UPDATING YOUR EMAIL',
        SUCCESS_HEADER_1_EMAIL_CONFIRMATION: 'We have sent a confirmation email to #mailid. Please click the link in the message to <span style=font-weight:bold>confirm your email address.</span>',
        SUCCESS_HEADER_1_EMAIL_DEFAULT: 'Make sure to check your junk or spam folder. The sender is <a href="">parkeraccount@logintest.parker.com</a>',
        UPDATE_PASSWORD_HEADING: "PLEASE UPDATE YOUR PASSWORD",
        UPDATE_PASSWORD_BODY: "Thank you for verifying your email. You need to update your password to meet our new security policies.",
        NEW_PASSWORD: "New Password",
        RE_ENTER_PASSWORD: "Re-enter Password",
        PASSWORD_RULES_HEADER: "Password should include at least",
        UPDATE_PASSWORD_BUTTON: "Update Password",
        PASSWORD_RULES: [
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: '12 characters in length',
                valid: false
            },
            /*{
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One lowercase',
                valid: false
            },*/
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Uppercase',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Number',
                valid: false
            },
            {
                iconOk: 'assets/img/ok2.png',
                iconCross: 'assets/img/cross41.png',
                text: 'One Special Character(!@#$%^&*)',
                valid: false
            }],
        SIGN_IN_TEXT: 'Sign In',
        SIGN_IN_FAILED: 'Sign in failed!',
        USERNAME: 'username',
        PASSWORD: 'password',
        Sign_In_BUTTON: 'Sign-In',
        SUCCESS_HEADER_2: 'YOUR PASSWORD HAS BEEN UPDATED SUCCESSFULLY',
        LOGIN_REDIRECT: 'Click here to login',
        SUCCESS_IMPORTANT_MESSAGE: 'Important: Your Email Address will now be your User ID.'
    }
};

const MainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_EMAIL_ADDRESS':
            {
                return (Object.assign({}, state, { emailAddress: action.payload }));
            }
        case 'UPDATE_PASSWORD':
            {
                return (Object.assign({}, state, { updatePassword: action.payload }));
            }
        case 'GET_LOCALE_TEXT':
            {
                return (Object.assign({}, state, { localeText: action.payload }));
            }
        default:
            return state;
    }
};

export default MainReducer;